import BendBranch from "./branches/BendBranch";
import BillingsBranch from "./branches/BillingsBranch";
import CedarRapidsBranch from "./branches/CedarRapidsBranch";
import DesMoinesBranch from "./branches/DesMoinesBranch";
import MedfordBranch from "./branches/MedfordBranch";
import SalemBranch from "./branches/SalemBranch";
import TestBranch from "./branches/TestBranch";

class PodioBranchList {
    constructor(podioApi) {
        this.branches = [
            new TestBranch(podioApi),
            new MedfordBranch(podioApi),
            new BendBranch(podioApi),
            new DesMoinesBranch(podioApi),
            new SalemBranch(podioApi),
            new BillingsBranch(podioApi),
            new CedarRapidsBranch(podioApi)
        ];
    }

    get(id) {
        for (const branch of this.branches) {
            if (branch.id === id) {
                return branch;
            }
        }
        throw new Error(`There is no branch with an id of ${id}.`);
    }

    getByName(name) {
        for (const branch of this.branches) {
            if (branch.name === name) {
                return branch;
            }
        }
        throw new Error(`There is no branch named ${name}.`);
    }

    getByProjectsAppId(id) {
        for (const branch of this.branches) {
            if (branch.projectSchema.id === id) {
                return branch;
            }
        }
        throw new Error(`There is no branch with a projects app in Podio that has an id of ${id}.`);
    }
}

export default PodioBranchList;
import { Component } from "react";
import PropTypes from "prop-types";
import "./ProfileLink.css";

class ProfileLink extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="ProfileLink">
                <div className="ProfileLink-container">
                    <a className={`full-button ${this.props.type}-button`} href={this.props.href} target="_blank">
                        {this.props.children}
                    </a>
                </div>
            </div>
        );
    }
}

ProfileLink.defaultProps = {
    type: "primary"
};

ProfileLink.propTypes = {
    type: PropTypes.string,
    href: PropTypes.string
};

export default ProfileLink;
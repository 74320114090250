import { Component } from "react";
import CircularCloseButton from "../CircularCloseButton";
import FileThumbnail from "../FileThumbnail";
import "./SingleFileIcon.css";


class SingleFileIcon extends Component {
    handleCloseButtonClick() {
        if (this.props.onRemove) {
            this.props.onRemove();
        }
    }

    render() {
        return (
            <div className={"SingleFileIcon" + (this.props.file.isLoading ? " loading" : "")}>
                <CircularCloseButton size={15} onClick={this.handleCloseButtonClick.bind(this)}/>
                <FileThumbnail file={this.props.file}/>
                <div className="SingleFileIcon-metadata">
                    <div className="SingleFileIcon-metadata-container">
                        <span>{this.props.file.name}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default SingleFileIcon;
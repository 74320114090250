import { Component } from "react";
import PropTypes from "prop-types";
import "./BranchSelector.css";

class BranchSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: props.default ? props.default.id : null
        };
    }

    componentDidMount() {
        if (this.props.default) {
            if (this.props.default.id !== this.state.selected) {
                this.setState({
                    selected: this.props.default.id
                });
            }
        }
    }

    handleChange(branch) {
        if (branch.id !== this.state.selected) {
            this.setState({
                selected: branch.id
            });
            if (this.props.onChange) {
                this.props.onChange(branch);
            }
        }
    }

    isSelected(branch) {
        return this.state.selected === branch.id;
    }

    render() {
        return (
            <div className="BranchSelector">
                <div className="BranchSelector-container">
                    {this.props.branchList.branches.map((branch, index) => (
                        <span className={"Branch" + (this.isSelected(branch) ? " selected" : "")} key={index} onClick={() => this.handleChange(branch)}>
                            <span className="Branch-container">
                                <span className="Branch-name">{branch.name}</span>
                            </span>
                        </span>
                    ))}
                </div>
            </div>
        );
    }
}

BranchSelector.propTypes = {
    default: PropTypes.object,
    onChange: PropTypes.func
};

export default BranchSelector;
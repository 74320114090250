import { Component } from "react";
import AddressField from "../../components/forms/fields/AddressField/AddressField";
import DateTimeField from "../../components/forms/fields/DateTimeField/DateTimeField";
import MultiLineTextField from "../../components/forms/fields/MultiLineTextField/MultiLineTextField";
import RadioField from "../../components/forms/fields/RadioField/RadioField";
import RadioOption from "../../components/forms/fields/RadioField/RadioOption";
import SelectionField from "../../components/forms/fields/SelectionField/SelectionField";
import SelectionOption from "../../components/forms/fields/SelectionField/SelectionOption";
import SingleLineTextField from "../../components/forms/fields/SingleLineTextField/SingleLineTextField";
import ScreenContainer from "../../components/ScreenContainer/ScreenContainer";
import SubmitButton from "../../components/SubmitButton/SubmitButton";
import Lead from "../../Lead";
import "./NewLeadScreen.css";

const APPOINTMENT_SET_STAGE = "1T Scheduled";
const SELF_GEN = "[Self-Gen]";

class NewLeadScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nameValue: "",
            nameError: null,
            phoneValue: "",
            phoneError: null,
            emailValue: "",
            emailError: null,
            addressValue: "",
            addressError: null,
            branchValue: null,
            branchError: null,
            channelValue: "",
            channelError: null,
            referredByValue: "",
            referredByError: null,
            hasAppointmentValue: false,
            hasAppointmentError: null,
            appointmentValue: "",
            appointmentError: null,
            notesValue: "",
            notesError: null,
            submissionError: null,
        };
    }

    componentDidMount() {
        this.props.stopLoader();
    }

    updateName(value) {
        if (!this.valueIsEmpty(value)) {
            this.setState({
                nameValue: value,
                nameError: null,
            });
        } else {
            this.setState({
                nameValue: "",
                nameError: "The lead's full name is required.",
            });
        }
    }

    updatePhone(value) {
        let state = { phoneValue: value, phoneError: null };
        if (!this.valueIsEmpty(value) && !this.state.hasAppointmentValue) {
            state.emailError = null;
        } else if (this.valueIsEmpty(value)) {
            if (this.state.hasAppointmentValue) {
                state.phoneError = "A phone number is required when scheduling an appointment.";
            } else if (this.emailIsEmpty()) {
                state.phoneError = state.emailError =
                    "A phone number or email address is required.";
            }
        }
        this.setState(state);
    }

    emailIsEmpty() {
        return this.valueIsEmpty(this.state.emailValue);
    }

    updateEmail(value) {
        let state = { emailValue: value, emailError: null };
        if (!this.valueIsEmpty(value) && !this.state.hasAppointmentValue) {
            state.phoneError = null;
        } else if (this.valueIsEmpty(value)) {
            if (this.state.hasAppointmentValue) {
                state.emailError = "An email address is required when scheduling an appointment.";
            } else if (this.phoneIsEmpty()) {
                state.emailError = state.phoneError =
                    "A phone number or email address is required.";
            }
        }
        this.setState(state);
    }

    phoneIsEmpty() {
        return this.valueIsEmpty(this.state.phoneValue);
    }

    valueIsEmpty(value) {
        return value === null || value === "";
    }

    updateAddress(value) {
        let state = {
            addressValue: value,
        };
        if (this.state.hasAppointmentValue && this.valueIsEmpty(value)) {
            state.addressError =
                "You must enter an address when there is an appointment.";
        }
        this.setState(state);
    }

    updateBranch(value) {
        if (!this.valueIsEmpty(value)) {
            this.setState({
                branchValue: value,
                branchError: null,
            });
        } else {
            this.setState({
                branchValue: null,
                branchError: "A branch must be selected.",
            });
        }
    }

    updateChannel(value) {
        if (!this.valueIsEmpty(value)) {
            this.setState({
                channelValue: value,
                channelError: null,
            });
        } else {
            this.setState({
                channelValue: "",
                channelError: "A lead source must be selected.",
            });
        }
    }

    isReferral() {
        return this.state.channelValue === "[Self-Gen] Referral";
    }

    updateReferredBy(value) {
        let state = { referredByValue: value }
        if (this.valueIsEmpty(value) && this.isReferral()) {
            state.referredByError = "Please enter the full name of the customer who referred this lead.";
        } else {
            state.referredByError = null;
        }
        this.setState(state);
    }

    updateHasAppointment(value) {
        let state = {
            hasAppointmentValue: value,
            hasAppointmentError: null,
        };
        if (!value) {
            state.appointmentValue =
                state.appointmentError =
                state.addressError =
                    null;
            state.phoneError = state.emailError = this.phoneAndEmailAreEmpty() ? "A phone number or email address is required." : null;
        } else {
            state.addressError = this.state.addressValue ? null : "An address is required when scheduling an appointment.";
            state.phoneError = this.phoneIsEmpty() ? "A phone number is required when scheduling an appointment." : null;
            state.emailError = this.emailIsEmpty() ? "An email address is required when scheduling an appointment." : null;
        }
        this.setState(state);
    }

    phoneOrEmailIsEmpty() {
        return this.phoneIsEmpty() || this.emailIsEmpty();
    }

    phoneAndEmailAreEmpty() {
        return this.phoneIsEmpty() && this.emailIsEmpty();
    }

    updateAppointment(value) {
        if (!this.valueIsEmpty(value)) {
            this.setState({
                appointmentValue: value,
                appointmentError: null,
            });
        } else {
            let state = { appointmentValue: null, appointmentError: null };
            if (this.state.hasAppointmentValue) {
                state.appointmentError =
                    "You must enter the date and time for the appointment.";
            }
            this.setState(state);
        }
    }

    updateNotes(value) {
        this.setState({
            notesValue: value,
            notesError: null
        });
    }

    handleSubmission() {
        this.props.startLoader();
        this.submit()
            .then(() => this.redirect())
            .catch((error) => this.handleError(error));
    }

    submit() {
        const validation = this.validate();
        if (!validation.isValid) {
            this.setState(validation.errors);
            return Promise.reject();
        }
        return this.createLead();
    }

    validate() {
        const validation = { isValid: true, errors: {} };
        if (this.valueIsEmpty(this.state.branchValue)) {
            validation.isValid = false;
            validation.errors.branchError = "A branch must be selected.";
        }
        if (this.valueIsEmpty(this.state.nameValue)) {
            validation.isValid = false;
            validation.errors.nameError = "The lead's full name is required.";
        }
        if (
            !this.state.hasAppointmentValue &&
            this.phoneAndEmailAreEmpty()
        ) {
            validation.isValid = false;
            validation.errors.phoneError = validation.errors.emailError =
                "A phone number or email address is required.";
        }
        if (this.valueIsEmpty(this.state.channelValue)) {
            validation.isValid = false;
            validation.errors.channelError = "A lead source must be selected.";
        }
        if (this.isReferral() && this.valueIsEmpty(this.state.referredByValue)) {
            validation.isValid = false;
            validation.errors.referredByError = "Please enter the full name of the customer who referred this lead.";
        }
        if (
            this.state.hasAppointmentValue &&
            this.valueIsEmpty(this.state.appointmentValue)
        ) {
            validation.isValid = false;
            validation.errors.appointmentError =
                "You must enter the date and time for the appointment.";
        }
        if (
            this.state.hasAppointmentValue &&
            this.valueIsEmpty(this.state.addressValue)
        ) {
            validation.isValid = false;
            validation.errors.addressError =
                "You must enter an address when there is an appointment.";
        }
        if (
            this.state.hasAppointmentValue &&
            this.phoneIsEmpty()
        ) {
            validation.isValid = false;
            validation.errors.phoneError = "A phone number is required when scheduling an appointment.";
        }
        if (
            this.state.hasAppointmentValue &&
            this.emailIsEmpty()
        ) {
            validation.isValid = false;
            validation.errors.emailError = "An email address is required when scheduling an appointment.";
        }
        return validation;
    }

    redirect() {
        this.props.showPage("/deals/leads");
    }

    handleError(error) {
        this.setState({
            submissionError: error,
        });
        console.log(error);
        this.props.stopLoader();
    }

    createLead() {
        let lead = this.getLead();
        return this.props.platform.createLead(lead);
    }

    getLead() {
        let lead = new Lead();
        lead.status = "Active";
        lead.name = this.state.nameValue;
        lead.phone = this.state.phoneValue;
        lead.email = this.state.emailValue;
        lead.address = this.state.addressValue;
        lead.branch = this.state.branchValue;
        lead.consultants.push(this.props.user.profile.profile_id);
        lead.channel = this.state.channelValue;
        lead.source = SELF_GEN;
        if (this.state.hasAppointmentValue) {
            lead.stage = APPOINTMENT_SET_STAGE;
            lead.appointment = this.state.appointmentValue;
            lead.leadManagers.push(this.props.user.profile.profile_id);
        }
        if (this.isReferral()) {
            lead.referredBy = this.state.referredByValue;
        }
        if (!this.valueIsEmpty(this.state.notesValue)) {
            lead.leadManagerNotes = this.state.notesValue;
        }
        return lead;
    }

    render() {
        return (
            <ScreenContainer
                showPage={this.props.showPage}
                platform={this.props.platform}
            >
                <div className="NewLeadScreen-header">New Lead</div>
                <div className="NewLeadScreen-description">
                    Use the fields below to create a new lead. Please double
                    check the information you have entered before clicking the{" "}
                    <em>create</em> button.
                </div>
                <div className="NewLeadScreen-form">
                    <SelectionField
                        label="Branch"
                        placeholder="Select the branch to create this lead in..."
                        error={this.state.branchError}
                        onChange={this.updateBranch.bind(this)}
                    >
                        {this.props.branchList.branches.map((branch, index) => (
                            <SelectionOption key={index} value={branch}>
                                {branch.name}
                            </SelectionOption>
                        ))}
                    </SelectionField>
                    <SelectionField
                        label="Source"
                        placeholder="Select the source of the lead..."
                        error={this.state.channelError}
                        onChange={this.updateChannel.bind(this)}
                    >
                        <SelectionOption value="[Self-Gen] Referral">
                            My Referral
                        </SelectionOption>
                        <SelectionOption value="[Self-Gen] Knocking">
                            Door Knocking
                        </SelectionOption>
                        <SelectionOption value="[Self-Gen] Flyer">
                            Flyer
                        </SelectionOption>
                        <SelectionOption value="[Self-Gen] Event">
                            Event
                        </SelectionOption>
                        <SelectionOption value="[Self-Gen] Other">
                            Other
                        </SelectionOption>
                    </SelectionField>
                    {this.isReferral() && (
                        <SingleLineTextField
                            label="Referred By"
                            placeholder="Enter the full name of the customer who referred this lead..."
                            error={this.state.referredByError}
                            onChange={this.updateReferredBy.bind(this)}
                        />
                    )}
                    <SingleLineTextField
                        label="Full Name"
                        placeholder="Enter the lead's first and last name..."
                        error={this.state.nameError}
                        onChange={this.updateName.bind(this)}
                    />
                    <SingleLineTextField
                        label="Phone Number"
                        placeholder="Enter the lead's phone number..."
                        error={this.state.phoneError}
                        onChange={this.updatePhone.bind(this)}
                    />
                    <SingleLineTextField
                        label="Email Address"
                        placeholder="Enter the lead's email address..."
                        error={this.state.emailError}
                        onChange={this.updateEmail.bind(this)}
                    />
                    <AddressField
                        label="Address"
                        placeholder="Enter the lead's home address..."
                        error={this.state.addressError}
                        onChange={this.updateAddress.bind(this)}
                    />
                    <RadioField
                        label="Would you like to schedule an appointment for this lead?"
                        error={this.state.hasAppointmentError}
                        onChange={this.updateHasAppointment.bind(this)}
                    >
                        <RadioOption value={true}>Yes</RadioOption>
                        <RadioOption value={false}>No</RadioOption>
                    </RadioField>
                    {this.state.hasAppointmentValue && (
                        <DateTimeField
                            label="Appointment"
                            placeholder="Select a date..."
                            error={this.state.appointmentError}
                            onChange={this.updateAppointment.bind(this)}
                        />
                    )}
                    <MultiLineTextField
                        label="Notes"
                        placeholder="Enter any additional notes about this lead..."
                        error={this.state.notesError}
                        onChange={this.updateNotes.bind(this)}
                    />
                    <SubmitButton
                        text="Create"
                        onClick={this.handleSubmission.bind(this)}
                    />
                </div>
            </ScreenContainer>
        );
    }
}

export default NewLeadScreen;

/**
 * @abstract
 * @typedef T
 */
class PodioRepository {
    constructor(api) {
        /** @protected @readonly */
        this.api = api;
    }

    /**
     * @abstract
     * @param {T} object
     * @returns {Promise<T>}
     */
    create(object) {
        throw new Error("The PodioRepository class must be extended in order to call this method.");
    }
}

export default PodioRepository;
import { Component } from "react";
import PropTypes from "prop-types";
import "./SelectionOption.css";

class SelectionOption extends Component {
    // constructor(props) {
    //     super(props);
    // }

    handleChange() {
        if (this.props.onSelect) {
            this.props.onSelect(this.props.value, this.props.children);
        }
    }

    render() {
        return (
            <div className="SelectionOption" onClick={this.handleChange.bind(this)}>
                <div className="SelectionOption-container">
                    <span className="SelectionOption-text">{this.props.children}</span>
                </div>
            </div>
        );
    }
}

SelectionOption.defaultProps = {
    value: null
};

SelectionOption.propTypes = {
    value: PropTypes.any,
    onSelect: PropTypes.func,
    children: PropTypes.string.isRequired
};

export default SelectionOption;
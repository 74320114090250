import { Component } from "react";
import Profile from "../../components/profile/Profile/Profile";
import "./RevisionScreen.css";
import ProfileMetadata from "../../components/profile/ProfileMetadata/ProfileMetadata";
import ProfileHeader from "../../components/profile/ProfileHeader/ProfileHeader";
import ProfileModule from "../../components/profile/ProfileModule/ProfileModule";
import ProfileField from "../../components/profile/ProfileField/ProfileField";
import ProfileForm from "../../components/profile/ProfileForm/ProfileForm";
import ScreenContainer from "../../components/ScreenContainer/ScreenContainer";
import DesignRevisionForm from "../../components/forms/DesignRevisionForm/DesignRevisionForm";
import OpportunitiesScreen from "../OpportunitiesScreen/OpportunitiesScreen";
import ProfileLink from "../../components/profile/ProfileLink/ProfileLink";
import whiteLinkIcon from "../../assets/white-link.svg";
import Opportunity from "../../Opportunity";
import primaryLinkIcon from "../../assets/primary-link.svg";

class RevisionScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opportunity: this.parseLocationRecord()
        };
    }

    parseLocationRecord() {
        if (this.props.location && this.props.location.state && this.props.location.state.record) {
            const opportunity = new Opportunity();
            Object.assign(opportunity, this.props.location.state.record);
            return opportunity;
        }
        return null;
    }

    componentDidMount() {
        if (this.props.activeBranch) {
            if (this.state.opportunity) {
                this.stopLoader();
            } else {
                this.fetch(this.props.match.params.id);
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.activeBranch && (!prevProps.activeBranch || this.props.activeBranch.id !== prevProps.activeBranch.id)) {
            this.fetch(this.props.match.params.id);
        }
    }

    isLoading() {
        return this.state.opportunity === null;
    }

    changeStatus(value, notes) {
        this.props.startLoader();
        this.props.branch.updateOpportunityStage(this.props.opportunity, {
            stage: value,
            stageNotes: notes
        }).then(() => {
            this.props.showScreen(<OpportunitiesScreen/>);
        }).catch((error) => {
            console.log(error);
            this.props.stopLoader();
        });
    }

    fetch(id) {
        this.props.activeBranch.fetchOpportunity(id)
            .then((opportunity) => {
                this.setState({
                    opportunity
                });
            })
            .then(() => this.props.stopLoader());
    }

    goToQueue() {
        this.props.showPage("/deals/opportunities");
    }

    render() {
        const { opportunity } = this.state;
        if (opportunity) {
            return (
                <ScreenContainer
                    showPage={this.props.showPage}
                    platform={this.props.platform}
                >
                    {!this.isLoading() && (
                        <Profile>
                            <ProfileMetadata title="Details">
                                <ProfileHeader title={opportunity.getFullName()} footer={opportunity.appointment.toLocaleString()} />
                                {this.state.opportunity.externalProposalId && (
                                    <ProfileLink type="green" href={`https://v2.aurorasolar.com/projects/${this.state.opportunity.externalProposalId}/overview/dashboard`}>
                                        Aurora <img src={whiteLinkIcon} width={12} height={12}/>
                                    </ProfileLink>
                                )}
                                <ProfileModule>
                                    <ProfileField label="Email">{opportunity.email}</ProfileField>
                                    <ProfileField label="Phone">{opportunity.phone}</ProfileField>
                                    <ProfileField label="Address">
                                        <a className="primary-link" href={opportunity.getGoogleMapsLink()} target="_blank">
                                            <span>{opportunity.address.street_address ? opportunity.address.street_address : opportunity.address.value} </span>
                                            <img src={primaryLinkIcon} width={12} height="auto"/>
                                        </a>
                                    </ProfileField>
                                </ProfileModule>
                            </ProfileMetadata>
                            <ProfileForm>
                                <DesignRevisionForm
                                    opportunity={opportunity}
                                    podio={this.props.podio}
                                    branch={this.props.activeBranch}
                                    startLoader={this.props.startLoader}
                                    stopLoader={this.props.stopLoader}
                                    onSubmitted={this.goToQueue.bind(this)}
                                />
                            </ProfileForm>
                        </Profile>
                    )}
                </ScreenContainer>
            );
        }
        return null;
    }
}

export default RevisionScreen;
import ProjectSchema from "../../ProjectSchema";

class TestProjectSchema extends ProjectSchema {
    constructor(podioApi) {
        super(podioApi, 25597149);
        this.addField("name", 217967234, "title");
        this.addField("email", 217967236, "email");
        this.addField("phone", 217967235, "phone");
        this.addField("address", 217967241, "address");
        this.addField("stage", 217967247, "project-stage");
        this.addField("status", 217967246, "status");
        this.addField("panel", 217967258, "panel-type");
        this.addField("panelCount", 217967260, "of-panels");
        this.addField("systemSize", 217967261, "system-size");
        this.addField("price", 217967264, "loan-amount");
        this.addField("consultant", 217967244, "consultant");
        this.addField("requests", 237351335, "changes");
        this.addField("issues", 237308444, "issues");
    }
}

export default TestProjectSchema;
import LeadSchema from "../../LeadSchema";


class SalemLeadSchema extends LeadSchema {
    constructor(podioApi) {
        super(podioApi, 26531611);
        this.addField("name", 228346572, "first-name");
        this.addField("email", 228346574, "email");
        this.addField("phone", 228346573, "phone");
        this.addField("cosignerName", 228346575, "co-name");
        this.addField("cosignerEmail", 228346577, "co-email");
        this.addField("cosignerPhone", 228346576, "co-phone");
        this.addField("address", 228346578, "location-2");
        this.addField("status", 228346579, "lead-status");
        this.addField("stage", 228346582, "status");
        this.addField("appointment", 228346583, "appointment-date");
        this.addField("leadManager", 228346605, "lead-owner");
        this.addField("utilityCompany", 240650156, "utility-company");
        this.addField("installationAddress", 240650157, "installation-address");
        this.addField("roofMaterial", 240650158, "roof-material");
        this.addField("isLongTermResident", 240650167, "is-long-term-resident");
        this.addField("shortTermResidencyReason", 240650168, "short-term-residency-reason");
        this.addField("squareFootage", 240650169, "square-footage");
        this.addField("yearBuilt", 240650170, "year-built");
        this.addField("numberOfStories", 240650171, "number-of-stories");
        this.addField("numberOfResidents", 240650172, "number-of-residents");
        this.addField("heatingType", 240650173, "heating-type");
        this.addField("airConditioner", 240650174, "air-conditioner");
        this.addField("waterHeater", 240650175, "water-heater");
        this.addField("applianceType", 240650176, "appliance-type");
        this.addField("consumptionChanges", 240650177, "consumption-changes");
        this.addField("utilityMeterNumber", 240650178, "utility-meter-number");
        this.addField("utilityAccountNumber", 240650179, "utility-account-number");
        this.addField("proposalRequest1", 240650160, "proposal-request-1");
        this.addField("proposalRequest2", 240650161, "proposal-request-2");
        this.addField("proposalRequest3", 240650162, "proposal-request-3");
        this.addField("targetOffset", 236932945, "system-offset");
        this.addField("adders", 240650163, "adders-notes");
        this.addField("turnaroundTime", 240650164, "design-turnaround-time");
        this.addField("designStatus", 228346585, "request-design");
        this.addField("consultants", 228346606, "salesperson-2");
        this.addField("leadManagers", 228346605, "lead-owner");
        this.addField("proposalAppointment", 228346584, "2nd-touch-appt");
        this.addField("stageNotes", 240650159, "stage-notes");
        this.addField("leadManagerNotes", 228346580, "lead-notes");
        this.addField("channel", 228346604, "lead-source-do-not-change");
        this.addField("source", 228346611, "lead-source-category");
        this.addField("referredBy", 228346601, "referred-by");
        this.addField("type", 245082604, "type");
        this.addField("unqualifiedReason", 252161755, "unqualified-reason");
    }
}

export default SalemLeadSchema;
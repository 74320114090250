import { Component } from "react";
import TableData from "../../components/table/TableData";
import ObjectQueue from "../../components/templates/ObjectQueue";
import TableHeader from "../../components/table/TableHeader";

class SelfGensScreen extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <ObjectQueue
                {...this.props}
                title="My Self Gens"
                recordPath={"/deals/schedule/:id"}
                fetchResources={(user) => this.props.activeBranch.fetchSelfGens(user)}
                header={(
                    <TableHeader>
                        <TableData className="medium">ID</TableData>
                        <TableData>Name</TableData>
                        <TableData>Phone</TableData>
                        <TableData className="medium">Email</TableData>
                    </TableHeader>
                )}
                platform={this.props.platform}
                renderRecord={(lead) => (
                    <>
                        <TableData className="medium">{lead.id}</TableData>
                        <TableData>
                            <strong>{lead.getFullName()}</strong>
                        </TableData>
                        <TableData>
                            {lead.phone ? lead.phone : "No Number"}
                        </TableData>
                        <TableData className="medium">
                            {lead.email ? lead.email : "No Email"}
                        </TableData>
                    </>
                )}
            />
        );
    }
}

export default SelfGensScreen;
import { Component } from "react";
import "./TableRow.css";

class TableRow extends Component {
    constructor(props) {
        super(props);
    }

    handleClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {
        return (
            <div className="TableRow" onClick={this.handleClick.bind(this)}>
                <div className="TableRow-container">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default TableRow;
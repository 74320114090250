class Issue {
    constructor() {
        this.id = null;
        this.name = null;
        this.problem = null;
        this.options = null;
        this.status = null;
        this.createdOn = null;
    }
}

export default Issue;
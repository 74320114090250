import { Component } from "react";
import "./NestedFieldGroup.css";

class NestedFieldGroup extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="NestedFieldGroup">
                {this.props.title && (
                    <div className="NestedFieldGroup-title">
                        {this.props.title}
                    </div>
                )}
                <div className="NestedFieldGroup-container">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default NestedFieldGroup;
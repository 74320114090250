import { Component } from "react";
import FieldLabel from "../FieldLabel";
import FormField from "../FormField";
import PropTypes from "prop-types";
import "./NumericField.css";

class NumericField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isValid: true
        };
    }

    handleChange(event) {
        if (isNaN(event.target.value) && event.target.value !== "") {
            this.setState({
                isValid: false
            });
            if (this.props.onChange) {
                this.props.onChange(null);
            }
        } else {
            this.setState({
                isValid: true
            });
            if (this.props.onChange) {
                this.props.onChange(event.target.value);
            }
        }
    }

    render() {
        return (
            <FormField error={this.props.error}>
                <FieldLabel text={this.props.label}/>
                <div className={"NumericField" + (this.state.isValid ? "" : " invalid")}>
                    <div className="NumericField-container">
                        <div className="NumericField-input-container">
                            <input type="text" onChange={this.handleChange.bind(this)} placeholder={this.props.placeholder} inputMode="decimal"/>
                        </div>
                        <div className="NumericField-error-message">
                            Please enter a number.
                        </div>
                    </div>
                </div>
            </FormField>
        );
    }
}

NumericField.propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    label: PropTypes.string
};

export default NumericField;
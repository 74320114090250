import Agent from "../Agent";
import ObjectConverter from "./ObjectConverter";


class AgentConverter extends ObjectConverter {
    constructor() {
        super(null);
    }

    convert(profile) {
        const agent = new Agent();
        if (profile.type === "user") {
            agent.id = profile.profile_id;
            agent.name = profile.name;
            if (profile.mail && profile.mail.length > 0) {
                agent.email = profile.mail[0];
            }
            if (profile.phone && profile.phone.length > 0) {
                agent.phone = profile.phone[0];
            }
            if (profile.image) {
                if (profile.image.link) {
                    agent.avatarUrl = profile.image.link;
                }
                if (profile.image.thumbnail_link) {
                    agent.avatarThumbnailUrl = profile.image.thumbnail_link;
                }
            }
            if (profile.link) {
                agent.externalUrl = profile.link;
            }
        }
        return agent;
    }
}

export default AgentConverter;
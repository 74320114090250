import { Component } from "react";
import "./SubmitButton.css";


class SubmitButton extends Component {
    constructor(props) {
        super(props);
    }

    handleClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {
        return (
            <div className="SubmitButton">
                <div className="SubmitButton-container">
                    <button className="dark-button large-button full-button submit-button" onClick={this.handleClick.bind(this)}>
                        {this.props.text}
                    </button>
                </div>
            </div>
        );
    }
}

export default SubmitButton;
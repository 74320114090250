import { Component } from "react";
import "./ErrorAlert.css";

class ErrorAlert extends Component {
    constructor(props) {
        super(props);
    }

    handleClear() {
        if (this.props.onClear) {
            this.props.onClear();
        }
    }

    render() {
        return (
            <div className="ErrorAlert">
                <div className="ErrorAlert-close-btn" onClick={this.handleClear.bind(this)}>
                    &times;
                </div>
                <div className="ErrorAlert-container">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default ErrorAlert;
import { Component } from "react";
import CalendarSelection from "./CalendarSelection";
import "./DateField.css";


class DateField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: null,
            isOpen: false
        };
    }

    open() {
        this.setState({
            isOpen: true
        });
    }

    close() {
        this.setState({
            isOpen: false
        });
    }

    toggle() {
        if (this.state.isOpen) {
            this.close();
        } else {
            this.open();
        }
    }

    handleChange(date) {
        this.setState({
            value: date,
            isOpen: false
        });
        if (this.props.onChange) {
            this.props.onChange(date);
        }
    }

    clear() {
        this.setState({
            value: null
        });
        if (this.props.onChange) {
            this.props.onChange(null);
        }
    }

    render() {
        return (
            <div className={"DateField" + (this.state.isOpen ? " open" : "")}>
                <div className="DateField-container">
                    <div className="DateField-value-container" onClick={this.toggle.bind(this)}>
                        {this.state.value ? (
                            <div className="DateField-value">
                                {this.state.value.toLocaleDateString()}
                            </div>
                        ) : (
                            <div className="DateField-placeholder">
                                {this.props.placeholder}
                            </div>
                        )}
                        <div className="DateField-clear-btn" onClick={this.clear.bind(this)}>
                            <span>&times;</span>
                        </div>
                    </div>
                    <div className="DateField-calendar">
                        <CalendarSelection show value={this.state.value} onChange={this.handleChange.bind(this)}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default DateField;
import FileUploader from "../FileUploader";
import UploadedFile from "../UploadedFile";
import PodioSingleFileUploader from "./PodioSingleFileUploader";


class PodioItemAttachmentUploader extends FileUploader {
    constructor(api, itemId) {
        super(itemId);
        this.api = api;
        this.uploader = new PodioSingleFileUploader(api);
    }

    attach(files) {
        if (files instanceof Array) {
            const promises = [];
            for (const file of files) {
                promises.push(this.attachFile(file));
            }
            return Promise.all(promises);
        } else {
            throw new Error(`You must pass an array of files to attach them to the ${this.resourceId} item.`);
        }
    }

    attachFile(file) {
        return this.uploader.upload(file.blob, file.name)
            .then((response) => {
                const uploaded = UploadedFile.fromUploadable(file, response.file_id, response.link);
                return this.attachToItem(uploaded);
            });
    }

    attachToItem(uploaded) {
        return this.api.request("POST", `/file/${uploaded.externalId}/attach`, {
            "ref_type": "item",
            "ref_id": this.resourceId
        }).then((_) => uploaded);
    }

    remove(file) {
        return this.api.request("DELETE", `/file/${file.externalId}`)
            .then(() => {
                file.isLoading = false;
                return file;
            }).catch(() => {
                const error = new Error(`The Podio file with an id of ${file.externalId} could not be removed.`)
                return Promise.reject(error);
            });
    }
}

export default PodioItemAttachmentUploader;
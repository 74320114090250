import UploadableFile from "./UploadableFile";


class RemovableFile extends UploadableFile {
    static fromUploadedFile(file) {
        const removable = new RemovableFile();
        removable.id = file.id;
        removable.externalId = file.externalId;
        removable.name = file.name;
        removable.type = file.type;
        removable.blob = file.blob;
        return removable;
    }
}

export default RemovableFile;
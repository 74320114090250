import { Component } from "react";
import PropTypes from "prop-types";
import "./ProfileHeader.css";

class ProfileHeader extends Component {
    constructor(props) {
        super(props);
    }

    hasSubtitle() {
        return (null !== this.props.subtitle) && (undefined !== this.props.subtitle);
    }

    render() {
        return (
            <div className="ProfileHeader">
                <div className="ProfileHeader-container">
                    <div className="ProfileHeader-title">
                        <div className="ProfileHeader-title-text">
                            {this.props.title}
                        </div>
                        {this.hasSubtitle() && (
                            <div className="ProfileHeader-subtitle">
                                {this.props.subtitle}
                            </div>
                        )}
                    </div>
                    <div className="ProfileHeader-footer">
                        <div className="ProfileHeader-footer-container">
                            {this.props.footer}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ProfileHeader.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    footer: PropTypes.string
};

export default ProfileHeader;
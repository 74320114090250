class InvalidFormDataError extends Error {
    constructor(errors) {
        super("The form could not be submitted.");
        this.errors = errors;
    }

    toString() {
        let message = this.getHeadline();
        for (const error of this.errors) {
            message += "\n\t- " + error.message;
        }
        message += "\n";
        return message;
    }

    toHtml() {
        let html = `<div>${this.getHeadline()}<ul>`;
        for (const error of this.errors) {
            html += "<li>" + error.message + "</li>";
        }
        html += "</ul></div>";
        return html;
    }

    getHeadline() {
        if (this.errors.length > 1) {
            return "The form could not be submitted due to the following reasons:";
        } else {
           return "The form could not be submitted due to the following reason:";
        }
    }
}

export default InvalidFormDataError;
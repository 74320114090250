import OpportunitySchema from "../../OpportunitySchema";


class CedarRapidsOpportunitySchema extends OpportunitySchema {
    constructor(podioApi) {
        super(podioApi, 27445363);
        this.addField("name", 238651513, "first-name");
        this.addField("email", 238651515, "email");
        this.addField("phone", 238651514, "phone");
        this.addField("cosigner", 238651516, "co-name");
        this.addField("cosignerEmail", 238651518, "co-email");
        this.addField("cosignerPhone", 238651517, "co-phone");
        this.addField("address", 238651519, "location-2");
        this.addField("status", 238651521, "lead-status");
        this.addField("stage", 238651524, "status");
        this.addField("appointment", 238651526, "2nd-touch-appt");
        this.addField("auroraId", 240687362, "aurora-project-id");
        this.addField("specialRequests", 240687378, "special-requests");
        this.addField("nickname", 240687379, "nickname");
        this.addField("leadManager", 238651550, "lead-owner");
        this.addField("designStatus", 238651527, "request-design");
        this.addField("consultants", 238651551, "salesperson-2");
        this.addField("hoaName", 240687380, "hoa-name");
        this.addField("hoaEmail", 240687381, "hoa-email");
        this.addField("hoaPhone", 240687382, "hoa-phone");
        this.addField("propertyAccessInstructions", 240687383, "property-access-instructions");
        this.addField("numberOfTreeRemovals", 240687384, "number-of-tree-removals");
        this.addField("partyResponsibleForTreeRemoval", 240687385, "party-responsible-for-tree-removal");
        this.addField("customerWantsRoofBid", 240687386, "customer-wants-roof-bid");
        this.addField("customerWantsMorePanels", 240687387, "customer-wants-more-panels");
        this.addField("numberOfAdditionalPanels", 240687388, "number-of-additional-panels");
        this.addField("referredBy", 238651546, "referred-by");
        this.addField("customerPriority", 240687389, "customer-priority");
        this.addField("customerPriorityExplanation", 240687390, "customer-priority-explanation");
        this.addField("numberOfStories", 240687369, "number-of-stories");
        this.addField("roofAccess", 240687364, "can-access-roof-from-ground-level");
        this.addField("submitDeal", 238651554, "submit-deal-2");
        this.addField("stageNotes", 240687353, "stage-notes");
        this.addField("designRevisionNotes", 240687361, "design-revision-notes");
        this.addField("type", 245083132, "type");
        this.addField("notes", 238651553, "consultant-notes");
    }
}

export default CedarRapidsOpportunitySchema;
import React, { Component } from "react";
import "./SelectionField.css";
import FormField from "../FormField";
import FieldLabel from "../FieldLabel";

class SelectionField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selection: null,
            isOpen: false
        };
    }

    isSelected() {
        return null !== this.state.selection;
    }

    select(value, text) {
        this.setState({
            selection: {
                value: value,
                text: text
            },
            isOpen: false
        });
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    close() {
        this.setState({
            isOpen: false
        });
    }

    open() {
        this.setState({
            isOpen: true
        });
    }

    toggle() {
        if (this.state.isOpen) {
            this.close();
        } else {
            this.open();
        }
    }

    render() {
        return (
            <FormField error={this.props.error}>
                <FieldLabel text={this.props.label}/>
                <div className={"SelectionField" + (this.isSelected() ? " selected" : "") + (this.state.isOpen ? " open" : "")}>
                    <div className="SelectionField-container">
                        <div className="SelectionField-value-container" onClick={this.toggle.bind(this)}>
                            <div className="SelectionField-placeholder">
                                {this.props.placeholder}
                            </div>
                            <div className="SelectionField value">
                                {this.isSelected() && (this.state.selection.text)}
                            </div>
                        </div>
                        <div className="SelectionField-options">
                            <div className="SelectionField-options-container">
                                {React.Children.map(this.props.children, (child) => {
                                    if (React.isValidElement(child)) {
                                        return React.cloneElement(child, { onSelect: this.select.bind(this) });
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </FormField>
        );
    }
}

export default SelectionField;
import Calendar from "./Calendar";

const CALENDAR_AUTH_SCOPE = "https://www.googleapis.com/auth/calendar";
const CALENDAR_DISCOVERY_DOCS = "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest";
const CONSULTATION_CALENDAR_DESCRIPTION = "- Please have a physical or digital power bill nearby for your consultant.";

class GoogleCalendar extends Calendar {
    constructor(clientId, apiKey) {
        super();
        this.clientId = clientId;
        this.apiKey = apiKey;
        this.tokenClient = null;
        this.timezone = GoogleCalendar.getTimezone();
    }

    static getTimezone() {
        return new Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    load() {
        if (document.readyState === "complete") {
            return this.setup();
        } else {
            return new Promise((resolve, reject) => {
                window.addEventListener("load", () => {
                    this.setup().then(resolve).catch(reject);
                });
            });
        }
    }

    setup() {
        if (this.hasGoogleLibraries()) {
            this.setupTokenClient();
            return this.setupApiClient();
        } else {
            return Promise.reject(
                new Error("The GoogleCalendar class requires the Google Identity Service JavaScript SDK and the Google Apis JavaScript Client Library.")
            );
        }
    }

    hasGoogleLibraries() {
        return this.hasGoogleIdentityServiceSdk() && this.hasGoogleApiClient();
    }

    hasGoogleIdentityServiceSdk() {
        return window.google !== undefined && window.google.accounts !== undefined;
    }

    hasGoogleApiClient() {
        return window.gapi !== undefined;
    }

    setupTokenClient() {
        this.tokenClient = window.google.accounts.oauth2.initTokenClient({
            client_id: this.clientId,
            scope: CALENDAR_AUTH_SCOPE,
            callback: this.handleTokenResponse.bind(this),
        });
    }

    handleTokenResponse(response) {
        if (!response.error) {
            if (this.onAuth) {
                this.onAuth();
                this.onAuth = null;
            }
        } else {
            if (this.onAuthError) {
                this.onAuthError(response.error);
                this.onAuthError = null;
            }
        }
    }

    setupApiClient() {
        return this.loadApiClient()
            .then(
                this.initializeApiClient.bind(this)
            )
            .then(
                this.loadDiscoveryDocs.bind(this)
            );
    }

    loadApiClient() {
        return new Promise((resolve) => {
            window.gapi.load("client", resolve);
        });
    }

    initializeApiClient() {
        return window.gapi.client.init({apiKey: this.apiKey})
            .then(this.loadDiscoveryDocs.bind(this));
    }

    loadDiscoveryDocs() {
        return new Promise((resolve) => {
            window.gapi.client.load(CALENDAR_DISCOVERY_DOCS);
            resolve();
        });
    }

    authenticate() {
        return new Promise((resolve, reject) => {
            this.onAuth = resolve;
            this.onAuthError = reject;
            this.tokenClient.requestAccessToken();
        });
    }

    isAuthenticated() {
        return window.gapi.client.getToken() !== null;
    }

    scheduleConsultation(lead) {
        let event = this.getConsultationEventFromLead(lead);
        return this.createEvent(event);
    }

    createEvent(event) {
        let request = window.gapi.client.calendar.events.insert(event);
        return new Promise((resolve) => {
            request.execute(resolve);
        });
    }

    getConsultationEventFromLead(lead) {
        let event = {};
        event.calendarId = "primary";
        event.resource = this.getConsultationResourceFromLead(lead);
        return event;
    }

    getConsultationResourceFromLead(lead) {
        let resource = {};
        resource.summary = `${lead.name} - Purelight Power In-Home Consultation`;
        resource.location = lead.address;
        resource.description = lead.phone ? `- ${lead.phone}\n` : "";
        resource.description += CONSULTATION_CALENDAR_DESCRIPTION;
        resource.start = this.getDateAsEventObject(lead.appointment);
        resource.end = this.getConsultationEndDateFromStartDateAsEventObject(lead.appointment);
        resource.attendees = this.getConsultationAttendeesFromLead(lead);
        return resource;
    }

    /**
     * @param {Date} date 
     * @returns {object}
     */
    getDateAsEventObject(date) {
        return {
            dateTime: date.toISOString(),
            timeZone: this.timezone,
        };
    }

    /**
     * @param {Date} startDate 
     * @returns {object}
     */
    getConsultationEndDateFromStartDateAsEventObject(startDate) {
        let endDate = new Date();
        let fortyFiveMinutes = 45 * 60 * 1000;
        endDate.setTime(startDate.getTime() + fortyFiveMinutes);
        return this.getDateAsEventObject(endDate);
    }

    getConsultationAttendeesFromLead(lead) {
        let attendees = [];
        if (lead.email) {
            attendees.push(this.getEventAttendeeFromEmail(lead.email));
        }
        return attendees;
    }

    getEventAttendeeFromEmail(email) {
        return { email };
    }
}

export default GoogleCalendar;
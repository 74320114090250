import ProjectSchema from "../../ProjectSchema";


class CedarRapidsProjectSchema extends ProjectSchema {
    constructor(podioApi) {
        super(podioApi, 27445366);
        this.addField("name", 238651597, "title");
        this.addField("email", 238651599, "email");
        this.addField("phone", 238651598, "phone");
        this.addField("address", 238651604, "address");
        this.addField("stage", 238651612, "project-stage");
        this.addField("status", 238651608, "category-2");
        this.addField("panel", 238651624, "panel-type");
        this.addField("panelCount", 238651626, "of-panels");
        this.addField("systemSize", 238651627, "system-size");
        this.addField("price", 238651631, "loan-amount");
        this.addField("consultant", 238651606, "consultant");
    }
}

export default CedarRapidsProjectSchema;
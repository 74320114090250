import React, { Component } from "react";
import menuIcon from "../../assets/menu.svg";
import SidebarCollapseArrow from "../SidebarCollapseArrow";
import "./ScreenContainer.css";

class ScreenContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarOpen: false,
            open: null
        };
    }

    isActive(key) {
        return window.location.pathname.indexOf(key) !== -1;
    }

    isExact(key) {
        return window.location.pathname === key;
    }

    linkIsOpen(key) {
        return this.state.open === key;
    }

    toggleSublinks(key) {
        if (this.linkIsOpen(key)) {
            this.setState({
                open: null
            });
        } else {
            this.setState({
                open: key
            });
        }
    }

    showScreen(key) {
        if (!this.isExact(key)) {
            this.props.showPage(key);
        } else {
            this.setState({
                sidebarOpen: false
            });
        }
    }

    toggleSidebar() {
        if (this.state.sidebarOpen) {
            this.setState({
                sidebarOpen: false
            });
        } else {
            this.setState({
                sidebarOpen: true
            });
        }
    }

    logout() {
        this.props.platform.logout()
            .then(() => this.props.showPage("/login"));
    }

    render() {
        return (
            <div className="ScreenContainer">
                <div className="ScreenContainer-container">
                    <div className="ScreenContainer-topbar">
                        <div className="ScreenContainer-topbar-container">
                            <div className="ScreenContainer-topbar-sidebar-toggle" onClick={this.toggleSidebar.bind(this)}>
                                <img src={menuIcon}/>
                            </div>
                            <div className="ScreenContainer-logout-button">
                                <button className="white-link" onClick={this.logout.bind(this)}>
                                    Logout
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={"ScreenContainer-sidebar" + (this.state.sidebarOpen ? " open" : "")}>
                        <div className="ScreenContainer-sidebar-container">
                            <div className="ScreenContainer-sidebar-links">
                                <div
                                    className={"ScreenContainer-sidebar-link" + (this.isActive("/deals/self-gens") ? " active" : "")}
                                >
                                    <div
                                        className="ScreenContainer-sidebar-link-text"
                                        onClick={() => this.showScreen("/deals/self-gens")}
                                    >
                                        Self Gens
                                    </div>
                                </div>
                                <div className={"ScreenContainer-sidebar-link ScreenContainer-sidebar-link-collapsable" + (this.isActive("/leads") ? " active" : "") + (this.linkIsOpen("/leads") ? " open" : "")}>
                                    <div className="ScreenContainer-sidebar-link-text" onClick={() => this.toggleSublinks("/leads")}>
                                        Leads <SidebarCollapseArrow active={this.isActive("/leads")}/>
                                    </div>
                                    <div className="ScreenContainer-sidebar-sublinks">
                                        <div
                                            className={"ScreenContainer-sidebar-sublink" + (this.isActive("/deals/leads") ? " active" : "")}
                                        >
                                            <div
                                                className="ScreenContainer-sidebar-sublink-text"
                                                onClick={() => this.showScreen("/deals/leads")}
                                            >
                                                My Leads
                                            </div>
                                        </div>
                                        <div
                                            className={"ScreenContainer-sidebar-sublink" + (this.isActive("/deals/stale/leads") ? " active" : "")}
                                        >
                                            <div
                                                className="ScreenContainer-sidebar-sublink-text"
                                                onClick={() => this.showScreen("/deals/stale/leads")}
                                            >
                                                Not Dispositioned
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"ScreenContainer-sidebar-link ScreenContainer-sidebar-link-collapsable" + (this.isActive("/opportunities") ? " active" : "") + (this.linkIsOpen("/opportunities") ? " open" : "")}>
                                    <div className="ScreenContainer-sidebar-link-text" onClick={() => this.toggleSublinks("/opportunities")}>
                                        Opportunities <SidebarCollapseArrow active={this.isActive("/opportunities")}/>
                                    </div>
                                    <div className="ScreenContainer-sidebar-sublinks">
                                        <div
                                            className={"ScreenContainer-sidebar-sublink" + (this.isActive("/deals/opportunities") ? " active" : "")}
                                        >
                                            <div
                                                className="ScreenContainer-sidebar-sublink-text"
                                                onClick={() => this.showScreen("/deals/opportunities")}
                                            >
                                                My Opportunities
                                            </div>
                                        </div>
                                        <div
                                            className={"ScreenContainer-sidebar-sublink" + (this.isActive("/deals/stale/opportunities") ? " active" : "")}
                                        >
                                            <div
                                                className="ScreenContainer-sidebar-sublink-text"
                                                onClick={() => this.showScreen("/deals/stale/opportunities")}
                                            >
                                                Not Dispositioned
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={"ScreenContainer-sidebar-link" + (this.isActive("/designs") ? " active" : "")}
                                >
                                    <div
                                        className="ScreenContainer-sidebar-link-text"
                                        onClick={() => this.showScreen("/designs")}
                                    >
                                        Pending Designs
                                    </div>
                                </div>
                                <div
                                    className={"ScreenContainer-sidebar-link" + (this.isActive("/projects") ? " active" : "")}
                                >
                                    <div
                                        className="ScreenContainer-sidebar-link-text"
                                        onClick={() => this.showScreen("/projects")}
                                    >
                                        My Projects
                                    </div>
                                </div>
                                <div className="ScreenContainer-sidebar-link">
                                    <div className="ScreenContainer-sidebar-link-text">
                                        <a href="https://internal.commissions.purelightpower.com/login" target="__blank">
                                            My Commissions
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="ScreenContainer-sidebar-new-lead-button">
                                <div className="white-button full-button" onClick={() => this.props.showPage("/deals/leads/new")}>
                                    New Lead
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ScreenContainer-content">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default ScreenContainer;
import { Component } from "react";
import "./ProfileForm.css";

class ProfileForm extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="ProfileForm">
                <div className="ProfileForm-container">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default ProfileForm;
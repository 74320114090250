import { Component } from "react";
import "./Profile.css";

class Profile extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="Profile">
                <div className="Profile-container">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default Profile;
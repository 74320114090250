import LeadSchema from "../../LeadSchema";


class CedarRapidsLeadSchema extends LeadSchema {
    constructor(podioApi) {
        super(podioApi, 27445363);
        this.addField("name", 238651513, "first-name");
        this.addField("email", 238651515, "email");
        this.addField("phone", 238651514, "phone");
        this.addField("cosignerName", 238651516, "co-name");
        this.addField("cosignerEmail", 238651518, "co-email");
        this.addField("cosignerPhone", 238651517, "co-phone");
        this.addField("address", 238651519, "location-2");
        this.addField("status", 238651521, "lead-status");
        this.addField("stage", 238651524, "status");
        this.addField("appointment", 238651525, "appointment-date");
        this.addField("leadManager", 238651550, "lead-owner");
        this.addField("utilityCompany", 240687354, "utility-company");
        this.addField("installationAddress", 240687355, "installation-address");
        this.addField("roofMaterial", 240687356, "roof-material");
        this.addField("isLongTermResident", 240687365, "is-long-term-resident");
        this.addField("shortTermResidencyReason", 240687366, "short-term-residency-reason");
        this.addField("squareFootage", 240687367, "square-footage");
        this.addField("yearBuilt", 240687368, "year-built");
        this.addField("numberOfStories", 240687369, "number-of-stories");
        this.addField("numberOfResidents", 240687370, "number-of-residents");
        this.addField("heatingType", 240687371, "heating-type");
        this.addField("airConditioner", 240687372, "air-conditioner");
        this.addField("waterHeater", 240687373, "water-heater");
        this.addField("applianceType", 240687374, "appliance-type");
        this.addField("consumptionChanges", 240687375, "consumption-changes");
        this.addField("utilityMeterNumber", 240687376, "utility-meter-number");
        this.addField("utilityAccountNumber", 240687377, "utility-account-number");
        this.addField("proposalRequest1", 240687357, "proposal-request-1");
        this.addField("proposalRequest2", 240687358, "proposal-request-2");
        this.addField("proposalRequest3", 240687359, "proposal-request-3");
        this.addField("targetOffset", 238651534, "system-offset");
        this.addField("adders", 240687360, "adders-notes");
        this.addField("turnaroundTime", 240687363, "design-turnaround-time");
        this.addField("designStatus", 238651527, "request-design");
        this.addField("consultants", 238651551, "salesperson-2");
        this.addField("leadManagers", 238651550, "lead-owner");
        this.addField("proposalAppointment", 238651526, "2nd-touch-appt");
        this.addField("stageNotes", 240687353, "stage-notes");
        this.addField("leadManagerNotes", 238651522, "lead-notes");
        this.addField("channel", 238651549, "lead-source-do-not-change");
        this.addField("source", 238651562, "lead-source-category");
        this.addField("referredBy", 238651546, "referred-by");
        this.addField("type", 245083132, "type");
        this.addField("unqualifiedReason", 252160480, "unqualified-reason");
    }
}

export default CedarRapidsLeadSchema;
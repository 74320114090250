import { Component } from "react";
import TableData from "../../components/table/TableData";
import ObjectQueue from "../../components/templates/ObjectQueue";
import TableHeader from "../../components/table/TableHeader";


class PendingDesignsScreen extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ObjectQueue
                {...this.props}
                title="Pending Designs"
                fetchResources={(user) => this.props.activeBranch.fetchPendingDesigns(user)}
                header={(
                    <TableHeader>
                        <TableData className="medium">ID</TableData>
                        <TableData>Lead</TableData>
                        <TableData>Design Status</TableData>
                        <TableData className="medium">Proposal Date</TableData>
                    </TableHeader>
                )}
                platform={this.props.platform}
                renderRecord={(lead) => (
                    <>
                        <TableData className="medium">{lead.id}</TableData>
                        <TableData>{lead.getFullName()}</TableData>
                        <TableData>{lead.designStatus}</TableData>
                        <TableData className="medium">
                            {lead.proposalAppointment ? lead.proposalAppointment.toLocaleString() : "None"}
                        </TableData>
                    </>
                )}
            />
        );
    }
}

export default PendingDesignsScreen;
import ProjectSchema from "../../ProjectSchema";


class BillingsProjectSchema extends ProjectSchema {
    constructor(podioApi) {
        super(podioApi, 26712634);
        this.addField("name", 230354125, "title");
        this.addField("email", 230354127, "email");
        this.addField("phone", 230354126, "phone");
        this.addField("address", 230354132, "address");
        this.addField("stage", 230354140, "project-stage");
        this.addField("status", 230354137, "category-2");
        this.addField("panel", 230354151, "panel-type");
        this.addField("panelCount", 230354153, "of-panels");
        this.addField("systemSize", 230354154, "system-size");
        this.addField("price", 230354157, "loan-amount");
        this.addField("consultant", 230354135, "consultant");
    }
}

export default BillingsProjectSchema;
import Lead from "../Lead";
import AgentConverter from "./AgentConverter";
import ObjectConverter from "./ObjectConverter";
import PodioAppItemParser from "./parsers/PodioAppItemParser";

class LeadConverter extends ObjectConverter {
    constructor(leadSchema) {
        super(leadSchema);
        this.agentConverter = new AgentConverter();
    }

    convert(item) {
        const lead = new Lead();
        lead.id = item.item_id;
        lead.name = item.title;
        lead.externalUrl = item.link;
        const parser = new PodioAppItemParser(item, this.schema);
        if (parser.itemHasField("phone")) {
            lead.phone = parser.getItemContactInformationField("phone");
        }
        if (parser.itemHasField("email")) {
            lead.email = parser.getItemContactInformationField("email");
        }
        if (parser.itemHasField("appointment")) {
            lead.appointment = parser.getItemDateField("appointment");
        }
        if (parser.itemHasField("address")) {
            lead.address = parser.getItemAddressField("address");
        }
        if (parser.itemHasField("stage")) {
            lead.stage = parser.getItemSelectionField("stage");
        }
        if (parser.itemHasField("status")) {
            lead.status = parser.getItemSelectionField("status");
        }
        if (parser.itemHasField("designStatus")) {
            lead.designStatus = parser.getItemSelectionField("designStatus");
        }
        if (parser.itemHasField("proposalAppointment")) {
            lead.proposalAppointment = parser.getItemDateField("proposalAppointment");
        }
        if (parser.itemHasField("leadManagerNotes")) {
            lead.leadManagerNotes = parser.getItemTextField("leadManagerNotes");
        }
        if (parser.itemHasField("leadManagers")) {
            lead.leadManagers = this.getAgentsFromField(parser.getItemField("leadManagers"));
        }
        if (parser.itemHasField("consultants")) {
            lead.consultants = this.getAgentsFromField(parser.getItemField("consultants"));
        }
        if (parser.itemHasField("source")) {
            lead.source = parser.getItemSelectionField("source");
        }
        if (parser.itemHasField("referredBy")) {
            lead.referredBy = parser.getItemTextField("referredBy");
        }
        if (parser.itemHasField("type")) {
            lead.type = parser.getItemSelectionField("type");
        }
        return lead;
    }

    getAgentsFromField(field) {
        const agents = [];
        for (const contact of field.values) {
            const agent = this.agentConverter.convert(contact.value);
            agents.push(agent);
        }
        return agents;
    }
}

export default LeadConverter;
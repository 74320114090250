import { Component } from "react";
import image from "../../assets/upload.svg";
import "./AddFileIcon.css";


class AddFileIcon extends Component {
    handleClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {
        return (
            <div className="AddFileIcon" onClick={this.handleClick.bind(this)}>
                <img src={image}/>
            </div>
        );
    }
}

export default AddFileIcon;
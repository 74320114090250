import LeadConverter from "../../../converters/LeadConverter";
import OpportunityConverter from "../../../converters/OpportunityConverter";
import ProjectConverter from "../../../converters/ProjectConverter";
import PodioBranch from "../PodioBranch";
import CedarRapidsLeadSchema from "../schema/workspaces/cedar-rapids/CedarRapidsLeadSchema";
import CedarRapidsOpportunitySchema from "../schema/workspaces/cedar-rapids/CedarRapidsOpportunitySchema";
import CedarRapidsProjectSchema from "../schema/workspaces/cedar-rapids/CedarRapidsProjectSchema";


class CedarRapidsBranch extends PodioBranch {
    constructor(podioApi) {
        super(podioApi);
        this.id = 6;
        this.name = "Cedar Rapids";
        this.leadSchema = new CedarRapidsLeadSchema(podioApi);
        this.leadConverter = new LeadConverter(this.leadSchema);
        this.opportunitySchema = new CedarRapidsOpportunitySchema(podioApi);
        this.opportunityConverter = new OpportunityConverter(this.opportunitySchema);
        this.projectSchema = new CedarRapidsProjectSchema(podioApi);
        this.projectConverter = new ProjectConverter(this.projectSchema);
    }
}

export default CedarRapidsBranch;
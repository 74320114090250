class Lead {
    constructor() {
        this.id = null;
        this.name = null;
        this.phone = null;
        this.email = null;
        this.appointment = null;
        this.address = null;
        this.stage = null;
        this.status = null;
        this.designStatus = null;
        this.leadManagers = [];
        this.leadManagerNotes = null;
        this.proposalAppointment = null;
        this.branch = null;
        this.consultants = [];
        this.channel = null;
        this.source = null;
        this.referredBy = null;
        this.type = null;
        this.externalUrl = null;
    }
    
    getFullName() {
        return this.name;
    }

    getGoogleMapsLink() {
        if (this.address && this.address.value) {
            return "https://www.google.com/maps/search/?api=1&query=" + encodeURI(this.address.value);
        }
        return null;
    }

    getCityAndState() {
        let string = "Unknown City";
        if (this.address) {
            if (this.address.city) {
                string = this.address.city;
                if (this.address.state) {
                    string += ", " + this.address.state;
                }
            }
        }
        return string;
    }
}

export default Lead;
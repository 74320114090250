import ProjectSchema from "../../ProjectSchema";


class DesMoinesProjectSchema extends ProjectSchema {
    constructor(podioApi) {
        super(podioApi, 25150993);
        this.addField("name", 213263320, "title");
        this.addField("email", 213265014, "email");
        this.addField("phone", 213265013, "phone");
        this.addField("address", 213265019, "address");
        this.addField("stage", 216341050, "project-stage");
        this.addField("status", 213265023, "status");
        this.addField("panel", 213266864, "panel-type");
        this.addField("panelCount", 213266866, "of-panels");
        this.addField("systemSize", 213266867, "system-size");
        this.addField("price", 213266869, "loan-amount");
        this.addField("consultant", 213265021, "consultant");
    }
}

export default DesMoinesProjectSchema;
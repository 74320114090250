import LeadSchema from "../../LeadSchema";


class MedfordLeadSchema extends LeadSchema {
    constructor(podioApi) {
        super(podioApi, 23987242);
        this.addField("name", 201571613, "first-name");
        this.addField("email", 201571615, "email");
        this.addField("phone", 201571614, "phone");
        this.addField("cosignerName", 202229595, "co-name");
        this.addField("cosignerEmail", 202231759, "co-email");
        this.addField("cosignerPhone", 202229596, "co-phone");
        this.addField("address", 201571616, "location-2");
        this.addField("status", 201571617, "lead-status");
        this.addField("stage", 201571618, "status");
        this.addField("appointment", 201571620, "appointment-date");
        this.addField("leadManager", 201571634, "lead-owner");
        this.addField("utilityCompany", 240180799, "utility-company");
        this.addField("installationAddress", 240180800, "installation-address");
        this.addField("mountingType", 202246621, "system-type");
        this.addField("roofMaterial", 240180801, "roof-material");
        this.addField("isLongTermResident", 240180802, "short-term-residency");
        this.addField("shortTermResidencyReason", 240180803, "short-term-residency-reason");
        this.addField("squareFootage", 240180804, "square-footage");
        this.addField("yearBuilt", 240180805, "year-built");
        this.addField("numberOfStories", 240180806, "number-of-stories");
        this.addField("numberOfResidents", 240180807, "number-of-residents");
        this.addField("heatingType", 240180808, "heating-type");
        this.addField("airConditioner", 240180809, "air-conditioner");
        this.addField("waterHeater", 240180810, "water-heater");
        this.addField("applianceType", 240180811, "appliance-type");
        this.addField("consumptionChanges", 240180812, "consumption-changes");
        this.addField("utilityMeterNumber", 240180813, "utility-meter-number");
        this.addField("utilityAccountNumber", 240180814, "utility-account-number");
        this.addField("proposalRequest1", 240180815, "proposal-request-1");
        this.addField("proposalRequest2", 240180816, "proposal-request-2");
        this.addField("proposalRequest3", 240180817, "proposal-request-3");
        this.addField("aestheticRequests", 240180818, "aesthetic-requests");
        this.addField("targetOffset", 236932932, "system-offset");
        this.addField("adders", 240180819, "adders-notes");
        this.addField("turnaroundTime", 240180820, "design-turnaround-time");
        this.addField("designStatus", 201572148, "request-design");
        this.addField("consultants", 201571635, "salesperson-2");
        this.addField("leadManagers", 201571634, "lead-owner");
        this.addField("proposalAppointment", 201571621, "2nd-touch-appt");
        this.addField("stageNotes", 240180821, "stage-notes");
        this.addField("leadManagerNotes", 201571619, "lead-notes");
        this.addField("channel", 201571633, "lead-source-do-not-change");
        this.addField("source", 204932205, "lead-source-category");
        this.addField("referredBy", 202246623, "referred-by");
        this.addField("type", 245075879, "type");
        this.addField("unqualifiedReason", 252161328, "unqualified-reason");
    }
}

export default MedfordLeadSchema;
import { Component } from "react";
import "./LoginScreen.css";
import logo from "../../assets/logos/vertical-dark.svg";

class LoginScreen extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.stopLoader();
    }

    loginWithPodio() {
        window.location = this.getAuthUrl();
    }

    static getQuery() {
        const query = new URLSearchParams(window.location.search);
        return query;
    }

    getRedirectPath() {
        const query = LoginScreen.getQuery();
        if (query.has("redirect")) {
            const path = query.get("redirect");
            query.delete("redirect");
            return path + "?" + query.toString();
        } else {
            return "";
        }
    }

    getAuthUrl() {
        let redirectUrl = window.location.protocol + "//" + window.location.host + this.getRedirectPath();
        return this.props.podio.getAuthorizationURL(redirectUrl);
    }

    render() {
        return (
            <div className="LoginScreen">
                <div className="LoginScreen-container">
                    <div className="LoginScreen-logo">
                        <img src={logo}/>
                    </div>
                    <div className="LoginScreen-button">
                        <button className="dark-button large-button" onClick={this.loginWithPodio.bind(this)}>Login with Podio</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginScreen;
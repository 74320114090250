import PodioRepository from "../PodioRepository";

/**
 * @typedef {import("../../../../Lead").default} Lead
 */

const IN_HOME_CONSULTATION = "In Home";


/**
 * @extends {PodioRepository<Lead>}
 */
class PodioLeadRepository extends PodioRepository {
    create(lead) {
        if (lead.branch) {
            return this.translate(lead)
                .then((dataObject) => 
                    this.pushDataObject(dataObject, lead.branch.leadSchema.id)
                )
                .then((response) => {
                    lead.id = response.item_id;
                    return lead;
                });
        } else {
            return Promise.reject(
                new Error("A lead cannot be created in Podio unless a branch is specified.")
            );
        }
    }

    pushDataObject(dataObject, appId) {
        return this.api.request(
            "POST",
            `/item/app/${appId}`,
            { fields: dataObject }
        );
    }

    translate(lead) {
        let dataObject = this.convertToDataObject(lead);
        return lead.branch.leadSchema.translateFields(dataObject);
    }

    convertToDataObject(lead) {
        let dataObject = { name: lead.getFullName() };
        if (lead.phone) {
            dataObject.phone = lead.phone;
        }
        if (lead.email) {
            dataObject.email = lead.email;
        }
        if (lead.appointment) {
            dataObject.appointment = lead.appointment;
        }
        if (lead.address) {
            dataObject.address = lead.address;
        }
        if (lead.stage) {
            dataObject.stage = lead.stage;
        }
        if (lead.status) {
            dataObject.status = lead.status;
        }
        if (lead.designStatus) {
            dataObject.designStatus = lead.designStatus;
        }
        if (lead.leadManagers.length > 0) {
            dataObject.leadManagers = lead.leadManagers;
        }
        if (lead.leadManagerNotes) {
            dataObject.leadManagerNotes = lead.leadManagerNotes;
        }
        if (lead.proposalAppointment) {
            dataObject.proposalAppointment = lead.proposalAppointment;
        }
        if (lead.consultants.length > 0) {
            dataObject.consultants = lead.consultants;
        }
        if (lead.channel) {
            dataObject.channel = lead.channel;
        }
        if (lead.source) {
            dataObject.source = lead.source;
        }
        if (lead.referredBy) {
            dataObject.referredBy = lead.referredBy;
        }
        if (lead.type) {
            dataObject.type = lead.type;
        } else {
            dataObject.type = IN_HOME_CONSULTATION;
        }
        return dataObject;
    }
}

export default PodioLeadRepository;
import { Component } from "react";
import SelectionOption from "../../forms/fields/SelectionField/SelectionOption";


class ProfileStatusSelectionOption extends Component {
    constructor(props) {
        super(props);
    }

    handleSelection(value, text) {
        if (this.props.onSelect) {
            const notesRequired = this.props.notesRequired ? true : false;
            this.props.onSelect({
                value,
                notesRequired
            }, text);
        }
    }

    render() {
        return (
            <SelectionOption value={this.props.value} onSelect={this.handleSelection.bind(this)}>
                {this.props.children}
            </SelectionOption>
        );
    }
}

export default ProfileStatusSelectionOption;
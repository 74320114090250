class ObjectConverter {
    constructor(schema) {
        this.schema = schema;
    }

    convertMany(items) {
        const objects = [];
        for (const item of items) {
            const object = this.convert(item);
            objects.push(object);
        }
        return objects;
    }

    convert() {
        throw new Error("The ObjectConverter class needs to be extended, it cannot be used directly.");
    }
}

export default ObjectConverter;
import OpportunitySchema from "../../OpportunitySchema";


class DesMoinesOpportunitySchema extends OpportunitySchema {
    constructor(podioApi) {
        super(podioApi, 25150942);
        this.addField("name", 213262894, "first-name");
        this.addField("email", 213262896, "email");
        this.addField("phone", 213262895, "phone");
        this.addField("cosigner", 213262897, "co-name");
        this.addField("cosignerEmail", 213262899, "co-email");
        this.addField("cosignerPhone", 213262898, "co-phone");
        this.addField("address", 213262900, "location-2");
        this.addField("status", 213262901, "lead-status");
        this.addField("stage", 213262904, "status");
        this.addField("appointment", 213262906, "2nd-touch-appt");
        this.addField("auroraId", 240648522, "aurora-project-id");
        this.addField("specialRequests", 240648523, "special-requests");
        this.addField("nickname", 240648524, "nickname");
        this.addField("leadManager", 213262924, "lead-owner");
        this.addField("designStatus", 213262907, "request-design");
        this.addField("consultants", 213262925, "salesperson-2");
        this.addField("hoaName", 240648525, "hoa-name");
        this.addField("hoaEmail", 240648526, "hoa-email");
        this.addField("hoaPhone", 240648527, "hoa-phone");
        this.addField("propertyAccessInstructions", 240648528, "property-access-instructions");
        this.addField("numberOfTreeRemovals", 240648529, "number-of-tree-removals");
        this.addField("partyResponsibleForTreeRemoval", 240648530, "party-responsible-for-tree-removal");
        this.addField("customerWantsRoofBid", 240648531, "customer-wants-roof-bid");
        this.addField("customerWantsMorePanels", 240648532, "customer-wants-more-panels");
        this.addField("numberOfAdditionalPanels", 240648533, "number-of-additional-panels");
        this.addField("referredBy", 213262920, "referred-by");
        this.addField("customerPriority", 240648534, "customer-priority");
        this.addField("customerPriorityExplanation", 240648535, "customer-priority-explanation");
        this.addField("numberOfStories", 240648150, "number-of-stories");
        this.addField("roofAccess", 240648537, "can-access-roof-from-ground-level");
        this.addField("submitDeal", 222222669, "submit-deal-2");
        this.addField("stageNotes", 240648164, "stage-notes");
        this.addField("designRevisionNotes", 240648538, "design-revision-notes");
        this.addField("type", 245082742, "type");
        this.addField("notes", 213262926, "consultant-notes");
    }
}

export default DesMoinesOpportunitySchema;
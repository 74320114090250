import LeadConverter from "../../../converters/LeadConverter";
import OpportunityConverter from "../../../converters/OpportunityConverter";
import ProjectConverter from "../../../converters/ProjectConverter";
import PodioBranch from "../PodioBranch";
import DesMoinesLeadSchema from "../schema/workspaces/des-moines/DesMoinesLeadSchema";
import DesMoinesOpportunitySchema from "../schema/workspaces/des-moines/DesMoinesOpportunitySchema";
import DesMoinesProjectSchema from "../schema/workspaces/des-moines/DesMoinesProjectSchema";


class DesMoinesBranch extends PodioBranch {
    constructor(podioApi) {
        super(podioApi);
        this.id = 3;
        this.name = "Des Moines";
        this.leadSchema = new DesMoinesLeadSchema(podioApi);
        this.leadConverter = new LeadConverter(this.leadSchema);
        this.opportunitySchema = new DesMoinesOpportunitySchema(podioApi);
        this.opportunityConverter = new OpportunityConverter(this.opportunitySchema);
        this.projectSchema = new DesMoinesProjectSchema(podioApi);
        this.projectConverter = new ProjectConverter(this.projectSchema);
    }
}

export default DesMoinesBranch;
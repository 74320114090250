import { Component } from "react";
import "./LeadScreen.css";
import Profile from "../../components/profile/Profile/Profile";
import ProfileMetadata from "../../components/profile/ProfileMetadata/ProfileMetadata";
import ProfileModule from "../../components/profile/ProfileModule/ProfileModule";
import ProfileField from "../../components/profile/ProfileField/ProfileField";
import ProfileForm from "../../components/profile/ProfileForm/ProfileForm";
import RequestDesignForm from "../../components/forms/RequestDesignForm/RequestDesignForm";
import ScreenContainer from "../../components/ScreenContainer/ScreenContainer";
import Lead from "../../Lead";
import NestedFieldGroup from "../../components/forms/NestedFieldGroup";
import primaryLinkIcon from "../../assets/primary-link.svg";
import ProfileAdjustableDateHeader from "../../components/profile/ProfileAdjustableDateHeader/ProfileAdjustableDateHeader";
import ProfileStatusSelectorForLeadScreen from "../../components/profile/ProfileStatusSelectorForLeadScreen/ProfileStatusSelectorForLeadScreen";


class LeadScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lead: this.parseLocationRecord()
        };
    }

    parseLocationRecord(obj) {
        if (this.props.location && this.props.location.state && this.props.location.state.record) {
            const lead = new Lead();
            Object.assign(lead, this.props.location.state.record);
            return lead;
        }
        return null;
    }

    componentDidMount() {
        if (this.props.activeBranch) {
            if (this.state.lead) {
                this.props.stopLoader();
            } else {
                this.fetchLead(this.props.match.params.id);
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.activeBranch && (!prevProps.activeBranch || this.props.activeBranch.id !== prevProps.activeBranch.id)) {
            if (this.state.lead) {
                this.props.stopLoader();
            } else {
                this.fetchLead(this.props.match.params.id);
            }
        }
    }

    fetchLead(id) {
        this.props.activeBranch.fetchLead(id)
            .then((lead) => {
                this.setState({
                    lead
                });
                this.props.stopLoader();
            });
    }

    changeStatus(value, notes, substatus = null) {
        this.props.startLoader();
        if (value === "Unqualified") {
            this.changeStatusToUnqualified(substatus);
        } else {
            this.changeStatusTo(value, notes);
        }
    }

    changeStatusToUnqualified(substatus) {
        this.props.activeBranch.updateLeadStage(this.state.lead, {
            stage: "Unqualified",
            stageNotes: null,
            unqualifiedReason: substatus
        }).then(() => {
            this.props.showPage("/deals/leads");
        }).catch((error) => {
            console.log(error);
            this.props.stopLoader();
        });
    }

    changeStatusTo(value, notes) {
        this.props.activeBranch.updateLeadStage(this.state.lead, {
            stage: value,
            stageNotes: notes,
            unqualifiedReason: []
        }).then(() => {
            this.props.showPage("/deals/leads");
        }).catch((error) => {
            console.log(error);
            this.props.stopLoader();
        });
    }

    complete() {
        this.props.showPage("/designs");
    }

    updateAppointment(date) {
        this.props.startLoader();
        this.props.activeBranch.updateLeadField(this.state.lead, "appointment", date).then(() => {
            const lead = this.state.lead;
            lead.appointment = date;
            this.setState({
                lead
            });
        }).catch().then(() => {
            this.props.stopLoader();
        });
    }

    render() {
        if (this.state.lead) {
            const { lead } = this.state;
            return (
                <ScreenContainer showPage={this.props.showPage} platform={this.props.platform}>
                    <Profile>
                        <ProfileMetadata title="Details">
                            <ProfileAdjustableDateHeader
                                title={lead.getFullName()}
                                date={lead.appointment}
                                onChange={this.updateAppointment.bind(this)}
                            />
                            <ProfileModule>
                                <ProfileField label="Email">{lead.email}</ProfileField>
                                <ProfileField label="Phone">{lead.phone}</ProfileField>
                                <ProfileField label="Address">
                                    <a className="primary-link" href={lead.getGoogleMapsLink()} target="_blank">
                                        <span>{lead.address.street_address ? lead.address.street_address : lead.address.value} </span>
                                        <img src={primaryLinkIcon} width={12} height="auto"/>
                                    </a>
                                </ProfileField>
                                {lead.leadManagers.length > 1 ? (
                                    <ProfileField label="Lead Managers">
                                        <ul>
                                            {lead.leadManagers.map((leadManager) => (
                                                <li>{leadManager.name}</li>
                                            ))}
                                        </ul>
                                    </ProfileField>
                                ) : lead.leadManagers.length > 0 && (
                                    <ProfileField label="Lead Manager">
                                        {lead.leadManagers[0].name}
                                    </ProfileField>
                                )}
                            </ProfileModule>
                            <ProfileStatusSelectorForLeadScreen
                                label="Lead Status"
                                submission="Update"
                                onSubmit={this.changeStatus.bind(this)}
                            />
                        </ProfileMetadata>
                        <ProfileForm>
                            {lead.leadManagerNotes && (
                                <NestedFieldGroup title="Lead Manager Notes">
                                    <div
                                        dangerouslySetInnerHTML={{__html: lead.leadManagerNotes }}
                                    />
                                </NestedFieldGroup>
                            )}
                            <RequestDesignForm
                                lead={lead}
                                podio={this.props.podio}
                                branch={this.props.activeBranch}
                                startLoader={this.props.startLoader}
                                stopLoader={this.props.stopLoader}
                                onSubmitted={this.complete.bind(this)}
                            />
                        </ProfileForm>
                    </Profile>
                </ScreenContainer>
            );
        }
        return (<div></div>);
    }
}

export default LeadScreen;
class ProposalRequest {
    constructor() {
        this.id = null;
        this.mountingType = null;
        this.description = null;
        this.needsTreeRemoval = false;
        this.treeRemovalDescription = null;
        this.siteMap = null;
        this.targetOffset = null;
        this.addersNotes = null;
    }
}

export default ProposalRequest;
import CategoryMap from "./CategoryMap";

class PodioAppSchema {
    constructor(podioApi, appId) {
        this.podio = podioApi;
        this.id = appId;
        this.keys = [];
        this.types = [];
        this.fieldIds = [];
        this.externalIds = [];
        this.categoryMaps = [];
    }

    addKey(key, type = "text") {
        if (this.keys.length === this.fieldIds.length && this.keys.length === this.externalIds.length && this.keys.length === this.types.length) {
            this.keys.push(key);
            this.types.push(type);
            this.fieldIds.push(null);
            this.externalIds.push(null);
        } else {
            throw new Error("There are inconsistencies in your field definitions.");
        }
    }

    addField(key, fieldId, externalId) {
        const index = this.keys.indexOf(key);
        if (index > -1) {
            this.fieldIds[index] = fieldId;
            this.externalIds[index] = externalId;
        } else {
            throw new Error(`The ${key} key is invalid.`);
        }
    }

    getAppId() {
        return this.id;
    }

    getType(field) {
        const index = this.keys.indexOf(field);
        if (index > -1) {
            return this.types[index];
        } else {
            throw new Error(`There is no ${field} field.`);
        }
    }

    getTypeById(fieldId) {
        const index = this.fieldIds.indexOf(fieldId);
        if (index > -1) {
            return this.types[index];
        } else {
            throw new Error(`There is no field with an id of ${fieldId}.`);
        }
    }

    getFieldId(field) {
        const index = this.keys.indexOf(field);
        if (index > -1) {
            return this.fieldIds[index];
        } else {
            throw new Error(`There is no ${field} field.`);
        }
    }

    getExternalId(field) {
        const index = this.keys.indexOf(field);
        if (index > -1) {
            return this.externalIds[index];
        } else {
            throw new Error(`There is no ${field} field.`);
        }
    }

    getOptionId(field, optionText) {
        const fieldId = this.getFieldId(field);
        return this.getCategoryMap(fieldId)
            .then((categoryMap) => categoryMap.getOptionId(optionText));
    }

    getMultipleOptionIds(field, optionsText) {
        const fieldId = this.getFieldId(field);
        return this.getCategoryMap(fieldId)
            .then((categoryMap) => {
                const ids = [];
                for (const optionText of optionsText) {
                    const id = categoryMap.getOptionId(optionText);
                    ids.push(id);
                }
                return ids;
            });
    }

    getCategoryMap(fieldId) {
        try {
            const categoryMap = this.findCategoryMap(fieldId)
            return Promise.resolve(categoryMap);
        } catch (error) {
            return this.saveCategoryMaps()
                .then(() => this.findCategoryMap(fieldId));
        }
    }

    findCategoryMap(fieldId) {
        for (const categoryMap of this.categoryMaps) {
            if (categoryMap.getField() === fieldId) {
                return categoryMap;
            }
        }
        throw new Error(`There is no existing category map for the ${fieldId} field.`);
    }

    saveCategoryMaps() {
        return this.podio.request("GET", `/app/${this.id}`)
            .then((response) => {
                this.categoryMaps = [];
                for (const field of response.fields) {
                    if (field.status === "active" && field.type === "category") {
                        const categoryMap = CategoryMap.fromJson(field);
                        this.categoryMaps.push(categoryMap);
                    }
                }
                return Promise.resolve();
            });
    }

    saveCategoryMap(fieldId) {
        return this.podio.request("GET", `/app/${this.id}/field/${fieldId}`)
            .then((response) => {
                const categoryMap = CategoryMap.fromJson(response);
                this.categoryMaps.push(categoryMap);
                return Promise.resolve();
            });
    }

    translateField(key, value) {
        const index = this.keys.indexOf(key);
        if (index > -1) {
            if (this.types[index] === "category") {
                return this.translateCategoryField(this.fieldIds[index], value);
            } else {
                return this.translateRegularField(this.fieldIds[index], value);
            }
        } else {
            throw new Error(`The ${key} field could not be found in the schema.`);
        }
    }

    translateRegularField(fieldId, value) {
        return new Promise((resolve, reject) => {
            try {
                const processedValue = this.processRegularField(fieldId, value);
                resolve(processedValue);
            } catch (e) {
                reject(e);
            }
        });
    }

    translateFields(untranslated) {
        let { regular, category } = this.separateCategoryFields(untranslated);
        regular = this.processRegularFields(regular);
        if (Object.keys(category).length > 0) {
            return this.translateCategoryFields(category)
                .then((translated) => {
                    return {
                        ...regular,
                        ...translated
                    };
                });
        } else {
            return Promise.resolve(regular);
        }
    }

    processRegularFields(regular) {
        const processed = {};
        for (const id in regular) {
            processed[id] = this.processRegularField(id, regular[id]);
        }
        return processed;
    }

    processRegularField(fieldId, value) {
        const type = this.getTypeById(parseInt(fieldId));
        let adjustedValue = value;
        if (type === "date" && value instanceof Date) {
            adjustedValue = `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()} ${value.getHours()}:${value.getMinutes()}:${value.getSeconds()}`;
        } else if (type === "phone") {
            adjustedValue = {
                value: value,
                type: "main"
            };
        } else if (type === "email") {
            adjustedValue = {
                value: value,
                type: "home"
            };
        }
        return adjustedValue;
    }

    separateCategoryFields(untranslatedFields) {
        const regularFields = {};
        const categoryFields = {};
        for (const key in untranslatedFields) {
            if (this.keys.includes(key)) {
                const index = this.keys.indexOf(key);
                const fieldId = this.fieldIds[index];
                const type = this.types[index];
                if (type === "category") {
                    categoryFields[fieldId] = untranslatedFields[key];
                } else if (!["file", "image"].includes(type)) {
                    regularFields[fieldId] = untranslatedFields[key];
                }
            } else {
                throw new Error(`Could not create filter because "${key}" is not a valid key.`);
            }
        }
        return {
            regular: regularFields,
            category: categoryFields
        };
    }

    mapFields(fields) {
        const mappedFields = {};
        for (const key in fields) {
            if (this.keys.includes(key)) {
                const fieldId = this.getFieldId(key);
                mappedFields[fieldId] = fields[key];
            }
        }
        return mappedFields;
    }

    translateCategoryField(fieldId, value) {
        if (this.categoryMaps.length === 0) {
            return this.saveCategoryMaps()
                .then(() => this.translateCategoryField(fieldId, value));
        } else {
            return this.translateCategoryField(fieldId, value);
        }
    }

    translateCategoryFields(untranslated) {
        if (this.categoryMaps.length === 0) {
            return this.saveCategoryMaps()
                .then(() => this.translateCategoryFieldsFromSavedCategoryMaps(untranslated));
        } else {
            return this.translateCategoryFieldsFromSavedCategoryMaps(untranslated);
        }
    }

    translateCategoryFieldsFromSavedCategoryMaps(untranslated) {
        return new Promise((resolve) => {
            const translated = {};
            for (const fieldId in untranslated) {
                translated[fieldId] = this.getCategoryOptionIds(fieldId, untranslated[fieldId]);
            }
            resolve(translated);
        });
    }

    translateCategoryFieldFromSavedCategoryMaps(fieldId, options) {
        return new Promise((resolve, reject) => {
            try {
                const optionIds = this.getCategoryOptionIds(fieldId, options);
                resolve(optionIds);
            } catch (e) {
                reject(e);
            }
        });
    }

    getCategoryOptionIds(fieldId, options) {
        const categoryMap = this.findCategoryMap(parseInt(fieldId));
        if (options instanceof Array) {
            const ids = [];
            for (const option of options) {
                ids.push(categoryMap.getOptionId(option));
            }
            return ids;
        } else {
            return categoryMap.getOptionId(options);
        }
    }
}

export default PodioAppSchema;
import FieldNotFoundError from "../errors/FieldNotFoundError";
import PodioItemParser from "./PodioItemParser";


class PodioAppItemParser extends PodioItemParser {
    constructor(item, schema) {
        super(item);
        this.schema = schema;
    }

    itemHasField(key) {
        try {
            const fieldId = this.schema.getFieldId(key);
            for (const field of this.item.fields) {
                if (fieldId === field.field_id) {
                    return true;
                }
            }
        } catch (e) {}
        return false;
    }

    getItemField(key) {
        const fieldId = this.schema.getFieldId(key);
        for (const field of this.item.fields) {
            if (fieldId === field.field_id) {
                return field;
            }
        }
        throw new FieldNotFoundError(`There is not field with an id of ${fieldId} on the ${this.item.item_id} (${this.item.title}) item.`);
    }
}

export default PodioAppItemParser;
import { Component } from "react";
import RadioField from "../fields/RadioField/RadioField";
import RadioOption from "../fields/RadioField/RadioOption";
import SelectionField from "../fields/SelectionField/SelectionField";
import SelectionOption from "../fields/SelectionField/SelectionOption";
import SingleLineTextField from "../fields/SingleLineTextField/SingleLineTextField";
import NestedFieldGroup from "../NestedFieldGroup";
import MultipleFileField from "../fields/MultipleFileField/MultipleFileField";
import MultiLineTextField from "../fields/MultiLineTextField/MultiLineTextField";
import NumericField from "../fields/NumericField/NumericField";
import "./RequestDesignForm.css";
import InvalidFormDataError from "../errors/FormValidationError";
import ErrorAlert from "../ErrorAlert/ErrorAlert";
import DateTimeField from "../fields/DateTimeField/DateTimeField";
import PodioItemAttachmentUploader from "../../../storage/podio/PodioItemAttachmentUploader";
import AddressField from "../fields/AddressField/AddressField";
import ProposalFieldGroup from "../ProposalFieldGroup/ProposalFieldGroup";
import PodioSimpleMultipleFileUploader from "../../../storage/podio/PodioSimpleMultipleFileUploader";
import CheckboxOption from "../fields/CheckboxField/CheckboxOption";

class RequestDesignForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isSuccess: false,
            error: null,
            uploads: {
                homeBlueprint: {
                    loading: false,
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (this.state.uploads.homeBlueprint.loading) {
                            return "A design cannot be requested while files are being uploaded."
                        }
                        if (value === null && this.state.fields.isLongTermResident.value === "No" && this.state.fields.shortTermResidencyReason === "New Build") {
                            return "A home blueprint must be uploaded for all newly built properties.";
                        }
                        return null;
                    }
                },
                curbsidePhoto: {
                    loading: false,
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (this.state.uploads.curbsidePhoto.loading) {
                            return "A design cannot be requested while files are being uploaded."
                        }
                        if (value === null || value.length < 1) {
                            return "A curbside photo of the property must be uploaded."
                        }
                        return null;
                    }
                },
                utilityBills: {
                    loading: false,
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (this.state.uploads.utilityBills.loading) {
                            return "A design cannot be requested while files are being uploaded."
                        }
                        if ((this.state.fields.isLongTermResident.value === "Yes" || (this.state.fields.isLongTermResident.value === "No" && this.state.conditionals.hasPartialUtilityBill.value)) && (value === null || value.length < 1)) {
                            return "A utility bill must be uploaded."
                        }
                        return null;
                    }
                }
            },
            conditionals: {
                hasCoOwner: {
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (value === null) {
                            return "This field cannot be left blank.";
                        }
                        return null;
                    }
                },
                hasPartialUtilityBill: {
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (this.state.fields.isLongTermResident.value === "No") {
                            if (value === null) {
                                return "This field cannot be left blank.";
                            }
                        }
                        return null;
                    }
                },
                numberOfProposals: {
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (value === null) {
                            return "This field cannot be left blank.";
                        }
                        return null;
                    }
                },
                isAggregateMetering: {
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (value === null) {
                            return "This field cannot be left blank.";
                        }
                        return null;
                    }
                },
                aggregateMeteringUtilityBillAcknowledgment: {
                    value: false,
                    error: null,
                    findError: (value) => {
                        if (this.state.conditionals.isAggregateMetering.value) {
                            if (!value) {
                                return "You must acknowledge this before requesting a design.";
                            }
                        }
                        return null;
                    }
                },
                aggregateMeteringSiteMapAcknowledgment: {
                    value: false,
                    error: null,
                    findError: (value) => {
                        if (this.state.conditionals.isAggregateMetering.value) {
                            if (!value) {
                                return "You must acknowledge this before requesting a design.";
                            }
                        }
                        return null;
                    }
                },
                aggregateMeteringUtilityCompanyAcknowledgment: {
                    value: false,
                    error: null,
                    findError: (value) => {
                        if (this.state.conditionals.isAggregateMetering.value) {
                            if (!value) {
                                return "You must acknowledge this before requesting a design.";
                            }
                        }
                        return null;
                    }
                },
                aggregateMeteringTaxLotAcknowledgment: {
                    value: false,
                    error: null,
                    findError: (value) => {
                        if (this.state.conditionals.isAggregateMetering.value) {
                            if (!value) {
                                return "You must acknowledge this before requesting a design.";
                            }
                        }
                        return null;
                    }
                },
                addressIsDifferent: {
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (value === null) {
                            return "You must select whether the current address is the address of the installation.";
                        }
                        return null;
                    }
                },
            },
            fields: {
                name: {
                    error: null,
                    value: null
                },
                cosignerName: {
                    error: null,
                    value: null,
                    findError: (value) => {
                        if (this.state.conditionals.hasCoOwner.value) {
                            if (value === null || value === "") {
                                return "This field cannot be left blank.";
                            }
                        }
                        return null;
                    }
                },
                cosignerEmail: {
                    error: null,
                    value: null,
                    findError: (value) => {
                        if (this.state.conditionals.hasCoOwner.value) {
                            if (value === null || value === "") {
                                return "This field cannot be left blank.";
                            }
                        }
                        return null;
                    }
                },
                cosignerPhone: {
                    error: null,
                    value: null,
                    findError: (value) => {
                        if (this.state.conditionals.hasCoOwner.value) {
                            if (value === null || value === "") {
                                return "This field cannot be left blank.";
                            }
                        }
                        return null;
                    }
                },
                utilityCompany: {
                    error: null,
                    value: null,
                    findError: (value) => {
                        if (value === null || value === "") {
                            return "This field cannot be left blank.";
                        }
                        return null;
                    }
                },
                installationAddress: {
                    error: null,
                    value: null,
                    findError: (value) => {
                        if (this.state.conditionals.addressIsDifferent.value) {
                            if (value === null || value === "") {
                                return "This field cannot be left blank.";
                            }
                        }
                        return null;
                    }
                },
                roofMaterial: {
                    error: null,
                    value: null,
                    findError: (value) => {
                        if (value === null || value === "") {
                            return "This field cannot be left blank.";
                        }
                        return null;
                    }
                },
                isLongTermResident: {
                    error: null,
                    value: null,
                    findError: (value) => {
                        if (value === null) {
                            return "This field cannot be left blank.";
                        }
                        return null;
                    }
                },
                shortTermResidencyReason: {
                    error: null,
                    value: null,
                    findError: (value) => {
                        if (this.state.fields.isLongTermResident.value === "No") {
                            if (value === null || value === "") {
                                return "This field cannot be left blank.";
                            }
                        }
                        return null;
                    }
                },
                squareFootage: {
                    error: null,
                    value: null,
                    findError: (value) => {
                        if (this.state.fields.isLongTermResident.value === "No") {
                            if (value === null || value === "") {
                                return "This field cannot be left blank.";
                            }
                        }
                        return null;
                    }
                },
                yearBuilt: {
                    error: null,
                    value: null,
                    findError: (value) => {
                        if (this.state.fields.isLongTermResident.value === "No") {
                            if (value === null || value === "") {
                                return "This field cannot be left blank.";
                            }
                        }
                        return null;
                    }
                },
                numberOfStories: {
                    error: null,
                    value: null,
                    findError: (value) => {
                        if (this.state.fields.isLongTermResident.value === "No") {
                            if (value === null || value === "") {
                                return "This field cannot be left blank.";
                            }
                        }
                        return null;
                    }
                },
                numberOfResidents: {
                    error: null,
                    value: null,
                    findError: (value) => {
                        if (this.state.fields.isLongTermResident.value === "No") {
                            if (value === null || value === "") {
                                return "This field cannot be left blank.";
                            }
                        }
                        return null;
                    }
                },
                heatingType: {
                    error: null,
                    value: null,
                    findError: (value) => {
                        if (this.state.fields.isLongTermResident.value === "No") {
                            if (value === null || value === "") {
                                return "This field cannot be left blank.";
                            }
                        }
                        return null;
                    }
                },
                airConditioner: {
                    error: null,
                    value: null,
                    findError: (value) => (null),
                },
                waterHeater: {
                    error: null,
                    value: null,
                    findError: (value) => {
                        if (this.state.fields.isLongTermResident.value === "No") {
                            if (value === null || value === "") {
                                return "This field cannot be left blank.";
                            }
                        }
                        return null;
                    }
                },
                applianceType: {
                    error: null,
                    value: null,
                    findError: (value) => {
                        if (this.state.fields.isLongTermResident.value === "No") {
                            if (value === null || value === "") {
                                return "This field cannot be left blank.";
                            }
                        }
                        return null;
                    }
                },
                consumptionChanges: {
                    error: null,
                    value: null
                },
                utilityMeterNumber: {
                    error: null,
                    value: null,
                    findError: (value) => {
                        if (this.state.fields.isLongTermResident.value === "No" && this.state.fields.shortTermResidencyReason === "Recently Moved") {
                            if (value === null || value === "") {
                                return "This field cannot be left blank.";
                            }
                        }
                        return null;
                    }
                },
                utilityAccountNumber: {
                    error: null,
                    value: null,
                    findError: (value) => {
                        if (this.state.fields.isLongTermResident.value === "No" && this.state.fields.shortTermResidencyReason === "Recently Moved") {
                            if (value === null || value === "") {
                                return "This field cannot be left blank.";
                            }
                        }
                        return null;
                    }
                },
                proposalRequest1: {
                    error: null,
                    value: null,
                    findError: (value) => {
                        if (this.state.conditionals.numberOfProposals >= 1) {
                            if (value === null) {
                                return "This proposal request is incomplete.";
                            }
                        }
                        return null;
                    }
                },
                proposalRequest2: {
                    error: null,
                    value: null,
                    findError: (value) => {
                        if (this.state.numberOfProposals >= 2) {
                            if (value === null) {
                                return "This proposal request is incomplete.";
                            }
                        }
                        return null;
                    }
                },
                proposalRequest3: {
                    error: null,
                    value: null,
                    findError: (value) => {
                        if (this.state.numberOfProposals === 3) {
                            if (value === null) {
                                return "This proposal request is incomplete.";
                            }
                        }
                        return null;
                    }
                },
                turnaroundTime: {
                    error: null,
                    value: null,
                    findError: (value) => {
                        if (value === null) {
                            return "This field cannot be left blank.";
                        }
                        return null;
                    }
                },
                proposalAppointment: {
                    error: null,
                    value: null,
                    findError: (value) => {
                        if (value === null) {
                            return "This field cannot be left blank.";
                        }
                        return null;
                    }
                }
            }
        };
        this.fileUploader = new PodioItemAttachmentUploader(this.props.podio, this.props.lead.id);
        this.simpleUploader = new PodioSimpleMultipleFileUploader(this.props.podio);
    }

    componentDidMount() {
        this.fields = {};
        for (const key in this.labels) {
            this.fields[key] = null;
        }
    }

    updateConditional(key, value) {
        const conditionals = this.state.conditionals;
        conditionals[key].value = value;
        if (conditionals[key].findError !== undefined) {
            conditionals[key].error = conditionals[key].findError(value);
        } else {
            conditionals[key].error = null;
        }
        this.setState({ conditionals });
    }

    updateField(key, value) {
        const fields = this.state.fields;
        fields[key].value = value;
        if (fields[key].findError !== undefined) {
            fields[key].error = fields[key].findError(value);
        } else {
            fields[key].error = null;
        }
        this.setState({ fields });
    }

    submit() {
        this.startLoader();
        const validation = this.validate();
        if (validation.isValid) {
            this.props.branch.requestDesign(this.props.lead, this.getFields())
                .then(() => this.setSuccessful())
                .catch(this.setError.bind(this))
        } else {
            this.setError(validation.error);
        }
    }

    validate() {
        const validation = { isValid: true, error: null };
        const fields = this.state.fields;
        for (const key in fields) {
            if (fields[key].findError !== undefined) {
                fields[key].error = fields[key].findError(fields[key].value);
                if (fields[key].error !== null && validation.isValid) {
                    validation.isValid = false;
                    validation.error = new Error("Please fix the issues on the fields above.");
                }
            }
        }
        const conditionals = this.state.conditionals;
        for (const key in conditionals) {
            if (conditionals[key].findError !== undefined) {
                conditionals[key].error = conditionals[key].findError(conditionals[key].value);
                if (conditionals[key].error !== null && validation.isValid) {
                    validation.isValid = false;
                    validation.error = new Error("Please fix the issues on the fields above.");
                }
            }
        }
        const uploads = this.state.uploads;
        for (const key in uploads) {
            if (uploads[key].findError !== undefined) {
                uploads[key].error = uploads[key].findError(uploads[key].value);
                if (uploads[key].error !== null && validation.isValid) {
                    validation.isValid = false;
                    validation.error = new Error("Please fix the issues on the fields above.");
                }
            }
        }
        this.setState({ fields, conditionals, uploads });
        return validation;
    }

    getFields() {
        const fields = {};
        for (const key in this.state.fields) {
            if (this.state.fields[key].value !== null) {
                fields[key] = this.state.fields[key].value;
            }
        }
        return fields;
    }

    startLoader() {
        this.props.startLoader();
        this.setState({
            isLoading: true,
            isSuccess: false,
            error: null
        });
    }

    setSuccessful() {
        this.setState({
            isLoading: false,
            isSuccess: true,
            error: null
        });
        if (this.props.onSubmitted) {
            this.props.onSubmitted();
        }
    }

    setError(error) {
        this.setState({
            isLoading: false,
            isSuccess: false,
            error: error instanceof InvalidFormDataError ? error.toHtml() : error.message
        });
        this.props.stopLoader();
    }

    isError() {
        return this.state.error !== null;
    }

    clearError() {
        this.setState({
            error: null
        });
    }

    updateProposal(index, proposal) {
        this.updateField(`proposalRequest${index+1}`, proposal);
    }

    startUpload(key) {
        const uploads = this.state.uploads;
        uploads[key].loading = true;
        this.setState({ uploads });
    }

    completeUpload(key, value) {
        const uploads = this.state.uploads;
        uploads[key].loading = false;
        uploads[key].value = value;
        uploads[key].error = null;
        this.setState({ uploads });
    }

    setUploadError(key, error) {
        const uploads = this.state.uploads;
        uploads[key].loading = false;
        uploads[key].error = error;
        this.setState({ uploads });
    }

    updateAddressIsDifferent(value) {
        let conditionals = this.state.conditionals;
        conditionals.addressIsDifferent.value = value;
        conditionals.addressIsDifferent.error = conditionals.addressIsDifferent.findError(value);
        let state = { conditionals };
        if (!value) {
            let fields = this.state.fields;
            fields.installationAddress.value = null;
            fields.installationAddress.error = null;
            state.fields = fields;
        }
        this.setState(state);
    }

    render() {
        const { fields, conditionals, uploads } = this.state;
        return (
            <div className="RequestDesignForm">
                <div className="RequestDesignForm-container">
                    <SingleLineTextField default={this.props.lead.name} label="Customer's Legal Name" placeholder="Enter the customer's name as it appears on title..." error={fields.name.error} onChange={(value) => this.updateField("name", value)}/>
                    <RadioField label="Will there be a co-signer?" error={conditionals.hasCoOwner.error} onChange={(value) => this.updateConditional("hasCoOwner", value)}>
                        <RadioOption value={true}>Yes</RadioOption>
                        <RadioOption value={false}>No</RadioOption>
                    </RadioField>
                    {conditionals.hasCoOwner.value && (
                        <NestedFieldGroup>
                            <SingleLineTextField label="Co-Owner Name" error={fields.cosignerName.error} placeholder="Enter the co-owner's name as it appears on title..." onChange={(value) => this.updateField("cosignerName", value)}/>
                            <SingleLineTextField label="Co-Owner Email (Must be Different from Primary Homeowner)" error={fields.cosignerEmail.error} placeholder="Enter the best email for the co-owner..." onChange={(value) => this.updateField("cosignerEmail", value)}/>
                            <SingleLineTextField label="Co-Owner Phone" error={fields.cosignerPhone.error} placeholder="Enter the best phone number for the co-owner..." onChange={(value) => this.updateField("cosignerPhone", value)}/>
                        </NestedFieldGroup>
                    )}
                    <SelectionField label="Utility Company" placeholder="Select a utility company..." error={fields.utilityCompany.error} onChange={(value) => this.updateField("utilityCompany", value)}>
                        <SelectionOption>
                            Unknown
                        </SelectionOption>
                        <SelectionOption value="Pacific Power - PLP 1:1 Net Metering">
                            Pacific Power
                        </SelectionOption>
                        <SelectionOption value="Douglas Electric Coop - PLP 1:1 Net Metering">
                            Douglas Electric
                        </SelectionOption>
                        <SelectionOption value="Portland General Electric - PLP 1:1 Net Metering">
                            Portland General Electric
                        </SelectionOption>
                        <SelectionOption value="MidAmerican Energy - PLP 1:1 Net Metering">
                            MidAmerican Energy
                        </SelectionOption>
                        <SelectionOption value="Alliant Energy - PLP 1:1 Net Metering">
                            Alliant Energy
                        </SelectionOption>
                        <SelectionOption value="Northwestern Energy - PLP 1:1 Net Metering">
                            Northwestern Energy
                        </SelectionOption>
                        <SelectionOption value="Yellowstone Valley Electric - PLP 1:1 Net Metering">
                            Yellowstone Energy
                        </SelectionOption>
                        <SelectionOption value="Central Electric - PLP Avoided Cost ">
                            Central Electric Cooperative
                        </SelectionOption>
                        <SelectionOption value="Ashland Municipal Electric Utility - PLP 1:1 Net Metering">
                            City of Ashland
                        </SelectionOption>
                        {/* <SelectionOption value="Other">
                            Other
                        </SelectionOption> */}
                    </SelectionField>
                    <RadioField
                        label={<span>Is <em>{this.props.lead.address.value}</em> the correct installation address?</span>}
                        error={this.state.conditionals.addressIsDifferent.error}
                        onChange={(value) => this.updateAddressIsDifferent(value)}
                    >
                        <RadioOption value={false}>Yes</RadioOption>
                        <RadioOption value={true}>No</RadioOption>
                    </RadioField>
                    {this.state.conditionals.addressIsDifferent.value && (
                        <AddressField label="Installation Address" placeholder="Enter the property address where the system will be installed..." error={fields.installationAddress.error} onChange={(value) => this.updateField("installationAddress", value)}/>
                    )}
                    <SelectionField label="Roof Material" placeholder="Select the roof material..." error={fields.roofMaterial.error} onChange={(value) => this.updateField("roofMaterial", value)}>
                        <SelectionOption value={null}>Unkown</SelectionOption>
                        <SelectionOption value="Asphalt Composite Shingle">Asphalt Composite Shingle</SelectionOption>
                        <SelectionOption value="3D Shake">3D Shake</SelectionOption>
                        <SelectionOption value="Corrugated Metal">Corrugated Metal</SelectionOption>
                        <SelectionOption value="Standing Seam Metal">Standing Seam Metal</SelectionOption>
                        <SelectionOption value="Metal Shingles">Metal Shingles</SelectionOption>
                        <SelectionOption value="Flat Concrete Tile">Flat Concrete Tile</SelectionOption>
                        <SelectionOption value="Low Profile Concrete Tile">Low Profile Concrete Tile</SelectionOption>
                        <SelectionOption value="Concrete S Tile">Concrete S Tile</SelectionOption>
                        <SelectionOption value="Wood Shakes">Wood Shakes</SelectionOption>
                        <SelectionOption value="Rolled Roofing">Rolled Roofing</SelectionOption>
                        <SelectionOption value="Mod Bit">Mod Bit</SelectionOption>
                        <SelectionOption value="PVC Membrane">PVC Membrane</SelectionOption>
                        <SelectionOption value="TPO Membrane">TPO Membrane</SelectionOption>
                        <SelectionOption value="EPDM Membrane">EPDM Membrane</SelectionOption>
                        <SelectionOption value="Other">Other</SelectionOption>
                    </SelectionField>
                    <RadioField label="Has the customer lived in the home for more than 9 months?" error={fields.isLongTermResident.error} onChange={(value) => this.updateField("isLongTermResident", value)}>
                        <RadioOption value={"Yes"}>Yes</RadioOption>
                        <RadioOption value={"No"}>No</RadioOption>
                    </RadioField>
                    {fields.isLongTermResident.value !== null && fields.isLongTermResident.value === "No" && (
                        <NestedFieldGroup>
                            <SelectionField label="Select the reason for the homeowner's short term residency." error={fields.shortTermResidencyReason.error} placeholder="Select a reason..." onChange={(value) => this.updateField("shortTermResidencyReason", value)}>
                                <SelectionOption value="New Build">New Build</SelectionOption>
                                <SelectionOption value="Recently Moved">Recently Moved</SelectionOption>
                            </SelectionField>
                            <RadioField label="Can the homeowner provide a partial power bill?" error={conditionals.hasPartialUtilityBill.error} onChange={(value) => this.updateConditional("hasPartialUtilityBill", value)}>
                                <RadioOption value={true}>Yes</RadioOption>
                                <RadioOption value={false}>No</RadioOption>
                            </RadioField>
                        </NestedFieldGroup>
                    )}
                    {fields.isLongTermResident.value !== null && fields.isLongTermResident.value === "No" && fields.shortTermResidencyReason.value !== null && (
                        <NestedFieldGroup>
                            <NumericField label="Square Footage" placeholder="Enter the home's square footage..." error={fields.squareFootage.error} onChange={(value) => this.updateField("squareFootage", value)}/>
                            <NumericField label="Year Built" placeholder="Enter the year that the home was built..." error={fields.yearBuilt.error} onChange={(value) => this.updateField("yearBuilt", value)}/>
                            <NumericField label="Number of Stories" placeholder="Enter the the number of stories in the home..." error={fields.numberOfStories.error} onChange={(value) => this.updateField("numberOfStories", value)}/>
                            <NumericField label="Number of Residents" placeholder="Enter the number of people living in the home..." error={fields.numberOfResidents.error} onChange={(value) => this.updateField("numberOfResidents", value)}/>
                            <SelectionField label="Heating Type" placeholder="Select the type of heating system that the home has..." error={fields.heatingType.error} onChange={(value) => this.updateField("heatingType", value)}>
                                <SelectionOption value="Gas">Gas</SelectionOption>
                                <SelectionOption value="Electric">Electric</SelectionOption>
                            </SelectionField>
                            <SelectionField label="Air Conditioning" placeholder="Select the type of air conditioning that the home has..." error={fields.airConditioner.error} onChange={(value) => this.updateField("airConditioner", value)}>
                                <SelectionOption value={null}>None</SelectionOption>
                                <SelectionOption value="Gas">Gas</SelectionOption>
                                <SelectionOption value="Electric">Electric</SelectionOption>
                            </SelectionField>
                            <SelectionField label="Water Heater" placeholder="Select the type of water heater that the home has..." error={fields.waterHeater.error} onChange={(value) => this.updateField("waterHeater", value)}>
                                <SelectionOption value="Gas">Gas</SelectionOption>
                                <SelectionOption value="Electric">Electric</SelectionOption>
                            </SelectionField>
                            <SelectionField label="Appliance Type" placeholder="Select the type of appliances that the home has..." error={fields.applianceType.error} onChange={(value) => this.updateField("applianceType", value)}>
                                <SelectionOption value="Gas">Gas</SelectionOption>
                                <SelectionOption value="Electric">Electric</SelectionOption>
                                <SelectionOption value="Both">Both</SelectionOption>
                            </SelectionField>
                            <MultiLineTextField label="Do you forsee any changes in the power consumption?" placeholder="Please describe any changes in power consumption that you can forsee..." error={fields.consumptionChanges.error} onChange={(value) => this.updateField("consumptionChanges", value)}/>
                            {fields.shortTermResidencyReason.value === "New Build" ? (
                                <MultipleFileField
                                    label="Upload Home Blueprint"
                                    error={uploads.homeBlueprint.error}
                                    uploader={this.fileUploader}
                                    onStartLoader={() => this.startUpload("homeBlueprint")}
                                    onChange={(value) => this.completeUpload("homeBlueprint", value)}
                                    onError={(error) => this.setUploadError("homeBlueprint", error)}
                                />
                            ) : (fields.shortTermResidencyReason.value === "Recently Moved") && (
                                <>
                                    <SingleLineTextField label="What is the utility meter number for the property?" placeholder="Enter the meter number for the property..." onChange={(value) => this.updateField("utilityMeterNumber", value)}/>
                                    <SingleLineTextField label="What is the homeowner's utility account number?" placeholder="Enter the homeowner's utility account number..." onChange={(value) => this.updateField("utilityAccountNumber", value)}/>
                                </>
                            )}
                        </NestedFieldGroup>
                    )}
                    {(fields.isLongTermResident.value === null || fields.isLongTermResident.value === "Yes" || conditionals.hasPartialUtilityBill.value) && (
                        <MultipleFileField
                            label="Upload Utility Bill(s)"
                            error={uploads.utilityBills.error}
                            uploader={this.fileUploader}
                            onStartLoader={() => this.startUpload("utilityBills")}
                            onChange={(value) => this.completeUpload("utilityBills", value)}
                            onError={(error) => this.setUploadError("utilityBills", error)}
                        />
                    )}
                    <SelectionField label="How many proposals are you requesting?" placeholder="Select number of proposals..." error={conditionals.numberOfProposals.error} onChange={(value) => this.updateConditional("numberOfProposals", value)}>
                        <SelectionOption value={1}>1</SelectionOption>
                        <SelectionOption value={2}>2</SelectionOption>
                        <SelectionOption value={3}>3</SelectionOption>
                    </SelectionField>
                    {conditionals.numberOfProposals.value !== null && [...Array(conditionals.numberOfProposals.value)].map((_, index) => (
                        <NestedFieldGroup key={index} title={`Proposal #${index + 1}`}>
                            <ProposalFieldGroup siteMapUploader={this.simpleUploader} onChange={(proposal) => this.updateProposal(index, proposal)}/>
                        </NestedFieldGroup>
                    ))}
                    <MultipleFileField
                        label="Picture(s) of the Front of the Property"
                        error={uploads.curbsidePhoto.error}
                        uploader={this.fileUploader}
                        onStartLoader={() => this.startUpload("curbsidePhoto")}
                        onChange={(value) => this.completeUpload("curbsidePhoto", value)}
                        onError={(error) => this.setUploadError("curbsidePhoto", error)}
                    />
                    <RadioField label="Is aggregate metering being sold?" error={conditionals.isAggregateMetering.error} onChange={(value) => this.updateConditional("isAggregateMetering", value)}>
                        <RadioOption value={true}>Yes</RadioOption>
                        <RadioOption value={false}>No</RadioOption>
                    </RadioField>
                    {conditionals.isAggregateMetering.value && (
                        <NestedFieldGroup>
                            <CheckboxOption value={true} onSelect={() => this.updateConditional("aggregateMeteringUtilityBillAcknowledgment", true)} selected={this.state.conditionals.aggregateMeteringUtilityBillAcknowledgment.value} onDeselect={() => this.updateConditional("aggregateMeteringUtilityBillAcknowledgment", false)} error={this.state.conditionals.aggregateMeteringUtilityBillAcknowledgment.error !== null}>
                                I acknowledge that I have uploaded a utility bill for each meter being aggregated.
                            </CheckboxOption>
                            <CheckboxOption value={true} onSelect={() => this.updateConditional("aggregateMeteringSiteMapAcknowledgment", true)} selected={this.state.conditionals.aggregateMeteringSiteMapAcknowledgment.value} onDeselect={() => this.updateConditional("aggregateMeteringSiteMapAcknowledgment", false)} error={this.state.conditionals.aggregateMeteringSiteMapAcknowledgment.error !== null}>
                                I acknowledge that I have indicated where each meter is on the annotated satellite image uploaded above.
                            </CheckboxOption>
                            <CheckboxOption value={true} onSelect={() => this.updateConditional("aggregateMeteringUtilityCompanyAcknowledgment", true)} selected={this.state.conditionals.aggregateMeteringUtilityCompanyAcknowledgment.value} onDeselect={() => this.updateConditional("aggregateMeteringUtilityCompanyAcknowledgment", false)} error={this.state.conditionals.aggregateMeteringUtilityCompanyAcknowledgment.error !== null}>
                                I acknowledge that the selected utility company above offers aggregate metering.
                            </CheckboxOption>
                            <CheckboxOption value={true} onSelect={() => this.updateConditional("aggregateMeteringTaxLotAcknowledgment", true)} selected={this.state.conditionals.aggregateMeteringTaxLotAcknowledgment.value} onDeselect={() => this.updateConditional("aggregateMeteringTaxLotAcknowledgment", false)} error={this.state.conditionals.aggregateMeteringTaxLotAcknowledgment.error !== null}>
                                I acknowledge that all meters being aggregated are on the same tax lot.
                            </CheckboxOption>
                        </NestedFieldGroup>
                    )}
                    <SelectionField label="Select a turnaround time for the proposal" placeholder="Select turnaround time..." error={fields.turnaroundTime.error} onChange={(value) => this.updateField("turnaroundTime", value)}>
                        <SelectionOption value={"20 minutes"}>In Home</SelectionOption>
                        <SelectionOption value={"Same Day"}>Same Day</SelectionOption>
                        <SelectionOption value={"Next Day"}>Next Day</SelectionOption>
                        {/* <SelectionOption value={"2 Days"}>2 Days</SelectionOption> */}
                    </SelectionField>
                    <DateTimeField placeholder="Select a date..." error={fields.proposalAppointment.error} onChange={(value) => this.updateField("proposalAppointment", value)} label="Proposal Appointment"/>
                    {!this.state.isLoading && this.isError() ? (
                        <ErrorAlert onClear={this.clearError.bind(this)}>
                            <div dangerouslySetInnerHTML={{__html: this.state.error}}/>
                        </ErrorAlert>
                    ) : (
                        <button className="dark-button large-button full-button submit-button" onClick={this.submit.bind(this)}>
                            <strong>Request</strong>
                        </button>
                    )}
                </div>
            </div>
        );
    }
}

export default RequestDesignForm;
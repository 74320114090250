import { Component } from "react";
import CalendarSelection from "../../forms/fields/DateField/CalendarSelection";
import TimeField from "../../forms/fields/TimeField/TimeField";
import "./ProfileAppointmentSelector.css";


class ProfileAppointmentSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: null,
            tab: 1
        };
    }

    selectDate(date) {
        this.setState({
            date,
            tab: 2
        });
    }

    selectTime(hour, minute) {
        const date = this.state.date;
        if (date) {
            if (hour !== null) {
                date.setHours(hour);
            }
            if (minute !== null) {
                date.setMinutes(minute);
            }
            this.setState({
                date
            });
        }
    }

    getValueAsText() {
        if (this.state.date) {
            return this.state.date.toLocaleString();
        }
        return "--/--/----, --:--:--";
    }

    handleUpdate() {
        if (this.props.onUpdate) {
            this.props.onUpdate(this.state.date);
        }
    }

    cancel() {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    render() {
        return (
            <div className={"ProfileAppointmentSelector" + (this.props.show ? " show" : " hide")}>
                <div className="ProfileAppointmentSelector-footer">
                    <div className="ProfileAppointmentSelector-footer-container">
                        {this.getValueAsText()}
                        <span className="ProfileAppointmentSelector-cancel-btn" onClick={this.cancel.bind(this)}>
                            &times;
                        </span>
                    </div>
                </div>
                <div className={"ProfileAppointmentSelector-tab" + (this.state.tab === 1 ? " show" : " hide")}>
                    <CalendarSelection
                        show
                        value={this.state.date}
                        onChange={this.selectDate.bind(this)}
                    />
                </div>
                <div className={"ProfileAppointmentSelector-tab" + (this.state.tab === 2 ? " show" : " hide")}>
                    <TimeField onChange={this.selectTime.bind(this)}/>
                    <div className="ProfileAppointmentSelector-update-btn" onClick={this.handleUpdate.bind(this)}>
                        <button className="full-button primary-button">Update</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProfileAppointmentSelector;
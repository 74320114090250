class CategoryMap {
    constructor() {
        this.json = null;
    }

    static fromJson(json) {
        if (json.type === "category") {
            const categoryMap = new CategoryMap();
            categoryMap.json = json;
            return categoryMap;
        } else {
            throw new Error(`The ${json.field_id} field is not a category field.`);
        }
    }

    getField() {
        return this.json.field_id;
    }

    getOptionId(text) {
        if (text) {
            for (const option of this.json.config.settings.options) {
                if (option.text === text) {
                    return option.id;
                }
            }
            throw new Error(`There is no option with a value that matches "${text}".`);
        }
        return null;
    }

    getOptionIdFromRegex(regex) {
        for (const option of this.json.config.settings.options) {
            if (regex.test(option.text)) {
                return option.id;
            }
        }
        throw new Error(`There is no option that is similar to "${regex}".`);
    }

    getOptionIdFromSimilar(text) {
        for (const option of this.json.config.settings.options) {
            if (option.toLowerCase().includes(text.toLowerCase())) {
                return option.id;
            }
        }
        throw new Error(`There is no option that is similar to "${text}".`);
    }
}

export default CategoryMap;
import { Component } from "react";
import activeTriangle from "../../assets/active-triangle.svg"
import inactiveTraingle from "../../assets/white-triangle.svg"
import "./SidebarCollapseArrow.css";


class SidebarCollapseArrow extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="SidebarCollapseArrow">
                {this.props.active ? (
                    <img src={activeTriangle} alt=""/>
                ) : (
                    <img src={inactiveTraingle} alt=""/>
                )}
            </div>
        );
    }
}

export default SidebarCollapseArrow;
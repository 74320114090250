import LeadConverter from "../../../converters/LeadConverter";
import OpportunityConverter from "../../../converters/OpportunityConverter";
import ProjectConverter from "../../../converters/ProjectConverter";
import PodioBranch from "../PodioBranch";
import MedfordLeadSchema from "../schema/workspaces/medford/MedfordLeadSchema";
import MedfordOpportunitySchema from "../schema/workspaces/medford/MedfordOpportunitySchema";
import MedfordProjectSchema from "../schema/workspaces/medford/MedfordProjectSchema";


class MedfordBranch extends PodioBranch {
    constructor(podioApi) {
        super(podioApi);
        this.id = 1;
        this.name = "Medford";
        this.leadSchema = new MedfordLeadSchema(podioApi);
        this.leadConverter = new LeadConverter(this.leadSchema);
        this.opportunitySchema = new MedfordOpportunitySchema(podioApi);
        this.opportunityConverter = new OpportunityConverter(this.opportunitySchema);
        this.projectSchema = new MedfordProjectSchema(podioApi);
        this.projectConverter = new ProjectConverter(this.projectSchema);
    }
}

export default MedfordBranch;
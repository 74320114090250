import Week from "./Week";

class Month {
    constructor(year, month) {
        this.weeks = [];
        let week = Week.fromDate(new Date(year, month, 1));
        this.weeks.push(week);
        week = week.getNextWeek();
        while (week.getSunday().getMonth() === month) {
            this.weeks.push(week);
            week = week.getNextWeek();
        }
    }
}

export default Month;
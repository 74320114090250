import { Component } from "react";
import ProfileAppointmentSelector from "../ProfileAppointmentSelector/ProfileAppointmentSelector";
import "./ProfileAdjustableDateHeader.css";


class ProfileAdjustableDateHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSelector: false
        };
    }

    hasSubtitle() {
        return (null !== this.props.subtitle) && (undefined !== this.props.subtitle);
    }

    toggleSelector() {
        this.setState({
            showSelector: !this.state.showSelector
        });
    }

    handleUpdate(date) {
        if (this.props.onChange) {
            this.props.onChange(date);
        }
        this.hideSelector();
    }

    hideSelector() {
        this.setState({
            showSelector: false
        });
    }

    render() {
        return (
            <div className="ProfileAdjustableDateHeader">
                <div className="ProfileAdjustableDateHeader-container">
                    <div className="ProfileAdjustableDateHeader-title">
                        <div className="ProfileAdjustableDateHeader-title-text">
                            {this.props.title}
                        </div>
                        {this.hasSubtitle() && (
                            <div className="ProfileAdjustableDateHeader-subtitle">
                                {this.props.subtitle}
                            </div>
                        )}
                    </div>
                    {this.state.showSelector ? (
                        <ProfileAppointmentSelector
                            onUpdate={this.handleUpdate.bind(this)}
                            onCancel={this.hideSelector.bind(this)}
                        />
                    ) : (
                        <div className="ProfileAdjustableDateHeader-footer" onClick={this.toggleSelector.bind(this)}>
                            <div className="ProfileAdjustableDateHeader-footer-container">
                                {this.props.date.toLocaleString()}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default ProfileAdjustableDateHeader;
import { Component } from "react";
import CollapsibleNotes from "../../components/CollapsibleNotes/CollapsibleNotes";
import AddressField from "../../components/forms/fields/AddressField/AddressField";
import DateTimeField from "../../components/forms/fields/DateTimeField/DateTimeField";
import MultiLineTextField from "../../components/forms/fields/MultiLineTextField/MultiLineTextField";
import SelectionField from "../../components/forms/fields/SelectionField/SelectionField";
import SelectionOption from "../../components/forms/fields/SelectionField/SelectionOption";
import SingleLineTextField from "../../components/forms/fields/SingleLineTextField/SingleLineTextField";
import GoogleCalendarSignInLink from "../../components/GoogleCalendarSignInLink/GoogleCalendarSignInLink";
import ScreenContainer from "../../components/ScreenContainer/ScreenContainer";
import SubmitButton from "../../components/SubmitButton/SubmitButton";
import Lead from "../../Lead";
import GoogleCalendar from "../../storage/GoogleCalendar";
import "./ScheduleConsultationScreen.css";

class ScheduleConsultationScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lead: this.parseLocationRecord(),
            dateTimeValue: null,
            dateTimeError: null,
            addressValue: "",
            addressError: null,
            phoneValue: "",
            phoneError: null,
            emailValue: "",
            emailError: null,
            googleIsAuthenticated: false,
            submissionError: null,
            modalShowing: false,
            stageValue: "",
            stageError: null,
            stageNotesValue: "",
            stageNotesError: null,
        };
        this.calendar = new GoogleCalendar(
            process.env.REACT_APP_GOOGLE_CALENDAR_API_CLIENT_ID,
            process.env.REACT_APP_GOOGLE_API_KEY
        );
    }

    parseLocationRecord(obj) {
        if (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.record
        ) {
            const lead = new Lead();
            Object.assign(lead, this.props.location.state.record);
            return lead;
        }
        return null;
    }

    componentDidMount() {
        this.calendar.load().then(() => {
            this.setState({
                googleIsAuthenticated: this.calendar.isAuthenticated(),
            });
            if (this.props.activeBranch) {
                if (this.state.lead) {
                    this.props.stopLoader();
                } else {
                    return this.fetchLead(this.props.match.params.id);
                }
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.activeBranch &&
            (!prevProps.activeBranch ||
                this.props.activeBranch.id !== prevProps.activeBranch.id)
        ) {
            if (this.state.lead) {
                this.props.stopLoader();
            } else {
                this.fetchLead(this.props.match.params.id);
            }
        }
    }

    fetchLead(id) {
        this.props.activeBranch.fetchLead(id).then((lead) => {
            this.setState({
                lead,
            });
            this.props.stopLoader();
        });
    }

    updateDateTime(value) {
        this.setState({
            dateTimeValue: value,
            dateTimeError: null,
        });
    }

    updatePhone(value) {
        let state = { phoneValue: value, phoneError: null };
        if (value === "" && this.state.lead.phone === null) {
            state.phoneError =
                "The lead must have a phone number before scheduling a consultation.";
        }
        this.setState(state);
    }

    updateEmail(value) {
        let state = { emailValue: value, emailError: null };
        if (value === "" && this.state.lead.email === null) {
            state.emailError =
                "The lead must have an email address before scheduling a consultation.";
        }
        this.setState(state);
    }

    updateAddress(value) {
        let state = { addressValue: value, addressError: null };
        if (value === "" && this.state.lead.address === null) {
            state.addressError =
                "The lead must have an address before scheduling a consultation.";
        }
        this.setState(state);
    }

    handleSubmission() {
        this.props.startLoader();
        this.submit()
            .then(() => this.props.showPage("/deals/leads"))
            .catch((error) => this.handleError(error));
    }

    submit() {
        let validation = this.validate();
        if (validation.isValid) {
            let payload = this.getPayload();
            return this.props.activeBranch
                .scheduleConsultation(this.state.lead, payload)
                .then(this.updateLead.bind(this))
                .then(this.calendar.scheduleConsultation.bind(this.calendar));
        } else {
            return Promise.reject(new Error("There are issues with the form."));
        }
    }

    updateLead() {
        let lead = this.state.lead;
        lead.appointment = this.state.dateTimeValue;
        if (this.state.addressValue) {
            lead.address = this.state.addressValue;
        }
        return lead;
    }

    validate() {
        let validation = { isValid: true, errors: {} };
        if (this.state.dateTimeValue === null) {
            validation.isValid = false;
            validation.errors.dateTimeError =
                "A date and time for the appointment is required.";
        }
        if (this.state.phoneValue === "" && this.state.lead.phone === null) {
            validation.isValid = false;
            validation.errors.phoneError =
                "The lead must have a phone number before scheduling a consultation.";
        }
        if (this.state.emailValue === "" && this.state.lead.email === null) {
            validation.isValid = false;
            validation.errors.emailError =
                "The lead must have an email address before scheduling a consultation.";
        }
        if (
            this.state.addressValue === "" &&
            this.state.lead.address === null
        ) {
            validation.isValid = false;
            validation.errors.addressError =
                "The lead must have an address before scheduling a consultation.";
        }
        if (!validation.isValid) {
            this.setState(validation.errors);
        }
        return validation;
    }

    getPayload() {
        let payload = {
            appointment: this.state.dateTimeValue,
            leadManagers: [this.props.user.profile.profile_id],
        };
        if (this.state.phoneValue) {
            payload.phone = this.state.phoneValue;
        }
        if (this.state.emailValue) {
            payload.email = this.state.emailValue;
        }
        if (this.state.addressValue) {
            payload.address = this.state.addressValue;
        }
        return payload;
    }

    handleError(error) {
        this.setState({
            submissionError: error.message,
        });
        this.props.stopLoader();
    }

    loginToGoogle() {
        this.calendar.authenticate().then(() => {
            this.setState({
                googleIsAuthenticated: true,
            });
        });
    }

    toggleStatusModal() {
        this.setState({
            modalShowing: !this.state.modalShowing,
        });
    }

    updateStage(value) {
        if (value !== "") {
            this.setState({
                stageValue: value,
                stageError: null,
            });
        } else {
            this.setState({
                stageValue: value,
                stageError: "A stage must be selected.",
            });
        }
    }

    handleModalClick(event) {
        if (event.target.classList.contains("ChangeStatusModal-content-wrapper")) {
            this.setState({
                modalShowing: false,
            });
        }
    }

    updateStatusNotes(value) {
        let state = { stageNotesValue: value, stageNotesError: null };
        if (value === "" && this.state.stageValue !== "") {
            state.stageNotesError = "You must explain why you are changing the stage.";
        }
        this.setState(state);
    }

    handleStatusChangeSubmission() {
        this.props.startLoader();
        let validation = this.validateStageChange();
        if (validation.isValid) {
            this.changeStage()
                .then(() => this.props.showPage("/deals/leads"))
                .catch((error) => console.log(error));
        } else {
            this.setState(validation.errors);
            this.props.stopLoader();
        }
    }

    validateStageChange() {
        let validation = { isValid: true, errors: {} };
        if (this.state.stageValue === "") {
            validation.isValid = false;
            validation.errors.stageError = "You must select a stage.";
        }
        if (this.state.stageNotesValue === "") {
            validation.isValid = false;
            validation.errors.stageNotesError = "You must explain why you are changing the stage.";
        }
        return validation;
    }

    changeStage() {
        return this.props.activeBranch.updateLeadStage(this.state.lead, {
            stage: this.state.stageValue,
            stageNotes: this.state.stageNotesValue
        });
    }

    render() {
        return (
            <ScreenContainer
                showPage={this.props.showPage}
                platform={this.props.platform}
            >
                <div className="ScheduleConsultationScreen-upper-content">
                    <div className="ScheduleConsultationScreen-header">
                        <div className="ScheduleConsultationScreen-header-container">
                            <div className="ScheduleConsultationScreen-header-lead-name">
                                {this.state.lead.name}
                            </div>
                            <div className="ScheduleConsultationScreen-header-status-btn-container">
                                <div
                                    className="ScheduleConsultationScreen-header-status-btn"
                                    onClick={this.toggleStatusModal.bind(this)}
                                >
                                    {this.state.modalShowing
                                        ? "..."
                                        : this.state.lead.status}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ScheduleConsultationScreen-lead-data">
                        {(this.state.lead.phone || this.state.lead.email) && (
                            <div className="ScheduleConsultationScreen-lead-data-row">
                                <div className="ScheduleConsultationScreen-lead-data-row-container">
                                    {this.state.lead.phone && (
                                        <div className="ScheduleConsultationScreen-lead-data-field">
                                            <div className="ScheduledConsultationScreen-lead-data-field-label">
                                                Phone
                                            </div>
                                            <div className="ScheduleConsultationScreen-lead-data-field-value">
                                                <a
                                                    href={`tel://${this.state.lead.phone}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className=""
                                                >
                                                    {this.state.lead.phone}
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                    {this.state.lead.email && (
                                        <div className="ScheduleConsultationScreen-lead-data-field">
                                            <div className="ScheduledConsultationScreen-lead-data-field-label">
                                                Email
                                            </div>
                                            <div className="ScheduleConsultationScreen-lead-data-field-value">
                                                {this.state.lead.email}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {this.state.lead.address && (
                            <div className="ScheduleConsultationScreen-lead-data-row">
                                <div className="ScheduleConsultationScreen-lead-data-row-container">
                                    <div className="ScheduleConsultationScreen-lead-data-field fullspan">
                                        <div className="ScheduledConsultationScreen-lead-data-field-label">
                                            Address
                                        </div>
                                        <div className="ScheduleConsultationScreen-lead-data-field-value">
                                            {this.state.lead.address.value}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <CollapsibleNotes
                        show={this.state.lead.leadManagerNotes !== null}
                        title="lead notes"
                        notes={this.state.lead.leadManagerNotes}
                    />
                </div>
                <div className="ScheduleConsultationScreen-description">
                    *Use the fields below to schedule a consultation for{" "}
                    <strong>{this.state.lead.name}</strong>.
                </div>
                <div className="ScheduleConsultationScreen-form">
                    <DateTimeField
                        label="Date and Time"
                        placeholder="Select a date..."
                        error={this.state.dateTimeError}
                        onChange={this.updateDateTime.bind(this)}
                    />
                    {!this.state.lead.phone && (
                        <SingleLineTextField
                            label="Phone Number"
                            placeholder="Enter the lead's phone number..."
                            error={this.state.phoneError}
                            onChange={this.updatePhone.bind(this)}
                        />
                    )}
                    {!this.state.lead.email && (
                        <SingleLineTextField
                            label="Email Address"
                            placeholder="Enter the lead's email address..."
                            error={this.state.emailError}
                            onChange={this.updateEmail.bind(this)}
                        />
                    )}
                    {!this.state.lead.address && (
                        <AddressField
                            label="Address"
                            placeholder="Enter the lead's address"
                            error={this.state.addressError}
                            onChange={this.updateAddress.bind(this)}
                        />
                    )}
                    <GoogleCalendarSignInLink
                        show={!this.state.googleIsAuthenticated}
                        onClick={this.loginToGoogle.bind(this)}
                    />
                    {this.state.googleIsAuthenticated && (
                        <SubmitButton
                            text="Schedule"
                            onClick={this.handleSubmission.bind(this)}
                        />
                    )}
                </div>
                <div
                    className={
                        "ChangeStatusModal" +
                        (this.state.modalShowing ? " show" : " hide")
                    }
                    onClick={this.handleModalClick.bind(this)}
                >
                    <div className="ChangeStatusModal-container">
                        <div className="ChangeStatusModal-content">
                            <div className="ChangeStatusModal-content-wrapper">
                                <div className="ChangeStatusModal-content-container">
                                    <div className="ChangeStatusModal-title">
                                        Change Status
                                    </div>
                                    <div className="ChangeStatusModal-description">
                                        *Use the fields below to change the
                                        stage for {this.state.lead.name}.
                                    </div>
                                    <div className="ChangeStatusModal-fields">
                                        <SelectionField
                                            label="Status"
                                            onChange={this.updateStage.bind(
                                                this
                                            )}
                                            error={this.state.stageError}
                                            placeholder="Select an option..."
                                        >
                                            <SelectionOption value="Unqualified">
                                                Unqualified
                                            </SelectionOption>
                                            <SelectionOption value="Lost Deal">
                                                Lost Deal
                                            </SelectionOption>
                                        </SelectionField>
                                        <MultiLineTextField
                                            label="Notes"
                                            placeholder="Enter your notes about the status change..."
                                            error={this.state.stageNotesError}
                                            onChange={this.updateStatusNotes.bind(
                                                this
                                            )}
                                        />
                                        <SubmitButton
                                            text="Update"
                                            onClick={this.handleStatusChangeSubmission.bind(
                                                this
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ScreenContainer>
        );
    }
}

export default ScheduleConsultationScreen;

import OpportunitySchema from "../../OpportunitySchema";


class MedfordOpportunitySchema extends OpportunitySchema {
    constructor(podioApi) {
        super(podioApi, 23987242);
        this.addField("name", 201571613, "first-name");
        this.addField("email", 201571615, "email");
        this.addField("phone", 201571614, "phone");
        this.addField("cosigner", 202229595, "co-name");
        this.addField("cosignerEmail", 202231759, "co-email");
        this.addField("cosignerPhone", 202229596, "co-phone");
        this.addField("address", 201571616, "location-2");
        this.addField("status", 201571617, "lead-status");
        this.addField("stage", 201571618, "status");
        this.addField("appointment", 201571621, "2nd-touch-appt");
        this.addField("auroraId", 240182815, "aurora-project-id");
        this.addField("specialRequests", 240182816, "special-requests");
        this.addField("nickname", 240182817, "nickname");
        this.addField("leadManager", 201571634, "lead-owner");
        this.addField("designStatus", 201572148, "request-design");
        this.addField("consultants", 201571635, "salesperson-2");
        this.addField("hoaName", 240182818, "hoa-name");
        this.addField("hoaEmail", 240182819, "hoa-email");
        this.addField("hoaPhone", 240182820, "hoa-phone");
        this.addField("propertyAccessInstructions", 240182821, "property-access-instructions");
        this.addField("numberOfTreeRemovals", 240182822, "number-of-tree-removals");
        this.addField("partyResponsibleForTreeRemoval", 240182823, "party-responsible-for-tree-removal");
        this.addField("customerWantsRoofBid", 240182824, "customer-wants-roof-bid");
        this.addField("customerWantsMorePanels", 240182825, "customer-wants-more-panels");
        this.addField("numberOfAdditionalPanels", 240182826, "number-of-additional-panels");
        this.addField("referredBy", 202246623, "referred-by");
        this.addField("customerPriority", 240182827, "customer-priority");
        this.addField("customerPriorityExplanation", 240182828, "customer-priority-explanation");
        this.addField("numberOfStories", 240180806, "number-of-stories");
        this.addField("roofAccess", 240182829, "can-access-roof-from-ground-level");
        this.addField("submitDeal", 222222445, "submit-deal-2");
        this.addField("stageNotes", 240180821, "stage-notes");
        this.addField("designRevisionNotes", 240182830, "design-revision-notes");
        this.addField("type", 245075879, "type");
        this.addField("notes", 202229598, "consultant-notes");
    }
}

export default MedfordOpportunitySchema;
import { Component } from "react";
import PropTypes from "prop-types";
import "./RadioOption.css";

class RadioOption extends Component {
    constructor(props) {
        super(props);
    }

    handleClick() {
        if (this.props.onSelect) {
            this.props.onSelect(this.props.value);
        }
    }

    render() {
        return (
            <div className={"RadioOption" + (this.props.selected ? " selected" : "")}>
                <div className="RadioOption-container" onClick={this.handleClick.bind(this)}>
                    <div className="RadioOption-circle"></div>
                    <div className="RadioOption-label">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

RadioOption.defaultProps = {
    value: null,
    selected: false
};

RadioOption.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
    ]),
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
    children: PropTypes.string.isRequired
};

export default RadioOption;
import { Component } from "react";
import FieldLabel from "../FieldLabel";
import FormField from "../FormField";
import MultipleFileUploader from "../MultipleFileUploader";


class MultipleFileField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUploading: false,
            isRemoving: false
        };
    }

    handleStartUpload() {
        this.setState({
            isUploading: true
        });
        if (this.props.onStartLoader && !this.state.isUploading && !this.state.isRemoving) {
            this.props.onStartLoader();
        }
    }

    handleCompleteUpload(files) {
        this.setState({
            isUploading: false
        });
        if (this.props.onChange && !this.state.isRemoving) {
            this.props.onChange(files);
        }
    }

    handleStartRemove() {
        this.setState({
            isRemoving: true
        });
        if (this.props.onStartLoader && !this.state.isUploading && !this.state.isRemoving) {
            this.props.onStartLoader();
        }
    }
    
    handleCompleteRemove(files) {
        this.setState({
            isRemoving: false
        });
        if (this.props.onChange && !this.state.isUploading) {
            this.props.onChange(files);
        }
    }

    render() {
        return (
            <FormField error={this.props.error}>
                <FieldLabel text={this.props.label}/>
                <MultipleFileUploader
                    uploader={this.props.uploader}
                    onStartUpload={this.handleStartUpload.bind(this)}
                    onCompleteUpload={this.handleCompleteUpload.bind(this)}
                    onStartRemove={this.handleStartRemove.bind(this)}
                    onCompleteRemove={this.handleCompleteRemove.bind(this)}
                    onError={this.props.onError}
                />
            </FormField>
        );
    }
}

export default MultipleFileField;
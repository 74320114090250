import { Component } from "react";
import "./TimeField.css";


class TimeField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hours: null,
            hourIsValid: true,
            minutes: null,
            minuteIsValid: true,
            period: "AM",
            periodIsOpen: false
        };
    }

    change(hour, minute, period) {
        if (this.props.onChange) {
            const militaryHour = this.getMilitaryHour(hour, period);
            if (militaryHour >= 0 && militaryHour <= 23) {
                if (minute >= 0 && minute <= 59) {
                    this.props.onChange(militaryHour, minute);
                } else {
                    this.setState({
                        minuteIsValid: false
                    });
                }
            } else {
                this.setState({
                    hourIsValid: false
                });
            }
        }
    }

    getMilitaryHour(hours, period) {
        if (period === "PM" && hours !== 12) {
            return hours + 12;
        } else if (period === "AM" && hours === 12) {
            return 0;
        }
        return hours;
    }

    handleHoursChange(event) {
        const value = parseInt(event.target.value);
        if (this.isValidHour(value)) {
            this.setState({
                hours: value,
                hourIsValid: true
            });
            this.change(value, this.state.minutes, this.state.period);
        }
    }

    isValidHour(value) {
        return !isNaN(value) && value !== null && value >= 0 && value <= 12;
    }

    handleMinutesChange(event) {
        const value = parseInt(event.target.value);
        if (this.isValidMinute(value)) {
            this.setState({
                minutes: value,
                minuteIsValid: true
            });
            this.change(this.state.hours, value, this.state.period);
        }
    }

    isValidMinute(value) {
        return !isNaN(value) && value !== null && value >= 0 && value <= 59;
    }

    setPeriod(period) {
        this.setState({
            period,
            periodIsOpen: false
        });
        this.change(this.state.hours, this.state.minutes, period);
    }

    showPeriodOptions() {
        this.setState({
            periodIsOpen: true
        });
    }

    getHoursAsText() {
        if (this.state.hours === null || isNaN(this.state.hours)) {
            return "";
        } else {
            if (this.state.hours < 10) {
                return "0" + this.state.hours.toString();
            }
            return this.state.hours.toString();
        }
    }

    getMinutesAsText() {
        if (this.state.minutes === null || isNaN(this.state.minutes)) {
            return "";
        } else {
            if (this.state.minutes < 10) {
                return "0" + this.state.minutes.toString();
            }
            return this.state.minutes.toString();
        }
    }

    render() {
        return (
            <div className="TimeField">
                <div className={"TimeField-hour" + (this.state.hourIsValid ? "" : " invalid")}>
                    <input type="text" value={this.getHoursAsText()} onChange={this.handleHoursChange.bind(this)} placeholder="12" inputMode="decimal" />
                </div>
                <div className={"TimeField-minute" + (this.state.minuteIsValid ? "" : " invalid")}>
                    <input type="text" value={this.getMinutesAsText()} onChange={this.handleMinutesChange.bind(this)} placeholder="00" inputMode="decimal" />
                </div>
                <div className={"TimeField-period" + (this.state.periodIsOpen ? " open" : "")}>
                    <div className="TimeField-period-value" onClick={this.showPeriodOptions.bind(this)}>
                        {this.state.period}
                    </div>
                    <div className="TimeField-period-options">
                        <div className="TimeField-period-option" onClick={() => this.setPeriod("AM")}>AM</div>
                        <div className="TimeField-period-option" onClick={() => this.setPeriod("PM")}>PM</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TimeField;
import LeadConverter from "../../../converters/LeadConverter";
import OpportunityConverter from "../../../converters/OpportunityConverter";
import ProjectConverter from "../../../converters/ProjectConverter";
import PodioBranch from "../PodioBranch";
import BendLeadSchema from "../schema/workspaces/bend/BendLeadSchema";
import BendOpportunitySchema from "../schema/workspaces/bend/BendOpportunitySchema";
import BendProjectSchema from "../schema/workspaces/bend/BendProjectSchema";


class BendBranch extends PodioBranch {
    constructor(podioApi) {
        super(podioApi);
        this.id = 2;
        this.name = "Bend";
        this.leadSchema = new BendLeadSchema(podioApi);
        this.leadConverter = new LeadConverter(this.leadSchema);
        this.opportunitySchema = new BendOpportunitySchema(podioApi);
        this.opportunityConverter = new OpportunityConverter(this.opportunitySchema);
        this.projectSchema = new BendProjectSchema(podioApi);
        this.projectConverter = new ProjectConverter(this.projectSchema);
    }
}

export default BendBranch;
import { Component } from "react";
import PropTypes from "prop-types";
import "./ProfileButton.css";

class ProfileButton extends Component {
    constructor(props) {
        super(props);
    }

    handleClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {
        return (
            <div className="ProfileButton">
                <div className="ProfileButton-container">
                    <button className={`full-button ${this.props.type}-button`} onClick={this.handleClick.bind(this)}>
                        {this.props.children}
                    </button>
                </div>
            </div>
        );
    }
}

ProfileButton.defaultProps = {
    type: "primary"
};

ProfileButton.propTypes = {
    type: PropTypes.string,
    onClick: PropTypes.func
};

export default ProfileButton;
import { Component } from "react";
import Profile from "../../components/profile/Profile/Profile";
import "./OpportunityScreen.css";
import ProfileMetadata from "../../components/profile/ProfileMetadata/ProfileMetadata";
import ProfileModule from "../../components/profile/ProfileModule/ProfileModule";
import ProfileField from "../../components/profile/ProfileField/ProfileField";
import ProfileForm from "../../components/profile/ProfileForm/ProfileForm";
import ScreenContainer from "../../components/ScreenContainer/ScreenContainer";
import SubmitProjectForm from "../../components/forms/SubmitProjectForm/SubmitProjectForm";
import ProfileStatusSelector from "../../components/profile/ProfileStatusSelector/ProfileStatusSelector";
import ProfileStatusSelectionOption from "../../components/profile/ProfileStatusSelectionOption/ProfileStatusSelectionOption";
import ProfileLink from "../../components/profile/ProfileLink/ProfileLink";
import whiteLinkIcon from "../../assets/white-link.svg";
import ProfileButton from "../../components/profile/ProfileButton/ProfileButton";
import primaryLinkIcon from "../../assets/primary-link.svg";
import ProfileAdjustableDateHeader from "../../components/profile/ProfileAdjustableDateHeader/ProfileAdjustableDateHeader";

class OpportunityScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opportunity: null
        };
    }

    componentDidMount() {
        if (this.props.activeBranch) {
            this.fetch(this.props.match.params.id);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.activeBranch && (!prevProps.activeBranch || this.props.activeBranch.id !== prevProps.activeBranch.id)) {
            this.fetch(this.props.match.params.id);
        }
    }

    isLoading() {
        return this.state.opportunity === null;
    }

    changeStatus(value, notes) {
        this.props.startLoader();
        this.props.activeBranch.updateOpportunityStage(this.state.opportunity, {
            stage: value,
            stageNotes: notes
        }).then(() => {
            this.props.showPage("/deals/opportunities");
        }).catch((error) => {
            console.log(error);
            this.props.stopLoader();
        });
    }

    fetch(id) {
        this.props.activeBranch.fetchOpportunity(id)
            .then((opportunity) => {
                this.setState({
                    opportunity
                });
            })
            .then(() => this.props.stopLoader());
    }

    goToQueue() {
        this.props.showPage("/deals/opportunities");
    }

    updateAppointment(date) {
        this.props.startLoader();
        this.props.activeBranch.updateOpportunityField(this.state.opportunity, "appointment", date)
            .then(() => {
                const opportunity = this.state.opportunity;
                opportunity.appointment = date;
                this.setState({
                    opportunity
                });
            }).catch().then(() => {
                this.props.stopLoader();
            });
    }

    render() {
        const { opportunity } = this.state;
        if (opportunity) {
            return (
                <ScreenContainer
                    showPage={this.props.showPage}
                    platform={this.props.platform}
                >
                    {!this.isLoading() && (
                        <Profile>
                            <ProfileMetadata title="Details">
                                <ProfileAdjustableDateHeader
                                    title={opportunity.getFullName()}
                                    date={opportunity.appointment}
                                    onChange={this.updateAppointment.bind(this)}
                                />
                                {opportunity.externalProposalId && (
                                    <ProfileLink type="green" href={`https://v2.aurorasolar.com/projects/${opportunity.externalProposalId}/overview/dashboard`}>
                                        Aurora <img src={whiteLinkIcon} width={12} height={12}/>
                                    </ProfileLink>
                                )}
                                <ProfileButton type="blue" onClick={() => this.props.showPage(`/deals/opportunities/${opportunity.id}/revision`)}>
                                    Revision
                                </ProfileButton>
                                <ProfileModule>
                                    <ProfileField label="Email">{opportunity.email}</ProfileField>
                                    <ProfileField label="Phone">{opportunity.phone}</ProfileField>
                                    <ProfileField label="Address">
                                        <a className="primary-link" href={opportunity.getGoogleMapsLink()} target="_blank">
                                            <span>{opportunity.address.street_address ? opportunity.address.street_address : opportunity.address.value} </span>
                                            <img src={primaryLinkIcon} width={12} height="auto"/>
                                        </a>
                                    </ProfileField>
                                    {opportunity.leadManagers.length > 1 ? (
                                        <ProfileField label="Lead Managers">
                                            <ul>
                                                {opportunity.leadManagers.map((leadManager) => (
                                                    <li>{leadManager.name}</li>
                                                ))}
                                            </ul>
                                        </ProfileField>
                                    ) : opportunity.leadManagers.length > 0 && (
                                        <ProfileField label="Lead Manager">
                                            {opportunity.leadManagers[0].name}
                                        </ProfileField>
                                    )}
                                </ProfileModule>
                                <ProfileStatusSelector label="Status" onSubmit={this.changeStatus.bind(this)} submission="Update">
                                    <ProfileStatusSelectionOption value={null}>N/A</ProfileStatusSelectionOption>
                                    <ProfileStatusSelectionOption value="2T Still Working" notesRequired>Still Working</ProfileStatusSelectionOption>
                                    <ProfileStatusSelectionOption value="Unqualified" notesRequired>Unqualified</ProfileStatusSelectionOption>
                                    <ProfileStatusSelectionOption value="Lost Deal" notesRequired>Closed (Lost)</ProfileStatusSelectionOption>
                                </ProfileStatusSelector>
                            </ProfileMetadata>
                            <ProfileForm>
                                <SubmitProjectForm
                                    opportunity={opportunity}
                                    podio={this.props.podio}
                                    branch={this.props.activeBranch}
                                    startLoader={this.props.startLoader}
                                    stopLoader={this.props.stopLoader}
                                    onSubmitted={this.goToQueue.bind(this)}
                                />
                            </ProfileForm>
                        </Profile>
                    )}
                </ScreenContainer>
            );
        }
        return null;
    }
}

export default OpportunityScreen;
import LeadSchema from "../../LeadSchema";


class BendLeadSchema extends LeadSchema {
    constructor(podioApi) {
        super(podioApi, 24389676);
        this.addField("name", 205590185, "first-name");
        this.addField("email", 205590187, "email");
        this.addField("phone", 205590186, "phone");
        this.addField("cosignerName", 205590188, "co-name");
        this.addField("cosignerEmail", 205590190, "co-email");
        this.addField("cosignerPhone", 205590189, "co-phone");
        this.addField("address", 205590191, "location-2");
        this.addField("status", 205590192, "lead-status");
        this.addField("stage", 205590195, "status");
        this.addField("appointment", 205590196, "appointment-date");
        this.addField("leadManager", 205590213, "lead-owner");
        this.addField("utilityCompany", 240633556, "utility-company");
        this.addField("installationAddress", 240634104, "installation-address");
        this.addField("mountingType", 205590201, "system-type");
        this.addField("roofMaterial", 240634105, "roof-material");
        this.addField("isLongTermResident", 240634106, "is-long-term-resident");
        this.addField("shortTermResidencyReason", 240634107, "short-term-residency-reason");
        this.addField("squareFootage", 240634108, "square-footage");
        this.addField("yearBuilt", 240634109, "year-built");
        this.addField("numberOfStories", 240634110, "number-of-stories");
        this.addField("numberOfResidents", 240634111, "number-of-residents");
        this.addField("heatingType", 240634112, "heating-type");
        this.addField("airConditioner", 240634113, "air-conditioner");
        this.addField("waterHeater", 240634114, "water-heater");
        this.addField("applianceType", 240634115, "appliance-type");
        this.addField("consumptionChanges", 240634116, "consumption-changes");
        this.addField("utilityMeterNumber", 240634117, "utility-meter-number");
        this.addField("utilityAccountNumber", 240634118, "utility-account-number");
        this.addField("proposalRequest1", 240634119, "proposal-request-1");
        this.addField("proposalRequest2", 240634120, "proposal-request-2");
        this.addField("proposalRequest3", 240634121, "proposal-request-3");
        this.addField("targetOffset", 236932817, "system-offset");
        this.addField("adders", 240634122, "adders-notes");
        this.addField("turnaroundTime", 240634123, "design-turnaround-time");
        this.addField("designStatus", 205590198, "request-design");
        this.addField("consultants", 205590214, "salesperson-2");
        this.addField("leadManagers", 205590213, "lead-owner");
        this.addField("proposalAppointment", 205590197, "2nd-touch-appt");
        this.addField("stageNotes", 240634124, "stage-notes");
        this.addField("leadManagerNotes", 205590193, "lead-notes");
        this.addField("channel", 205590212, "lead-source-do-not-change");
        this.addField("source", 205590217, "lead-source-category");
        this.addField("referredBy", 205590210, "referred-by");
        this.addField("type", 245082633, "type");
        this.addField("unqualifiedReason", 252160285, "unqualified-reason");
    }
}

export default BendLeadSchema;
import LeadSchema from "../../LeadSchema";


class DesMoinesLeadSchema extends LeadSchema {
    constructor(podioApi) {
        super(podioApi, 25150942);
        this.addField("name", 213262894, "first-name");
        this.addField("email", 213262896, "email");
        this.addField("phone", 213262895, "phone");
        this.addField("cosignerName", 213262897, "co-name");
        this.addField("cosignerEmail", 213262899, "co-email");
        this.addField("cosignerPhone", 213262898, "co-phone");
        this.addField("address", 213262900, "location-2");
        this.addField("status", 213262901, "lead-status");
        this.addField("stage", 213262904, "status");
        this.addField("appointment", 213262905, "appointment-date");
        this.addField("leadManager", 213262924, "lead-owner");
        this.addField("utilityCompany", 240648143, "utility-company");
        this.addField("installationAddress", 240648145, "installation-address");
        this.addField("roofMaterial", 240648144, "roof-material");
        this.addField("isLongTermResident", 240648146, "is-long-term-resident");
        this.addField("shortTermResidencyReason", 240648147, "short-term-residency-reason");
        this.addField("squareFootage", 240648148, "square-footage");
        this.addField("yearBuilt", 240648149, "year-built");
        this.addField("numberOfStories", 240648150, "number-of-stories");
        this.addField("numberOfResidents", 240648151, "number-of-residents");
        this.addField("heatingType", 240648152, "heating-type");
        this.addField("airConditioner", 240648153, "air-conditioner");
        this.addField("waterHeater", 240648154, "water-heater");
        this.addField("applianceType", 240648155, "appliance-type");
        this.addField("consumptionChanges", 240648156, "consumption-changes");
        this.addField("utilityMeterNumber", 240648157, "utility-meter-number");
        this.addField("utilityAccountNumber", 240648158, "utility-account-number");
        this.addField("proposalRequest1", 240648159, "proposal-request-1");
        this.addField("proposalRequest2", 240648160, "proposal-request-2");
        this.addField("proposalRequest3", 240648161, "proposal-request-3");
        this.addField("targetOffset", 236932921, "system-offset");
        this.addField("adders", 240648162, "adders-notes");
        this.addField("turnaroundTime", 240648163, "design-turnaround-time");
        this.addField("designStatus", 213262907, "request-design");
        this.addField("consultants", 213262925, "salesperson-2");
        this.addField("leadManagers", 213262924, "lead-owner");
        this.addField("proposalAppointment", 213262906, "2nd-touch-appt");
        this.addField("stageNotes", 240648164, "stage-notes");
        this.addField("leadManagerNotes", 213262902, "lead-notes");
        this.addField("channel", 213262923, "lead-source-do-not-change");
        this.addField("source", 213262928, "lead-source-category");
        this.addField("referredBy", 213262920, "referred-by");
        this.addField("type", 245082742, "type");
        this.addField("unqualifiedReason", 252161028, "unqualified-reason");
    }
}

export default DesMoinesLeadSchema;
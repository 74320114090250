import React, { Component } from "react";
import FormField from "../FormField";
import FieldLabel from "../FieldLabel";
import "./RadioField.css";

class RadioField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: null
        };
    }

    isSelected() {
        return null !== this.state.selectedIndex;
    }

    select(index, value) {
        this.setState({
            selectedIndex: index
        });
        if (this.props.onChange) {
            this.props.onChange(value)
        }
    }

    render() {
        return (
            <FormField error={this.props.error}>
                <FieldLabel text={this.props.label}/>
                <div className={"RadioField" + (this.isSelected() ? " selected" : "")}>
                    <div className="RadioField-container">
                        <div className="RadioField-options">
                            {React.Children.map(this.props.children, (child, index) => {
                                if (React.isValidElement(child)) {
                                    return React.cloneElement(child, {
                                        onSelect: (value) => this.select(index, value),
                                        selected: this.state.selectedIndex === index
                                    });
                                }
                            })}
                        </div>
                    </div>
                </div>
            </FormField>
        );
    }
}

export default RadioField;
import { v4 as uuid } from "uuid";


class UploadableFile {
    constructor() {
        this.id = uuid();
        this.externalId = null;
        this.url = null;
        this.name = null;
        this.type = null;
        this.blob = null;
        this.isLoading = true;
    }

    static fromFile(file, name = null) {
        const uploadableFile = new UploadableFile();
        uploadableFile.name = name ? name : file.name;
        uploadableFile.type = file.type;
        uploadableFile.blob = file;
        return uploadableFile;
    }

    static fromWebObject(obj) {
        const uploadableFile = new UploadableFile();
        uploadableFile.name = obj.name;
        uploadableFile.type = obj.type;
        uploadableFile.blob = obj;
        return uploadableFile;
    }

    equalsWebObject(obj) {
        return this.blob.name === obj.name && 
            this.blob.type === obj.type && 
            this.blob.size === obj.size && 
            this.blob.lastModified === obj.lastModified
        ;
    }
}

export default UploadableFile;
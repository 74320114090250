import { Component } from "react";
import MultiLineTextField from "../../forms/fields/MultiLineTextField/MultiLineTextField";
import SelectionField from "../../forms/fields/SelectionField/SelectionField";
import SubmitButton from "../../SubmitButton/SubmitButton";
import SelectionOption from "../../forms/fields/SelectionField/SelectionOption";
import "../ProfileStatusSelector/ProfileStatusSelector.css";

class ProfileStatusSelectorForLeadScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selection: null,
            selectionError: null,
            substatus: null,
            substatusError: null,
            notes: null,
            notesError: null
        };
    }

    handleSelection(value) {
        this.setState({
            selection: value,
            selectionError: null,
            substatusError: null,
            notesError: null
        });
    }

    handleNotesChange(value) {
        this.setState({
            notes: value
        });
    }

    handleSubstatusSelection(value) {
        this.setState({
            substatus: value,
            substatusError: null
        });
    }

    submit() {
        if (this.state.selection === null) {
            this.setState({
                selectionError: "Select a valid option",
                notesError: null
            });
        } else if (this.state.notes === null && this.isSelected("Lost Deal")) {
            this.setState({
                selectionError: null,
                notesError: "Enter notes about this change in status."
            });
        } else if (this.state.substatus === null && this.isSelected("Unqualified")) {
            this.setState({
                selectionError: null,
                notesError: null,
                substatusError: "Please select a substatus"
            });
        } else if (this.props.onSubmit) {
            this.props.onSubmit(this.state.selection, this.state.notes, this.state.substatus);
        }
    }

    isSelected(value) {
        return this.state.selection === value;
    }

    render() {
        return (
            <div className="ProfileStatusSelector">
                <div className="ProfileStatusSelector-container">
                    <SelectionField
                        label={this.props.label}
                        onChange={this.handleSelection.bind(this)}
                        placeholder="Select a Status"
                        error={this.state.selectionError}
                    >
                        <SelectionOption value={null}>N/A</SelectionOption>
                        <SelectionOption value="1T No Show">No Show</SelectionOption>
                        <SelectionOption value="Unqualified">Unqualified</SelectionOption>
                        <SelectionOption value="Lost Deal">Closed (Lost)</SelectionOption>
                    </SelectionField>
                    <div style={{display: (this.isSelected("Unqualified") ? " block" : " none") }}>
                        <SelectionField label="Unqualified Reason" placeholder="Select a reason..." onChange={this.handleSubstatusSelection.bind(this)} error={this.state.substatusError}>
                            <SelectionOption value="Not Home Owner">Not Home Owner</SelectionOption>
                            <SelectionOption value="Failed Credit">Failed Credit</SelectionOption>
                            <SelectionOption value="No Tax Liability">No Tax Liability</SelectionOption>
                            <SelectionOption value="No Existing Building">No Existing Building</SelectionOption>
                            <SelectionOption value="No Roof Space">No Roof Space</SelectionOption>
                            <SelectionOption value="Manufactured Home with No Permanent Foundation">Manufactured Home with No Permanent Foundation</SelectionOption>
                            <SelectionOption value="2x2 Trusses">2x2 Trusses</SelectionOption>
                            <SelectionOption value="Property is worth less than the solar system">Property is worth less than the solar system</SelectionOption>
                            <SelectionOption value="Incompatible Roof Type">Roof Type</SelectionOption>
                        </SelectionField>
                    </div>
                    <div className={"ProfileStatusSelector-notes" + (this.isSelected("Lost Deal") ? " show" : " hide")}>
                        <MultiLineTextField
                            label=""
                            error={this.state.notesError}
                            placeholder="Enter your notes..."
                            onChange={this.handleNotesChange.bind(this)}
                        />
                    </div>
                    <SubmitButton
                        text={this.props.submission}
                        onClick={this.submit.bind(this)}
                    />
                </div>
            </div>
        );
    }
}

export default ProfileStatusSelectorForLeadScreen;
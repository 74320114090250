import { Component } from "react";
import PropTypes from "prop-types";
import "./ProfileField.css";

class ProfileField extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="ProfileField">
                <div className="ProfileField-container">
                    <div className="ProfileField-label">
                        {this.props.label}:
                    </div>
                    <div className="ProfileField-value">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

ProfileField.propTypes = {
    label: PropTypes.string,
    children: PropTypes.node
};

export default ProfileField;
class Week {
    constructor(sunday) {
        this._sunday = sunday;
    }

    static fromDate(date) {
        if (date instanceof Date) {
            const sunday = new Date(date);
            sunday.setDate(sunday.getDate() - sunday.getDay());
            return new Week(sunday);
        } else {
            throw new Error("Week.fromDate() can only be called by passing a Date object as an argument.");
        }
    }

    getDays() {
        const days = [];
        for (let i = 0; i < 7; i++) {
            const date = new Date(this._sunday);
            date.setDate(this._sunday.getDate() + i);
            days.push(date);
        }
        return days;
    }

    getSunday() {
        return this._sunday;
    }

    getMonday() {
        const date = new Date(this._sunday);
        date.setDate(this._sunday.getDate() + 1);
        return date;
    }

    getTuesday() {
        const date = new Date(this._sunday);
        date.setDate(this._sunday.getDate() + 2);
        return date;
    }

    getWednesday() {
        const date = new Date(this._sunday);
        date.setDate(this._sunday.getDate() + 3);
        return date;
    }

    getThursday() {
        const date = new Date(this._sunday);
        date.setDate(this._sunday.getDate() + 4);
        return date;
    }

    getFriday() {
        const date = new Date(this._sunday);
        date.setDate(this._sunday.getDate() + 5);
        return date;
    }

    getSaturday() {
        const date = new Date(this._sunday);
        date.setDate(this._sunday.getDate() + 6);
        return date;
    }

    getNextSunday() {
        const date = new Date(this._sunday);
        date.setDate(this._sunday.getDate() + 7);
        return date;
    }

    getNextWeek() {
        const nextSunday = this.getNextSunday();
        return new Week(nextSunday);
    }
}

export default Week;
import OpportunitySchema from "../../OpportunitySchema";


class SalemOpportunitySchema extends OpportunitySchema {
    constructor(podioApi) {
        super(podioApi, 26531611);
        this.addField("name", 228346572, "first-name");
        this.addField("email", 228346574, "email");
        this.addField("phone", 228346573, "phone");
        this.addField("cosigner", 228346575, "co-name");
        this.addField("cosignerEmail", 228346577, "co-email");
        this.addField("cosignerPhone", 228346576, "co-phone");
        this.addField("address", 228346578, "location-2");
        this.addField("status", 228346579, "lead-status");
        this.addField("stage", 228346582, "status");
        this.addField("appointment", 228346584, "2nd-touch-appt");
        this.addField("auroraId", 240650165, "aurora-project-id");
        this.addField("specialRequests", 240650180, "special-requests");
        this.addField("nickname", 240650181, "nickname");
        this.addField("leadManager", 228346605, "lead-owner");
        this.addField("designStatus", 228346585, "request-design");
        this.addField("consultants", 228346606, "salesperson-2");
        this.addField("hoaName", 240650183, "hoa-name");
        this.addField("hoaEmail", 240650184, "hoa-email");
        this.addField("hoaPhone", 240650185, "hoa-phone");
        this.addField("propertyAccessInstructions", 240650186, "property-access-instructions");
        this.addField("numberOfTreeRemovals", 240650187, "number-of-tree-removals");
        this.addField("partyResponsibleForTreeRemoval", 240650188, "party-responsible-for-tree-removal");
        this.addField("customerWantsRoofBid", 240650189, "customer-wants-roof-bid");
        this.addField("customerWantsMorePanels", 240650190, "customer-wants-more-panels");
        this.addField("numberOfAdditionalPanels", 240650191, "number-of-additional-panels");
        this.addField("referredBy", 228346601, "referred-by");
        this.addField("customerPriority", 240650192, "customer-priority");
        this.addField("customerPriorityExplanation", 240650193, "customer-priority-explanation");
        this.addField("numberOfStories", 240650171, "number-of-stories");
        this.addField("roofAccess", 240650182, "can-access-roof-from-ground-level");
        this.addField("submitDeal", 228346609, "submit-deal-2");
        this.addField("stageNotes", 240650159, "stage-notes");
        this.addField("designRevisionNotes", 240650166, "design-revision-notes");
        this.addField("type", 245082604, "type");
        this.addField("notes", 228346608, "consultant-notes");
    }
}

export default SalemOpportunitySchema;
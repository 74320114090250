import { Component } from "react";
import ErrorAlert from "../../components/forms/ErrorAlert/ErrorAlert";
import MultiLineTextField from "../../components/forms/fields/MultiLineTextField/MultiLineTextField";
import NumericField from "../../components/forms/fields/NumericField/NumericField";
import SelectionField from "../../components/forms/fields/SelectionField/SelectionField";
import SelectionOption from "../../components/forms/fields/SelectionField/SelectionOption";
import ProjectScreen from "../ProjectScreen/ProjectScreen";
import "./RequestScreen.css";

class RequestScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            history: [],
            tab: 1,
            error: null,
            comments: null
        };
        this.propertyChanges = {};
        this.fields = {
            "237351319": "",
            "237351321": props.user.profile.profile_id
        };
    }

    componentDidMount() {
        this.fields["237351321"] = this.props.user.profile.profile_id;
        this.propertyChanges = {};
        this.props.stopLoader();
    }

    goBack() {
        const history = this.state.history;
        if (history.length > 0) {
            const tab = history.pop();
            this.setState({
                history: history,
                tab: tab
            });
        } else {
            this.showProjectScreen();
        }
    }

    showProjectScreen() {
        this.props.showScreen(<ProjectScreen project={this.props.project} branch={this.props.branch}/>);
    }

    showTab(tab) {
        const history = this.state.history;
        if (history >= 50) {
            history.shift();
        }
        history.push(this.state.tab);
        this.setState({
            history: history,
            tab: tab
        });
    }

    isTab(tab) {
        return this.state.tab === tab;
    }

    setPropertyChange(name, value) {
        this.propertyChanges[name] = value;
    }

    updateField(key, value) {
        const fields = {};
        fields[key] = value;
        this.setState(fields);
    }

    requestCancellation() {
        this.props.startLoader();
        const fields = this.fields;
        fields["237351273"] = "Cancellation Request";
        fields["237351320"] = [1];
        fields["237351319"] = this.state.comments;
        this.props.branch.createRequest(this.props.project.id, fields)
            .then(() => this.showProjectScreen())
            .catch((error) => {
                this.setState({
                    error: error.message
                });
            })
    }

    requestOther() {
        this.props.startLoader();
        const fields = this.fields;
        fields["237351273"] = "Change Request";
        fields["237351320"] = [2];
        fields["237351319"] = `<div><b>Changes:</b><ul>`;
        for (const label in this.propertyChanges) {
            const value = this.propertyChanges[label];
            if (value !== null && value !== "") {
                fields["237351319"] += `<li><b>${label}:</b> ${value}</li>`;
            }
        }
        fields["237351319"] += `</ul></div></br><div><b>Comments:</b></div><div>${this.state.comments}</div>`;
        return this.props.branch.createRequest(this.props.project.id, fields)
            .then(() => this.showProjectScreen())
            .catch((error) => {
                this.setState({
                    error: error.message
                });
            });
    }

    isError() {
        return this.state.error !== null;
    }

    clearError() {
        this.setState({
            error: null
        });
    }

    render() {
        return (
            <div className="RequestScreen">
                <div className="RequestScreen-container">
                    <div className="RequestScreen-header">
                        <div className="RequestScreen-header-container">
                            <div className="RequestScreen-header-go-back">
                                <button onClick={this.goBack.bind(this)}>&larr;</button>
                            </div>
                            <div className="RequestScreen-header-title">
                                New Request for <strong>{this.props.project.name}</strong>
                            </div>
                        </div>
                    </div>
                    <div className="RequestScreen-tab-list">
                        <div className="RequestScreen-tab-list-container">
                            <div className={"RequestScreen-tab" + (this.isTab(1) ? " active" : "")}>
                                <div className="RequestScreen-tab-container">
                                    <button className="RequestScreen-cancel-button dark-button large-button full-button" onClick={() => this.showTab(2)}>
                                        <strong>Request Cancellation</strong>
                                    </button>
                                    <button className="RequestScreen-change-button dark-button large-button full-button" onClick={() => this.showTab(3)}>
                                        <strong>Request Change Order</strong>
                                    </button>
                                </div>
                            </div>
                            <div className={"RequestScreen-tab" + (this.isTab(2) ? " active" : "")}>
                                <div className="RequestScreen-tab-container">
                                    <MultiLineTextField label="Comments" placeholder="Please explain why you would like to cancel this project..." onChange={(value) => this.updateField("comments", value)}/>
                                    {this.isError() ? (
                                        <ErrorAlert onClear={this.clearError.bind(this)}>
                                            {this.state.error}
                                        </ErrorAlert>
                                    ) : (
                                        <button className="dark-button large-button full-button" onClick={this.requestCancellation.bind(this)}>
                                            <strong>Request</strong>
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className={"RequestScreen-tab" + (this.isTab(3) ? " active" : "")}>
                                <div className="RequestScreen-tab-container">
                                    <NumericField label="Panel Count" placeholder="Enter the new panel count..." onChange={(value) => this.setPropertyChange("Panel Count", value)}/>
                                    <SelectionField label="Payment Method" placeholder="Select a new payment method..." onChange={(value) => this.setPropertyChange("Payment Method", value)}>
                                        <SelectionOption value={null}>No Change</SelectionOption>
                                        <SelectionOption value="GoodLeap 25yr 2.99%">GoodLead, 25yr, 2.99%</SelectionOption>
                                        <SelectionOption value="GoodLeap 20yr 1.49%">GoodLead, 20yr, 1.49%</SelectionOption>
                                    </SelectionField>
                                    <MultiLineTextField label="Comments" placeholder="Please describe any other information that is important for the project planning team..." onChange={(value) => this.updateField("comments", value)}/>
                                    {this.isError() ? (
                                        <ErrorAlert onClear={this.clearError.bind(this)}>
                                            {this.state.error}
                                        </ErrorAlert>
                                    ) : (
                                        <button className="dark-button large-button full-button" onClick={this.requestOther.bind(this)}>
                                            <strong>Request</strong>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RequestScreen;
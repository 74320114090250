import AttachmentConverter from "./AttachmentConverter";
import Opportunity from "../Opportunity";
import ObjectConverter from "./ObjectConverter";
import PodioAppItemParser from "./parsers/PodioAppItemParser";
import AgentConverter from "./AgentConverter";

class OpportunityConverter extends ObjectConverter {
    constructor(opportunitySchema) {
        super(opportunitySchema);
        this.attachmentConverter = new AttachmentConverter();
        this.agentConverter = new AgentConverter();
    }

    convert(item) {
        const opportunity = new Opportunity();
        opportunity.id = item.item_id;
        opportunity.name = item.title;
        const parser = new PodioAppItemParser(item, this.schema);
        if (parser.itemHasField("phone")) {
            opportunity.phone = parser.getItemContactInformationField("phone");
        }
        if (parser.itemHasField("email")) {
            opportunity.email = parser.getItemContactInformationField("email");
        }
        if (parser.itemHasField("appointment")) {
            opportunity.appointment = parser.getItemDateField("appointment");
        }
        if (parser.itemHasField("address")) {
            opportunity.address = parser.getItemAddressField("address");
        }
        if (parser.itemHasField("stage")) {
            opportunity.stage = parser.getItemSelectionField("stage");
        }
        if (parser.itemHasField("status")) {
            opportunity.status = parser.getItemSelectionField("status");
        }
        if (parser.itemHasField("auroraId")) {
            opportunity.externalProposalId = parser.getItemTextField("auroraId");
        }
        if (parser.itemHasField("siteMap")) {
            opportunity.siteMap = this.getFirstImageAsAttachment(parser.getItemImageField("siteMap"));
        }
        if (item.files !== undefined && item.files.length > 0) {
            opportunity.utilityBills = this.getUtilityBillsAsAttachments(item.files);
        }
        if (parser.itemHasField("leadManager")) {
            opportunity.leadManagers = this.getAgentsFromField(parser.getItemField("leadManager"));
        }
        return opportunity;
    }

    getFirstImageAsAttachment(images) {
        return this.attachmentConverter.convert(images[0]);
    }

    getUtilityBillsAsAttachments(files) {
        const utilityBills = [];
        for (const file of files) {
            const standardizedName = file.name.trim().toLowerCase();
            if (standardizedName.includes("utilitybill") || standardizedName.includes("utility_bill")) {
                utilityBills.push(this.attachmentConverter.convert(file));
            }
        }
        return utilityBills;
    }

    getAgentsFromField(field) {
        const agents = [];
        for (const contact of field.values) {
            const agent = this.agentConverter.convert(contact.value);
            agents.push(agent);
        }
        return agents;
    }
}

export default OpportunityConverter;
class BasicSessionManager {
    constructor() { }

    get(authType, callback) {
        const serialized = localStorage.getItem(`podioOAuth_${authType}`);
        if (serialized) {
            const payload = JSON.parse(serialized);
            if (this.isExpired(payload)) {
                callback({});
            } else {
                callback(payload.authObject);
            }
        } else {
            callback({});
        }
    }

    isExpired(payload) {
        if (Object.keys(payload.authObject).length === 0) {
            return true;
        }
        const timeInSeconds = this.getTimeInSeconds();
        const expirationTimeInSeconds = payload.timestamp + parseInt(payload.authObject.expiresIn);
        return timeInSeconds > expirationTimeInSeconds;
    }

    set(authObject, authType) {
        const serialized = JSON.stringify({ authObject, timestamp: this.getTimeInSeconds() });
        localStorage.setItem(`podioOAuth_${authType}`, serialized);
        this.clearHash();
    }

    getTimeInSeconds() {
        const timeInMilliseconds = Date.now();
        return Math.floor(timeInMilliseconds / 1000);
    }

    remove(authType) {
        localStorage.removeItem(`podioOAuth_${authType}`);
    }

    clearHash() {
        if (window !== undefined && window.location !== undefined && window.location.hash !== undefined) {
            window.location.hash = "";
        }
    }
}

export default BasicSessionManager;
import OpportunitySchema from "../../OpportunitySchema";


class BillingsOpportunitySchema extends OpportunitySchema {
    constructor(podioApi) {
        super(podioApi, 26712630);
        this.addField("name", 230354048, "first-name");
        this.addField("email", 230354050, "email");
        this.addField("phone", 230354049, "phone");
        this.addField("cosigner", 230354051, "co-name");
        this.addField("cosignerEmail", 230354053, "co-email");
        this.addField("cosignerPhone", 230354052, "co-phone");
        this.addField("address", 230354054, "location-2");
        this.addField("status", 230354055, "lead-status");
        this.addField("stage", 230354058, "status");
        this.addField("appointment", 230354060, "2nd-touch-appt");
        this.addField("auroraId", 240687101, "aurora-project-id");
        this.addField("specialRequests", 240687116, "special-requests");
        this.addField("nickname", 240687117, "nickname");
        this.addField("leadManager", 230354081, "lead-owner");
        this.addField("designStatus", 230354061, "request-design");
        this.addField("consultants", 230354082, "salesperson-2");
        this.addField("hoaName", 240687118, "hoa-name");
        this.addField("hoaEmail", 240687119, "hoa-email");
        this.addField("hoaPhone", 240687120, "hoa-phone");
        this.addField("propertyAccessInstructions", 240687121, "property-access-instructions");
        this.addField("numberOfTreeRemovals", 240687122, "number-of-tree-removals");
        this.addField("partyResponsibleForTreeRemoval", 240687123, "party-responsible-for-tree-removal");
        this.addField("customerWantsRoofBid", 240687124, "customer-wants-roof-bid");
        this.addField("customerWantsMorePanels", 240687125, "customer-wants-more-panels");
        this.addField("numberOfAdditionalPanels", 240687126, "number-of-additional-panels");
        this.addField("referredBy", 230354077, "referred-by");
        this.addField("customerPriority", 240687127, "customer-priority");
        this.addField("customerPriorityExplanation", 240687128, "customer-priority-explanation");
        this.addField("numberOfStories", 240687107, "number-of-stories");
        this.addField("roofAccess", 240687129, "can-access-roof-from-ground-level");
        this.addField("submitDeal", 230354085, "submit-deal-2");
        this.addField("stageNotes", 240687092, "stage-notes");
        this.addField("designRevisionNotes", 240687100, "design-revision-notes");
        this.addField("type", 245083032, "type");
        this.addField("notes", 230354084, "consultant-notes");
    }
}

export default BillingsOpportunitySchema;
import { Component } from "react";
import FieldLabel from "../FieldLabel";
import FormField from "../FormField";
import PropTypes from "prop-types";
import "./MultiLineTextField.css";

class MultiLineTextField extends Component {
    constructor(props) {
        super(props);
    }

    handleChange(event) {
        if (this.props.onChange) {
            this.props.onChange(event.target.value);
        }
    }

    render() {
        return (
            <FormField error={this.props.error}>
                <FieldLabel text={this.props.label}/>
                <div className="MultiLineTextField">
                    <div className="MultiLineTextField-container">
                        <textarea onChange={this.handleChange.bind(this)} placeholder={this.props.placeholder}/>
                    </div>
                </div>
            </FormField>
        );
    }
}

MultiLineTextField.propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    label: PropTypes.string
};

export default MultiLineTextField;
import { Component } from "react";
import "./HomeScreen.css";
import logo from "../../assets/logos/vertical-dark.svg";
import whiteLinkIcon from "../../assets/white-link.svg";


class HomeScreen extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.user) {
            this.props.stopLoader();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.user) {
            if (!prevProps.user || prevProps.user.user_id !== this.props.user.user_id) {
                this.props.stopLoader();
            }
        }
    }

    goToMyLeads() {
        this.props.showPage("/deals/leads");
    }

    hasUser() {
        if (this.props.user) {
            return true;
        }
        return false;
    }

    render() {
        return (
            <div className="HomeScreen">
                <div className="HomeScreen-container">
                    <div className="HomeScreen-logo">
                        <img src={logo}/>
                    </div>
                    <div className="HomeScreen-button">
                        <button
                            className="dark-button large-button"
                            onClick={this.goToMyLeads.bind(this)}
                        >
                            My Leads <img src={whiteLinkIcon} width={12} height={12}/>
                        </button>
                    </div>
                    <div className="HomeScreen-message">
                        {this.hasUser() && (
                            <span>
                                You are logged in as <b>{this.props.user.profile.name}</b>
                            </span>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeScreen;
import { Component } from "react";
import FieldLabel from "../FieldLabel";
import FormField from "../FormField";
import PropTypes from "prop-types";
import "./SingleLineTextField.css";

class SingleLineTextField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDefault: props.default !== undefined && props.default !== null && props.default !== ""
        };
    }

    handleChange(event) {
        if (this.props.onChange) {
            this.props.onChange(event.target.value);
        }
    }

    hideDefault() {
        this.setState({
            showDefault: false
        });
    }

    render() {
        return (
            <FormField error={this.props.error}>
                <FieldLabel text={this.props.label}/>
                <div className="SingleLineTextField">
                    <div className="SingleLineTextField-container">
                        {this.state.showDefault ? (
                            <div className="SingleLineTextField-default">
                                <div className="SingleLineTextField-default-value">
                                    {this.props.default}
                                </div>
                                <div className="SingleLineTextField-change-button" onClick={this.hideDefault.bind(this)}>
                                    change
                                </div>
                            </div>
                        ) : (
                            <input type="text" onChange={this.handleChange.bind(this)} placeholder={this.props.placeholder}/>
                        )}
                    </div>
                </div>
            </FormField>
        );
    }
}

SingleLineTextField.propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    label: PropTypes.string
};

export default SingleLineTextField;
import { Component } from "react";
import "./CalendarSelection.css";
import Month from "./Month";


class CalendarSelection extends Component {
    
    constructor(props) {
        super(props);
        this.months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        if (props.value !== null) {
            this.state = {
                year: props.value.getFullYear(),
                month: props.value.getMonth()
            };
        } else {
            const today = new Date();
            this.state = {
                year: today.getFullYear(),
                month: today.getMonth()
            };
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== null && this.props.value === null) {
            const today = new Date();
            this.setState({
                year: today.getFullYear(),
                month: today.getMonth()
            });
        }
    }

    previousMonth() {
        if (this.state.month === 0) {
            this.setState({
                year: this.state.year - 1,
                month: 11
            });
        } else {
            this.setState({
                month: this.state.month - 1
            });
        }
    }

    nextMonth() {
        if (this.state.month === 11) {
            this.setState({
                year: this.state.year + 1,
                month: 0
            });
        } else {
            this.setState({
                month: this.state.month + 1
            });
        }
    }

    getMonth() {
        return new Month(this.state.year, this.state.month);
    }

    getMonthText() {
        return this.months[this.state.month];
    }

    inMonth(date) {
        return date.getMonth() === this.state.month;
    }

    select(date) {
        if (this.props.onChange) {
            this.props.onChange(date);
        }
    }

    isSelected(date) {
        if (this.props.value && this.props.value.getFullYear() === date.getFullYear()) {
            if (this.props.value.getMonth() === date.getMonth()) {
                return this.props.value.getDate() === date.getDate();
            }
        }
        return false;
    }

    render() {
        return (
            <div className={"CalendarSelection" + (this.props.show ? " open" : "")}>
                <div className="CalendarSelection-container">
                    <div className="CalendarSelection-navigator">
                        <div className="CalendarSelection-month-selection">
                            <div className="CalendarSelection-previous-month" onClick={this.previousMonth.bind(this)}>
                                &larr;
                            </div>
                            <div className="CalendarSelection-current-month">
                                {this.getMonthText()} {this.state.year}
                            </div>
                            <div className="CalendarSelection-next-month" onClick={this.nextMonth.bind(this)}>
                                &rarr;
                            </div>
                        </div>
                    </div>
                    <div className="CalendarSelection-days">
                        {this.getMonth().weeks.map((week, index) => (
                            <div className="CalendarSelection-week" key={index}>
                                {week.getDays().map((date, index) => (
                                    <div className={"CalendarSelection-day" + (this.inMonth(date) ? "" : " dull") + (this.isSelected(date) ? " selected" : "")} onClick={() => this.select(date)} key={index}>
                                        {date.getDate()}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default CalendarSelection;
import PodioAppSchema from "./PodioAppSchema";

class ProjectSchema extends PodioAppSchema {
    constructor(podioApi, appId) {
        super(podioApi, appId);
        this.addKey("name", "text");
        this.addKey("email", "email");
        this.addKey("phone", "email");
        this.addKey("address", "location");
        this.addKey("stage", "category");
        this.addKey("status", "category");
        this.addKey("panel", "category");
        this.addKey("panelCount", "number");
        this.addKey("systemSize", "number");
        this.addKey("price", "currency");
        this.addKey("consultant", "contact");
        this.addKey("requests", "app");
        this.addKey("issues", "app");
    }
}

export default ProjectSchema;
import { Component } from "react";
import SelectionField from "../fields/SelectionField/SelectionField";
import SelectionOption from "../fields/SelectionField/SelectionOption";
import MultipleFileField from "../fields/MultipleFileField/MultipleFileField";
import MultiLineTextField from "../fields/MultiLineTextField/MultiLineTextField";
import InvalidFormDataError from "../errors/FormValidationError";
import ErrorAlert from "../ErrorAlert/ErrorAlert";
import PodioSimpleMultipleFileUploader from "../../../storage/podio/PodioSimpleMultipleFileUploader";

class DesignRevisionForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isSuccess: false,
            error: null,
            uploads: {
                example: {
                    loading: false,
                    error: null,
                    value: null,
                    findError: (value) => {
                        if (this.state.uploads.example.loading) {
                            return "You cannot request your revision while files are uploading";
                        }
                        return null;
                    }
                }
            },
            conditionals: {
                description: {
                    error: null,
                    value: null,
                    findError: (value) => {
                        if (value === null) {
                            return "This proposal request is incomplete.";
                        }
                        return null;
                    }
                }
            },
            fields: {
                designStatus: {
                    error: null,
                    value: null,
                    findError: (value) => {
                        if (value === null) {
                            return "This field cannot be left blank.";
                        }
                        return null;
                    }
                }
            }
        };
        this.simpleUploader = new PodioSimpleMultipleFileUploader(this.props.podio);
    }

    componentDidMount() {
        this.fields = {};
        for (const key in this.labels) {
            this.fields[key] = null;
        }
    }

    updateConditional(key, value) {
        const conditionals = this.state.conditionals;
        conditionals[key].value = value;
        if (conditionals[key].findError !== undefined) {
            conditionals[key].error = conditionals[key].findError(value);
        } else {
            conditionals[key].error = null;
        }
        this.setState({ conditionals });
    }

    updateField(key, value) {
        const fields = this.state.fields;
        fields[key].value = value;
        if (fields[key].findError !== undefined) {
            fields[key].error = fields[key].findError(value);
        } else {
            fields[key].error = null;
        }
        this.setState({ fields });
    }

    submit() {
        this.startLoader();
        const validation = this.validate();
        if (validation.isValid) {
            this.props.branch.requestDesignRevision(this.props.opportunity, this.getFields())
                .then(() => this.setSuccessful())
                .catch(this.setError.bind(this))
        } else {
            this.setError(validation.error);
        }
    }

    validate() {
        const validation = { isValid: true, error: null };
        const fields = this.state.fields;
        for (const key in fields) {
            if (fields[key].findError !== undefined) {
                fields[key].error = fields[key].findError(fields[key].value);
                if (fields[key].error !== null && validation.isValid) {
                    validation.isValid = false;
                    validation.error = new Error("Please fix the issues on the fields above.");
                }
            }
        }
        const conditionals = this.state.conditionals;
        for (const key in conditionals) {
            if (conditionals[key].findError !== undefined) {
                conditionals[key].error = conditionals[key].findError(conditionals[key].value);
                if (conditionals[key].error !== null && validation.isValid) {
                    validation.isValid = false;
                    validation.error = new Error("Please fix the issues on the fields above.");
                }
            }
        }
        const uploads = this.state.uploads;
        for (const key in uploads) {
            if (uploads[key].findError !== undefined) {
                uploads[key].error = uploads[key].findError(uploads[key].value);
                if (uploads[key].error !== null && validation.isValid) {
                    validation.isValid = false;
                    validation.error = new Error("Please fix the issues on the fields above.");
                }
            }
        }
        this.setState({ fields, conditionals, uploads });
        return validation;
    }

    getFields() {
        const fields = {};
        for (const key in this.state.fields) {
            if (this.state.fields[key].value !== null) {
                fields[key] = this.state.fields[key].value;
            }
        }
        fields["designRevisionNotes"] = `<p><b>Notes:</b></p><p>${this.state.conditionals.description.value}</p>`;
        if (this.state.uploads.example.value) {
            fields["designRevisionNotes"] += `<p><b>Example</b></p><p>`;
            for (const image of this.state.uploads.example.value) {
                fields["designRevisionNotes"] += `<img src="${image.url}"/>`;
            }
            fields["designRevisionNotes"] += "</p>";
        }
        return fields;
    }

    startLoader() {
        this.props.startLoader();
        this.setState({
            isLoading: true,
            isSuccess: false,
            error: null
        });
    }

    setSuccessful() {
        this.setState({
            isLoading: false,
            isSuccess: true,
            error: null
        });
        if (this.props.onSubmitted) {
            this.props.onSubmitted();
        }
    }

    setError(error) {
        this.setState({
            isLoading: false,
            isSuccess: false,
            error: error instanceof InvalidFormDataError ? error.toHtml() : error.message
        });
        this.props.stopLoader();
    }

    isError() {
        return this.state.error !== null;
    }

    clearError() {
        this.setState({
            error: null
        });
    }

    updateProposal(index, proposal) {
        this.proposals[index] = proposal;
    }

    startUpload(key) {
        const uploads = this.state.uploads;
        uploads[key].loading = true;
        this.setState({ uploads });
    }

    completeUpload(key, value) {
        const uploads = this.state.uploads;
        uploads[key].loading = false;
        uploads[key].value = value;
        uploads[key].error = null;
        this.setState({ uploads });
    }

    render() {
        const { fields, conditionals, uploads } = this.state;
        return (
            <div className="DesignRevisionForm">
                <div className="DesignRevisionForm-container">
                    <MultiLineTextField
                        label="Please describe why a revision is needed:"
                        placeholder="Enter a description..."
                        error={conditionals.description.error}
                        onChange={(value) => this.updateConditional("description", value)}
                    />
                    <MultipleFileField
                        label="Upload an image demonstrating what needs to change on the design"
                        error={uploads.example.error}
                        uploader={this.simpleUploader}
                        onStartLoader={() => this.startUpload("example")}
                        onChange={(value) => this.completeUpload("example", value)}
                    />
                    <SelectionField label="Select a turnaround time for the proposal" placeholder="Select turnaround time..." error={fields.designStatus.error} onChange={(value) => this.updateField("designStatus", value)}>
                        <SelectionOption value={"In Home Revision"}>In Home</SelectionOption>
                        <SelectionOption value={"Needs Revision"}>Next Day</SelectionOption>
                    </SelectionField>
                    {!this.state.isLoading && this.isError() ? (
                        <ErrorAlert onClear={this.clearError.bind(this)}>
                            <div dangerouslySetInnerHTML={{__html: this.state.error}}/>
                        </ErrorAlert>
                    ) : (
                        <button className="dark-button large-button full-button submit-button" onClick={this.submit.bind(this)}>
                            <strong>Request</strong>
                        </button>
                    )}
                </div>
            </div>
        );
    }
}

export default DesignRevisionForm;
import { Component } from "react";
import "./CircularCloseButton.css";


class CircularCloseButton extends Component {
    handleClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {
        return (
            <div
                className="CircularCloseButton"
                style={{
                    height: this.props.size,
                    width: this.props.size,
                    borderRadius: this.props.size,
                    top: -(this.props.size/3),
                    right: -(this.props.size/3),
                    fontSize: this.props.size * 0.8
                }}
                onClick={this.handleClick.bind(this)}
            >
                <span>&times;</span>
            </div>
        );
    }
}

export default CircularCloseButton;
import Platform from "../../Platform";
import PodioLeadRepository from "./repositories/PodioLeadRepository";

class PodioPlatform extends Platform {
    constructor(api) {
        super();
        /** @private */
        this.api = api;
        /** @protected */
        this.leads = new PodioLeadRepository(this.api);
    }

    createLead(lead) {
        return this.leads.create(lead);
    }

    getApi() {
        return this.api;
    }

    logout() {
        this.api._clearAuthentication();
        return Promise.resolve();
    }
}

export default PodioPlatform;
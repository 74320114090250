class FileUploader {
    constructor(resourceId) {
        this.resourceId = resourceId;
    }

    attach(file) {
        throw new Error("The FileUploader class must be extended, it cannot be used directly.");
    }

    remove(attachment) {
        throw new Error("The FileUploader class must be extended, it cannot be used directly.");
    }
}

export default FileUploader;
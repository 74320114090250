import { Component } from "react";
import "./FileThumbnail.css";

class FileThumbnail extends Component {
    constructor(props) {
        super(props);
        this.recognizedTypes = {
            "application/pdf": "pdf",
            "image/png": "png",
            "image/jpg": "jpg",
            "image/jpeg": "jpeg",
            "image/gif": "gif"
        };
    }

    isRecognizedType() {
        return this.recognizedTypes.hasOwnProperty(this.props.file.type);
    }

    getTypeClass() {
        if (this.isRecognizedType()) {
            return this.recognizedTypes[this.props.file.type];
        } else {
            return "other";
        }
    }

    getExtension() {
        const parts = this.props.file.name.split(".");
        if (parts.length > 1) {
            return parts[parts.length - 1];
        } else {
            return this.props.file.type.trim().split("/")[1];
        }
    }

    render() {
        return (
            <div className={`FileThumbnail type-${this.getTypeClass()}` + (this.props.file.isLoading ? " loading" : "")}>
                <div className="FileThumbnail-container">
                    <div className="FileThumbnail-type">
                        <span>{this.getExtension()}</span>
                    </div>
                    <div className="FileThumbnail-loader"></div>
                </div>
            </div>
        );
    }
}

export default FileThumbnail;
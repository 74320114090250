/**
 * @typedef {import("../Lead").default} Lead
 */

/**
 * @abstract
 */
class Calendar {
    /**
     * @returns {Promise<any>}
     */
    authenticate() {
        throw new Error("The Calendar abstract class must be extended before calling this method.");
    }

    /**
     * @returns {boolean}
     */
    isAuthenticated() {
        throw new Error("The Calendar abstract class must be extended before calling this method.");
    }

    /**
     * @param {Lead} lead 
     * @returns {Promise<any>}
     */
    scheduleConsultation(lead) {
        throw new Error("The Calendar abstract class must be extended before calling this method.");
    }
}

export default Calendar;
import { Component } from "react";
import "./style.css";

class CollapsibleNotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    toggle() {
        this.setState({
            open: !this.state.open,
        });
    }

    render() {
        return (
            <div
                className={
                    "CollapsibleNotes" +
                    (this.props.show ? " show" : " hide") +
                    (this.state.open ? " open" : " closed")
                }
            >
                <div
                    className="CollapsibleNotes-bar"
                    onClick={this.toggle.bind(this)}
                >
                    <div className="CollapsibleNotes-bar-wrapper">
                        <div className="CollapsibleNotes-bar-container">
                            <div className="CollapsibleNotes-bar-icon CollapsibleNotes-bar-text">
                                {this.state.open ? "-" : "+"}
                            </div>
                            <div className="CollapsibleNotes-bar-instruction CollapsibleNotes-bar-text">
                                {this.state.open ? "Hide" : "Show"}
                            </div>
                            <div className="CollapsibleNotes-bar-title CollapsibleNotes-bar-text">
                                {this.props.title}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="CollapsibleNotes-content"
                    dangerouslySetInnerHTML={{ __html: this.props.notes }}
                />
            </div>
        );
    }
}

export default CollapsibleNotes;

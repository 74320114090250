import Issue from "../Issue";
import ObjectConverter from "./ObjectConverter";
import PodioItemParser from "./parsers/PodioItemParser";

class IssueConverter extends ObjectConverter {
    constructor() {
        super(null);
    }

    convert(item) {
        const issue = new Issue();
        issue.id = item.item_id;
        issue.name = item.title;
        issue.createdOn = item.createdOn;
        if (undefined !== item.fields) {
            const parser = new PodioItemParser(item);
            if (parser.itemHasField(237308630)) {
                issue.status = parser.getItemSelectionField(237308630);
            }
            if (parser.itemHasField(237308330)) {
                issue.problem = parser.getItemTextField(237308330);
            }
            if (parser.itemHasField(237308331)) {
                issue.options = parser.getItemTextField(237308331);
            }
        }
        return issue;
    }
}

export default IssueConverter;
import OpportunitySchema from "../../OpportunitySchema";


class BendOpportunitySchema extends OpportunitySchema {
    constructor(podioApi) {
        super(podioApi, 24389676);
        this.addField("name", 205590185, "first-name");
        this.addField("email", 205590187, "email");
        this.addField("phone", 205590186, "phone");
        this.addField("cosigner", 205590188, "co-name");
        this.addField("cosignerEmail", 205590190, "co-email");
        this.addField("cosignerPhone", 205590189, "co-phone");
        this.addField("address", 205590191, "location-2");
        this.addField("status", 205590192, "lead-status");
        this.addField("stage", 205590195, "status");
        this.addField("appointment", 205590197, "2nd-touch-appt");
        this.addField("auroraId", 240634125, "aurora-project-id");
        this.addField("specialRequests", 240634126, "special-requests");
        this.addField("nickname", 240634127, "nickname");
        this.addField("leadManager", 205590213, "lead-owner");
        this.addField("designStatus", 205590198, "request-design");
        this.addField("consultants", 205590214, "salesperson-2");
        this.addField("hoaName", 240634128, "hoa-name");
        this.addField("hoaEmail", 240634129, "hoa-email");
        this.addField("hoaPhone", 240634130, "hoa-phone");
        this.addField("propertyAccessInstructions", 240634131, "property-access-instructions");
        this.addField("numberOfTreeRemovals", 240634132, "number-of-tree-removals");
        this.addField("partyResponsibleForTreeRemoval", 240634133, "party-responsible-for-tree-removal");
        this.addField("customerWantsRoofBid", 240634134, "customer-wants-roof-bid");
        this.addField("customerWantsMorePanels", 240634135, "customer-wants-more-panels");
        this.addField("numberOfAdditionalPanels", 240634136, "number-of-additional-panels");
        this.addField("referredBy", 205590210, "referred-by");
        this.addField("customerPriority", 240634137, "customer-priority");
        this.addField("customerPriorityExplanation", 240634138, "customer-priority-explanation");
        this.addField("numberOfStories", 240634110, "number-of-stories");
        this.addField("roofAccess", 240634140, "can-access-roof-from-ground-level");
        this.addField("submitDeal", 222222882, "submit-deal-2");
        this.addField("stageNotes", 240634124, "stage-notes");
        this.addField("designRevisionNotes", 240634141, "design-revision-notes");
        this.addField("type", 245082633, "type");
        this.addField("notes", 205590215, "consultant-notes");
    }
}

export default BendOpportunitySchema;
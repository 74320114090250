import { Component } from "react";
import TableData from "../../components/table/TableData";
import ObjectQueue from "../../components/templates/ObjectQueue";
import TableHeader from "../../components/table/TableHeader";

class StaleOpportunitiesScreen extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ObjectQueue
                {...this.props}
                title="My Not Dispositioned Opportunities"
                recordPath="/deals/opportunities/:id"
                fetchResources={(user) => this.props.activeBranch.fetchStaleOpportunities(user)}
                header={(
                    <TableHeader>
                        <TableData className="medium">ID</TableData>
                        <TableData>Name</TableData>
                        <TableData>Proposal Date</TableData>
                        <TableData className="medium">City</TableData>
                    </TableHeader>
                )}
                platform={this.props.platform}
                renderRecord={(opportunity) => (
                    <>
                        <TableData className="medium">{opportunity.id}</TableData>
                        <TableData>
                            <strong>{opportunity.getFullName()}</strong>
                        </TableData>
                        <TableData>
                            {opportunity.appointment.toLocaleString()}
                        </TableData>
                        <TableData className="medium">
                            {opportunity.getCityAndState()}
                        </TableData>
                    </>
                )}
            />
        );
    }
}

export default StaleOpportunitiesScreen;
import { Component } from "react";
import "./CheckboxOption.css";

class CheckboxOption extends Component {
    constructor(props) {
        super(props);
    }

    toggle() {
        if (this.props.selected) {
            if (this.props.onDeselect) {
                this.props.onDeselect();
            }
        } else {
            if (this.props.onSelect) {
                this.props.onSelect(this.props.value);
            }
        }
    }

    render() {
        return (
            <div className={"CheckboxOption" + (this.props.selected ? " selected" : "") + (this.props.error ? " error" : "")}>
                <div className="CheckboxOption-container" onClick={this.toggle.bind(this)}>
                    <div className="CheckboxOption-box"></div>
                    <div className="CheckboxOption-text">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default CheckboxOption;
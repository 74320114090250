import UploadableFile from "./UploadableFile";

class UploadedFile extends UploadableFile {
    constructor(externalId = null, url = null) {
        super();
        this.isLoading = false;
        this.externalId = externalId;
        this.url = url;
    }

    static fromUploadable(uploadable, externalId = null, url = null) {
        const uploaded = new UploadedFile(externalId, url);
        uploaded.id = uploadable.id;
        uploaded.name = uploadable.name
        uploaded.type = uploadable.type;
        uploaded.blob = uploadable.blob;
        return uploaded;
    }
}

export default UploadedFile;
import Project from "../Project";
import IssueConverter from "./IssueConverter";
import ObjectConverter from "./ObjectConverter";
import PodioAppItemParser from "./parsers/PodioAppItemParser";

class ProjectConverter extends ObjectConverter {
    constructor(projectSchema) {
        super(projectSchema);
    }

    convert(item) {
        const project = new Project();
        project.id = item.item_id;
        project.name = item.title;
        project.signedOn = new Date(item.created_on);
        project.externalUrl = item.link;
        const parser = new PodioAppItemParser(item, this.schema);
        if (parser.itemHasField("stage")) {
            project.phase = parser.getItemSelectionField("stage");
        }
        if (parser.itemHasField("panel")) {
            project.panel = parser.getItemSelectionField("panel");
        }
        if (parser.itemHasField("panelCount")) {
            project.panelCount = parser.getItemNumberField("panelCount")
        }
        if (parser.itemHasField("systemSize")) {
            project.systemSize = parser.getItemNumberField("systemSize") * 1000;
        }
        if (parser.itemHasField("price")) {
            project.contractPrice = parser.getItemCurrencyField("price");
        }
        if (parser.itemHasField("issues")) {
            project.issues = this.getIssues(parser.getItemField("issues"));
        }
        return project;
    }

    getIssues(field) {
        const issues = [];
        const issueConverter = new IssueConverter();
        for (const relationship of field.values) {
            const issue = issueConverter.convert(relationship.value);
            issues.push(issue);
        }
        return issues;
    }
}

export default ProjectConverter;
import LeadConverter from "../../../converters/LeadConverter";
import OpportunityConverter from "../../../converters/OpportunityConverter";
import ProjectConverter from "../../../converters/ProjectConverter";
import PodioBranch from "../PodioBranch";
import BillingsLeadSchema from "../schema/workspaces/billings/BillingsLeadSchema";
import BillingsOpportunitySchema from "../schema/workspaces/billings/BillingsOpportunitySchema";
import BillingsProjectSchema from "../schema/workspaces/billings/BillingsProjectSchema";


class BillingsBranch extends PodioBranch {
    constructor(podioApi) {
        super(podioApi);
        this.id = 5;
        this.name = "Billings";
        this.leadSchema = new BillingsLeadSchema(podioApi);
        this.leadConverter = new LeadConverter(this.leadSchema);
        this.opportunitySchema = new BillingsOpportunitySchema(podioApi);
        this.opportunityConverter = new OpportunityConverter(this.opportunitySchema);
        this.projectSchema = new BillingsProjectSchema(podioApi);
        this.projectConverter = new ProjectConverter(this.projectSchema);
    }
}

export default BillingsBranch;
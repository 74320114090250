import { Component } from "react";
import MultiLineTextField from "../../forms/fields/MultiLineTextField/MultiLineTextField";
import SelectionField from "../../forms/fields/SelectionField/SelectionField";
import SubmitButton from "../../SubmitButton/SubmitButton";
import "./ProfileStatusSelector.css";


class ProfileStatusSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selection: null,
            selectionError: null,
            notesRequired: false,
            notes: null,
            notesError: null
        };
    }

    handleSelection({ value, notesRequired }) {
        this.setState({
            selection: value,
            notesRequired,
            selectionError: null,
            notesError: null
        });
    }

    handleNotesChange(value) {
        this.setState({
            notes: value
        });
    }

    submit() {
        if (this.state.selection === null) {
            this.setState({
                selectionError: "Select a valid option",
                notesError: null
            });
        } else if (this.state.notes === null && this.state.notesRequired) {
            this.setState({
                selectionError: null,
                notesError: "Enter notes about this change in status."
            });
        } else if (this.props.onSubmit) {
            this.props.onSubmit(this.state.selection, this.state.notes);
        }
    }

    render() {
        return (
            <div className="ProfileStatusSelector">
                <div className="ProfileStatusSelector-container">
                    <SelectionField
                        label={this.props.label}
                        onChange={this.handleSelection.bind(this)}
                        placeholder="Select a Status"
                        error={this.state.selectionError}
                    >
                        {this.props.children}
                    </SelectionField>
                    <div className={"ProfileStatusSelector-notes" + (this.state.selection !== null ? " show" : " hide")}>
                        <MultiLineTextField
                            label=""
                            error={this.state.notesError}
                            placeholder="Enter your notes..."
                            onChange={this.handleNotesChange.bind(this)}
                        />
                    </div>
                    <SubmitButton
                        text={this.props.submission}
                        onClick={this.submit.bind(this)}
                    />
                </div>
            </div>
        );
    }
}

export default ProfileStatusSelector;
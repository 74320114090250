import LeadConverter from "../../../converters/LeadConverter";
import OpportunityConverter from "../../../converters/OpportunityConverter";
import ProjectConverter from "../../../converters/ProjectConverter";
import PodioBranch from "../PodioBranch";
import TestLeadSchema from "../schema/workspaces/test/TestLeadSchema";
import TestOpportunitySchema from "../schema/workspaces/test/TestOpportunitySchema";
import TestProjectSchema from "../schema/workspaces/test/TestProjectSchema";

class TestBranch extends PodioBranch {
    constructor(podioApi) {
        super(podioApi);
        this.id = 0;
        this.name = "Test";
        this.leadSchema = new TestLeadSchema(podioApi);
        this.leadConverter = new LeadConverter(this.leadSchema);
        this.opportunitySchema = new TestOpportunitySchema(podioApi);
        this.opportunityConverter = new OpportunityConverter(this.opportunitySchema);
        this.projectSchema = new TestProjectSchema(podioApi);
        this.projectConverter = new ProjectConverter(this.projectSchema);
    }
}

export default TestBranch;
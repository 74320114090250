import { Component } from "react";
import RemovableFile from "../../../../storage/RemovableFile";
import UploadableFile from "../../../../storage/UploadableFile";
import AddFileIcon from "../../../AddFileIcon";
import FileUploaderComponentHelper from "../../../FileUploaderComponentHelper";
import SingleFileIcon from "../../../SingleFileIcon";
import "./MultipleFileUploader.css";


class MultipleFileUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: []
        };
        this.uid = FileUploaderComponentHelper.getUID("MultipleFileUploader");
        this.input = null;
    }

    componentDidMount() {
        this.input = document.getElementById(this.uid);
    }

    handleInputChange() {
        if (this.input.files.length > 0) {
            this.addFiles(this.input.files);
        }
        this.input.value = "";
    }

    addFiles(webObjects) {
        const files = this.getUniqueFiles(webObjects);
        if (files.length > 0) {
            this.upload(files);
        }
    }

    getUniqueFiles(webObjects) {
        const files = [];
        for (const webObject of webObjects) {
            if (!this.hasFile(webObject)) {
                const file = UploadableFile.fromWebObject(webObject);
                files.push(file);
            }
        }
        return files;
    }

    hasFile(webObject) {
        for (const file of this.state.files) {
            if (file.equalsWebObject(webObject)) {
                return true;
            }
        }
        return false;
    }
    
    upload(files) {
        this.triggerUploadStarted(files);
        this.addToQueue(files);
        this.attachGroupOfFiles(files);
    }

    triggerUploadStarted(files) {
        if (!this.hasPendingUploads() && this.props.onStartUpload) {
            this.props.onStartUpload(files);
        }
    }

    hasPendingUploads() {
        for (const file of this.state.files) {
            if (file.isLoading) {
                return true;
            }
        }
        return false;
    }

    addToQueue(files) {
        const queueFiles = this.state.files;
        queueFiles.push(...files);
        this.setState({
            files: queueFiles
        });
    }

    attachGroupOfFiles(files) {
        this.props.uploader.attach(files)
            .then((uploaded) => {
                const queue = this.getUpdatedQueueFiles(uploaded);
                this.triggerUploadCompleted(queue);
                this.updateQueue(queue);
            }).catch((error) => this.triggerError(error));
    }

    triggerUploadCompleted(queue) {
        if (this.props.onCompleteUpload && !this.queueHasPendingUploads(queue)) {
            this.props.onCompleteUpload(queue);
        }
    }

    queueHasPendingUploads(queue) {
        for (const file of queue) {
            if (file.isLoading) {
                return true;
            }
        }
        return false;
    }

    queueFileIsInGroup(queueFile, groupFiles) {
        for (const groupFile of groupFiles) {
            if (queueFile.id !== groupFile.id) {
                return true;
            }
        }
        return false;
    }

    updateFilesInQueue(groupFiles) {
        const queueFiles = this.getUpdatedQueueFiles(groupFiles);
        this.setState({
            files: queueFiles
        });
    }

    getFileIndex(needle, haystack) {
        for (let i = 0; i < haystack.length; i++) {
            const file = haystack[i];
            if (file.id === needle.id) {
                return i;
            }
        }
        throw new Error(`There is no file with an id of ${needle.id}.`);
    }

    handleRemoveClick(file) {
        const removable = RemovableFile.fromUploadedFile(file);
        this.remove(removable);
    }

    remove(removableFile) {
        this.triggerRemoveStarted(removableFile);
        this.updateQueueFile(removableFile);
        this.detachFile(removableFile);
    }

    triggerRemoveStarted(removableFile) {
        if (this.props.onStartRemove && !this.hasPendingRemovals()) {
            this.props.onStartRemove(removableFile);
        }
    }

    hasPendingRemovals() {
        for (const file of this.state.files) {
            if (file instanceof RemovableFile && file.isLoading) {
                return true;
            }
        }
        return false;
    }

    updateQueueFile(file) {
        const queueFiles = this.getUpdatedQueueFiles([file]);
        this.setState({
            files: queueFiles
        });
    }

    detachFile(removableFile) {
        this.props.uploader.remove(removableFile)
            .then((removedFile) => {
                const queue = this.getQueueFilesWithoutRemoved(removedFile);
                this.updateQueue(queue);
                this.triggerRemoveCompleted(queue);
            }).catch((error) => this.triggerError(error));
    }

    triggerRemoveCompleted(queue) {
        if (this.props.onCompleteRemove && !this.queueHasPendingRemovals(queue)) {
            this.props.onCompleteRemove(queue);
        }
    }

    queueHasPendingRemovals(queue) {
        for (const file of queue) {
            if (file instanceof RemovableFile && file.isLoading) {
                return true;
            }
        }
        return false;
    }

    getUpdatedQueueFiles(updatedFiles) {
        const queueFiles = this.state.files;
        for (const updatedFile of updatedFiles) {
            try {
                const index = this.getFileIndex(updatedFile, queueFiles);
                queueFiles[index] = updatedFile;
            } catch (_) {
                queueFiles.push(updatedFile);
            }
        }
        return queueFiles;
    }

    getQueueFilesWithoutRemoved(removed) {
        const queueFiles = this.state.files;
        try {
            const index = this.getFileIndex(removed, queueFiles);
            queueFiles.splice(index, 1);
        } catch (_) {}
        return queueFiles;
    }

    updateQueue(queue) {
        this.setState({
            files: queue
        });
    }

    openDialog() {
        this.input.click();
    }

    render() {
        return (
            <div className="MultipleFileUploader">
                <input
                    type="file"
                    id={this.uid}
                    className="MultipleFileUploader-input"
                    onChange={this.handleInputChange.bind(this)}
                    multiple
                />
                <div className="MultipleFileUploader-icons">
                    <div className="MultipleFileUploader-files">
                        {this.state.files.length > 0 && this.state.files.map((file, index) => (
                            <SingleFileIcon
                                file={file}
                                onRemove={() => this.handleRemoveClick(file)}
                                key={index}
                            />
                        ))}
                    </div>
                    <AddFileIcon
                        onClick={this.openDialog.bind(this)}
                    />
                </div>
            </div>
        );
    }
}

export default MultipleFileUploader;
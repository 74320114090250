import PodioAppSchema from "./PodioAppSchema";

class LeadSchema extends PodioAppSchema {
    constructor(podioApi, appId) {
        super(podioApi, appId);
        this.addKey("name", "text");
        this.addKey("email", "email");
        this.addKey("phone", "phone");
        this.addKey("cosignerName", "text");
        this.addKey("cosignerEmail", "email");
        this.addKey("cosignerPhone", "phone");
        this.addKey("address", "location");
        this.addKey("status", "category");
        this.addKey("stage", "category");
        this.addKey("appointment", "date");
        this.addKey("leadManager", "contact");
        this.addKey("utilityCompany", "category");
        this.addKey("installationAddress", "location");
        this.addKey("mountingType", "category");
        this.addKey("roofMaterial", "category");
        this.addKey("isLongTermResident", "category");
        this.addKey("shortTermResidencyReason", "category");
        this.addKey("januaryConsumption", "number");
        this.addKey("februaryConsumption", "number");
        this.addKey("marchConsumption", "number");
        this.addKey("aprilConsumption", "number");
        this.addKey("mayConsumption", "number");
        this.addKey("juneConsumption", "number");
        this.addKey("julyConsumption", "number");
        this.addKey("augustConsumption", "number");
        this.addKey("septemberConsumption", "number");
        this.addKey("octoberConsumption", "number");
        this.addKey("novemberConsumption", "number");
        this.addKey("decemberConsumption", "number");
        this.addKey("squareFootage", "number");
        this.addKey("yearBuilt", "number");
        this.addKey("numberOfStories", "number");
        this.addKey("numberOfResidents", "number");
        this.addKey("heatingType", "category");
        this.addKey("airConditioner", "category");
        this.addKey("waterHeater", "category");
        this.addKey("wholeHomeLed", "category");
        this.addKey("applianceType", "category");
        this.addKey("consumptionChanges", "text");
        this.addKey("utilityMeterNumber", "text");
        this.addKey("utilityAccountNumber", "text");
        this.addKey("proposalRequest1", "text");
        this.addKey("proposalRequest2", "text");
        this.addKey("proposalRequest3", "text");
        this.addKey("utilityBill", "image");
        this.addKey("usageDisclosureForm", "file");
        this.addKey("numberOfProposals", "number");
        this.addKey("proposalDescription", "text");
        this.addKey("needsTreeRemoval", "category");
        this.addKey("treeRemovalDescription", "text");
        this.addKey("siteMap", "image");
        this.addKey("aestheticRequests", "text");
        this.addKey("targetOffset", "number");
        this.addKey("adders", "text");
        this.addKey("turnaroundTime", "category");
        this.addKey("designStatus", "category");
        this.addKey("consultants", "contact");
        this.addKey("leadManagers", "contact");
        this.addKey("proposalAppointment", "date");
        this.addKey("stageNotes", "text");
        this.addKey("leadManagerNotes", "text");
        this.addKey("channel", "category");
        this.addKey("source", "category");
        this.addKey("referredBy", "text");
        this.addKey("type", "category");
        this.addKey("unqualifiedReason", "category");
    }
}

export default LeadSchema;
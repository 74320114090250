import { Component } from "react";
import Table from "../../components/table/Table";
import TableData from "../../components/table/TableData";
import TableRow from "../../components/table/TableRow";
import BranchSelector from "../../components/BranchSelector/BranchSelector";
import "./ProjectsScreen.css";
import ScreenContainer from "../../components/ScreenContainer/ScreenContainer";
import ProjectScreen from "../ProjectScreen/ProjectScreen";
import ObjectQueue from "../../components/templates/ObjectQueue";
import TableHeader from "../../components/table/TableHeader";

class ProjectsScreen extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ObjectQueue
                {...this.props}
                title="My Projects"
                fetchResources={(user) => this.props.activeBranch.fetchProjects(user)}
                header={(
                    <TableHeader>
                        <TableData className="medium">ID</TableData>
                        <TableData>Customer</TableData>
                        <TableData>Phase</TableData>
                        <TableData>Contract Amount</TableData>
                        <TableData className="medium">System Size</TableData>
                    </TableHeader>
                )}
                platform={this.props.platform}
                renderRecord={(project) => (
                    <>
                        <TableData className="medium">{project.id}</TableData>
                        <TableData>{project.name}</TableData>
                        <TableData>{project.phase}</TableData>
                        <TableData>
                            {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD"
                            }).format(project.contractPrice)}
                        </TableData>
                        <TableData className="medium">{project.systemSize/1000} kW</TableData>
                    </>
                )}
            />
        );
    }
}

export default ProjectsScreen;
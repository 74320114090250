import { Component } from "react";
import "./AddressField.css";
import FormField from "../FormField";
import FieldLabel from "../FieldLabel";


class AddressField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDefault: props.default !== undefined && props.default !== null && props.default !== "",
            predictionsLoading: false,
            predictions: [],
            value: ""
        };
        this.autocomplete = new window.google.maps.places.AutocompleteService();
    }

    change(value) {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    handleChange(event) {
        this.setState({
            value: event.target.value
        });
        if (event.target.value.length > 3) {
            this.getPredictions(event.target.value);
        }
        this.change(event.target.value);
    }

    hasPredictions() {
        return !this.state.predictionsLoading && this.state.predictions.length > 0;
    }

    getPredictions(value) {
        this.setState({ predictionsLoading: true });
        return this.autocomplete.getPlacePredictions({
            input: encodeURI(value)
        }, (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                const filtered = [];
                for (const prediction of predictions) {
                    if (prediction.place_id !== undefined && prediction.place_id !== null && prediction.place_id !== "") {
                        filtered.push(prediction);
                    }
                }
                this.setState({
                    predictions: filtered,
                    predictionsLoading: false
                });
            } else {
                this.setState({
                    predictions: [],
                    predictionsLoading: false
                });
                if (status !== window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
                    throw new Error(`The autocompletion query request failed with a status of ${status}.`);
                }
            }
        });
    }

    selectPrediction(prediction) {
        this.setState({
            value: prediction.description,
            predictions: []
        });
        this.change(prediction.description);
    }

    hideDefault() {
        this.setState({
            showDefault: false
        });
    }

    render() {
        return (
            <FormField error={this.props.error}>
                <FieldLabel text={this.props.label} />
                <div className="AddressField">
                    <div className="AddressField-container">
                        <div className="AddressField-input-container">
                            {this.state.showDefault ? (
                                <div className="AddressField-default">
                                    <div className="AddressField-default-value">
                                        {this.props.default}
                                    </div>
                                    <div className="AddressField-change-button" onClick={this.hideDefault.bind(this)}>
                                        change
                                    </div>
                                </div>
                            ) : (
                                <input value={this.state.value} type="text" onChange={this.handleChange.bind(this)} placeholder={this.props.placeholder} />
                            )}
                        </div>
                        <div className={"AddressField-predictions-container" + (this.hasPredictions() ? " showing" : "")}>
                            {this.state.predictions.map((prediction, index) => (
                                <div className="AddressField-prediction-container" onClick={() => this.selectPrediction(prediction)} key={index}>
                                    <div className="AddressField-prediction-text">
                                        {prediction.description}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </FormField>
        );
    }
}

export default AddressField;
import ProjectSchema from "../../ProjectSchema";


class SalemProjectSchema extends ProjectSchema {
    constructor(podioApi) {
        super(podioApi, 26531624);
        this.addField("name", 228346822, "title");
        this.addField("email", 228346824, "email");
        this.addField("phone", 228346823, "phone");
        this.addField("address", 228346829, "address");
        this.addField("stage", 228346837, "project-stage");
        this.addField("status", 228346834, "category-2");
        this.addField("panel", 228346848, "panel-type");
        this.addField("panelCount", 228346850, "of-panels");
        this.addField("systemSize", 228346851, "system-size");
        this.addField("price", 228346854, "loan-amount");
        this.addField("consultant", 228346832, "consultant");
    }
}

export default SalemProjectSchema;
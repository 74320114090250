import FieldNotFoundError from "../errors/FieldNotFoundError";

class PodioItemParser {
    constructor(item) {
        this.item = item;
    }

    itemHasField(fieldId) {
        for (const field of this.item.fields) {
            if (fieldId === field.field_id) {
                return true;
            }
        }
        return false;
    }

    getItemContactInformationField(identifier) {
        const field = this.getItemField(identifier);
        return field.values[0].value;
    }

    getItemDateField(identifier) {
        const field = this.getItemField(identifier);
        return new Date(field.values[0].start.replace(" ", "T"));
    }

    getItemAddressField(identifier) {
        const field = this.getItemField(identifier);
        return field.values[0];
    }

    getItemSelectionField(identifier) {
        const field = this.getItemField(identifier);
        return field.values[0].value.text;
    }

    getItemCurrencyField(identifier) {
        const field = this.getItemField(identifier);
        if (field.values.length > 0) {
            return parseFloat(field.values[0].value);
        }
    }

    getItemNumberField(identifier) {
        const field = this.getItemField(identifier);
        if (field.values.length > 0) {
            return field.values[0].value;
        }
    }

    getItemTextField(identifier) {
        const field = this.getItemField(identifier);
        if (field.values.length > 0) {
            return field.values[0].value;
        }
    }

    getItemImageField(identifier) {
        const field = this.getItemField(identifier);
        if (field.values.length > 0) {
            const files = [];
            for (const image of field.values) {
                files.push(image.value);
            }
            return files;
        }
    }

    getItemField(fieldId) {
        for (const field of this.item.fields) {
            if (fieldId === field.field_id) {
                return field;
            }
        }
        throw new FieldNotFoundError(`There is not field with an id of ${fieldId} on the ${this.item.item_id} (${this.item.title}) item.`);
    }
}

export default PodioItemParser;
import { Component } from "react";
import SelectionField from "../fields/SelectionField/SelectionField";
import SelectionOption from "../fields/SelectionField/SelectionOption";
import RadioField from "../fields/RadioField/RadioField";
import RadioOption from "../fields/RadioField/RadioOption";
import NestedFieldGroup from "../NestedFieldGroup";
import SingleLineTextField from "../fields/SingleLineTextField/SingleLineTextField";
import MultiLineTextField from "../fields/MultiLineTextField/MultiLineTextField";
import InvalidFormDataError from "../errors/FormValidationError";
import "./SubmitProjectForm.css";
import ErrorAlert from "../ErrorAlert/ErrorAlert";
import MultipleFileField from "../fields/MultipleFileField/MultipleFileField";
import PodioItemAttachmentUploader from "../../../storage/podio/PodioItemAttachmentUploader";
import NumericField from "../fields/NumericField/NumericField";

class SubmitProjectForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isSuccess: false,
            error: null,
            uploads: {
                satelliteImagesOfTrees: {
                    loading: false,
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (this.state.uploads.satelliteImagesOfTrees.loading) {
                            return "Please wait until all the files have been uploaded before submitting";
                        }
                        if (value === null && this.state.conditionals.hasTreeRemoval.value) {
                            return "Satellite images must be uploaded for the trees being removed";
                        }
                        return null;
                    }
                },
                groundImagesOfTrees: {
                    loading: false,
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (this.state.uploads.groundImagesOfTrees.loading) {
                            return "Please wait until all the files have been uploaded before submitting";
                        }
                        if (value === null && this.state.conditionals.hasTreeRemoval.value) {
                            return "Images of the trees being removed must be uploaded";
                        }
                        return null;
                    }
                },
                utilityBill: {
                    loading: false,
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (this.state.uploads.utilityBill.loading) {
                            return "Please wait until all the files have been uploaded before submitting";
                        }
                        return null;
                    }
                },
                proposalDesign: {
                    loading: false,
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (this.state.uploads.proposalDesign.loading) {
                            return "Please wait until all the files have been uploaded before submitting";
                        } else if (value === null) {
                            return "An image of the proposal design must be uploaded."
                        }
                        return null;
                    }
                },
                proposalPricing: {
                    loading: false,
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (this.state.uploads.proposalPricing.loading) {
                            return "Please wait until all the files have been uploaded before submitting";
                        } else if (value === null) {
                            return "An image of the proposal pricing table must be uploaded."
                        }
                        return null;
                    }
                },
                proposalAdders: {
                    loading: false,
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (this.state.uploads.proposalAdders.loading) {
                            return "Please wait until all the files have been uploaded before submitting";
                        } else if (value === null) {
                            return "An image of the proposal adders list must be uploaded."
                        }
                        return null;
                    }
                },
            },
            conditionals: {
                hasHoa: {
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (value === null) {
                            return "This field is required.";
                        }
                        return null;
                    }
                },
                hasSpecialAccess: {
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (value === null) {
                            return "This field is required.";
                        }
                        return null;
                    }
                },
                hasTreeRemoval: {
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (value === null) {
                            return "This field is required";
                        }
                        return null;
                    }
                },
                isReferral: {
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (value === null) {
                            return "This field is required";
                        }
                        return null;
                    }
                }
            },
            fields: {
                hoaName: {
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (this.state.conditionals.hasHoa.value) {
                            if (value === null) {
                                return "This field cannot be empty";
                            }
                        }
                        return null;
                    }
                },
                hoaEmail: {
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (this.state.conditionals.hasHoa.value) {
                            if (value === null && this.state.fields.hoaPhone.value === null) {
                                return "An HOA contact email or phone is required";
                            }
                        }
                        return null;
                    }
                },
                hoaPhone: {
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (this.state.conditionals.hasHoa.value) {
                            if (value === null && this.state.fields.hoaEmail.value === null) {
                                return "An HOA contact email or phone is required";
                            }
                        }
                        return null;
                    }
                },
                propertyAccessInstructions: {
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (this.state.conditionals.hasSpecialAccess.value) {
                            if (value === null) {
                                return "Please enter some instructions for accessing the property";
                            }
                        }
                        return null;
                    }
                },
                numberOfTreeRemovals: {
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (this.state.conditionals.hasTreeRemoval.value) {
                            if (value === null) {
                                return "Please select the number of trees being removed";
                            }
                        }
                        return null;
                    }
                },
                partyResponsibleForTreeRemoval: {
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (this.state.conditionals.hasTreeRemoval.value) {
                            if (value === null) {
                                return "Please select who will be removing the trees";
                            }
                        }
                        return null;
                    }
                },
                customerWantsRoofBid: {
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (value === null) {
                            return "This field is required."
                        }
                        return null;
                    }
                },
                customerWantsMorePanels: {
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (value === null) {
                            return "This field is required."
                        }
                        return null;
                    }
                },
                numberOfAdditionalPanels: {
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (this.state.fields.customerWantsMorePanels.value === "Yes") {
                            if (value === null) {
                                return "Please enter how many panels the customer would like if they can fit.";
                            }
                        }
                        return null;
                    }
                },
                referredBy: {
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (this.state.conditionals.isReferral.value) {
                            if (value === null) {
                                return `Please enter the name of the customer who referred ${this.props.opportunity.name}`
                            }
                        }
                        return null;
                    }
                },
                customerPriority: {
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (value === null) {
                            return "This field is required";
                        }
                        return null;
                    }
                },
                customerPriorityExplanation: {
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (this.state.fields.customerPriority.value === "Aesthetics") {
                            if (value === null) {
                                return "Please explain how aesthetics are more important to the customer";
                            }
                        }
                        return null;
                    }
                },
                numberOfStories: {
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (value === null) {
                            return "This field is required";
                        }
                        return null
                    }
                },
                roofAccess: {
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (this.state.fields.numberOfStories.value > 1) {
                            if (value === null) {
                                return "This field is required";
                            }
                        }
                        return null;
                    }
                },
                specialRequests: {
                    value: null,
                    error: null,
                    findError: (value) => null
                },
                nickname: {
                    value: null,
                    error: null,
                    findError: (value) => {
                        if (value === null) {
                            return "This field is required";
                        }
                        return null;
                    }
                }
            }
        };
        this.fileUploader = new PodioItemAttachmentUploader(this.props.podio, this.props.opportunity.id);
    }

    updateConditional(key, value) {
        const conditionals = this.state.conditionals;
        conditionals[key].value = value;
        if (conditionals[key].findError !== undefined) {
            conditionals[key].error = conditionals[key].findError(value);
        } else {
            conditionals[key].error = null;
        }
        this.setState({ conditionals });
    }

    updateField(key, value) {
        const fields = this.state.fields;
        fields[key].value = value;
        if (fields[key].findError !== undefined) {
            fields[key].error = fields[key].findError(value);
        } else {
            fields[key].error = null;
        }
        this.setState({ fields });
    }

    submit() {
        this.props.startLoader();
        const validation = this.validate();
        if (validation.isValid) {
            this.props.branch.submitProject(this.props.opportunity.id, this.getFields())
                .then(() => this.setSuccessful())
                .catch(this.setError.bind(this))
        } else {
            this.setError(validation.error);
        }
    }

    validate() {
        const validation = { isValid: true, error: null };
        const fields = this.state.fields;
        for (const key in fields) {
            if (fields[key].findError !== undefined) {
                fields[key].error = fields[key].findError(fields[key].value);
                if (fields[key].error !== null && validation.isValid) {
                    validation.isValid = false;
                    validation.error = new Error("Please fix the issues on the fields above.");
                }
            }
        }
        const conditionals = this.state.conditionals;
        for (const key in conditionals) {
            if (conditionals[key].findError !== undefined) {
                conditionals[key].error = conditionals[key].findError(conditionals[key].value);
                if (conditionals[key].error !== null && validation.isValid) {
                    validation.isValid = false;
                    validation.error = new Error("Please fix the issues on the fields above.");
                }
            }
        }
        const uploads = this.state.uploads;
        for (const key in uploads) {
            if (uploads[key].findError !== undefined) {
                uploads[key].error = uploads[key].findError(uploads[key].value);
                if (uploads[key].error !== null && validation.isValid) {
                    validation.isValid = false;
                    validation.error = new Error("Please fix the issues on the fields above.");
                }
            }
        }
        this.setState({ fields, conditionals, uploads });
        return validation;
    }

    getFields() {
        const fields = {};
        for (const key in this.state.fields) {
            if (this.state.fields[key].value !== null) {
                fields[key] = this.state.fields[key].value;
            }
        }
        return fields;
    }

    startLoader() {
        this.props.startLoader();
        this.setState({
            isLoading: true,
            isSuccess: false,
            error: null
        });
    }

    setSuccessful() {
        if (this.props.onSubmitted) {
            this.props.onSubmitted();
        }
    }

    setError(error) {
        this.setState({
            error: error instanceof InvalidFormDataError ? error.toHtml() : error.message
        });
        this.props.stopLoader();
    }

    isError() {
        return this.state.error !== null;
    }

    clearError() {
        this.setState({
            error: null
        });
    }

    startUpload(key) {
        const uploads = this.state.uploads;
        uploads[key].loading = true;
        this.setState({ uploads });
    }

    completeUpload(key, value) {
        const uploads = this.state.uploads;
        uploads[key].loading = false;
        uploads[key].value = value;
        uploads[key].error = null;
        this.setState({ uploads });
    }

    setUploadError(key, error) {
        const uploads = this.state.uploads;
        uploads[key].loading = false;
        uploads[key].error = error;
        this.setState({ uploads });
    }

    render() {
        const { opportunity } = this.props;
        return (
            <div className="SubmitProjectForm">
                <div className="SubmitProjectForm-container">
                    <RadioField
                        label="Is there an HOA?"
                        error={this.state.conditionals.hasHoa.error}
                        onChange={(value) => this.updateConditional("hasHoa", value)}
                    >
                        <RadioOption value={true}>Yes</RadioOption>
                        <RadioOption value={false}>No</RadioOption>
                    </RadioField>
                    {this.state.conditionals.hasHoa.value && (
                        <NestedFieldGroup>
                            <SingleLineTextField
                                label="HOA Name"
                                error={this.state.fields.hoaName.error}
                                placeholder={"Enter the name of the HOA..."}
                                onChange={(value) => this.updateField("hoaName", value)}
                            />
                            <SingleLineTextField
                                label="HOA Contact Email"
                                error={this.state.fields.hoaEmail.error}
                                placeholder={"Enter the email of the point of contact for the HOA..."}
                                onChange={(value) => this.updateField("hoaEmail", value)}
                            />
                            <SingleLineTextField
                                label="HOA Contact Phone"
                                error={this.state.fields.hoaEmail.error}
                                placeholder={"Enter the phone number of the point of contact for the HOA..."}
                                onChange={(value) => this.updateField("hoaPhone", value)}
                            />
                        </NestedFieldGroup>
                    )}
                    <RadioField
                        label="Does the property have special access instructions (locked gates, restricted parking, etc.)?"
                        error={this.state.conditionals.hasSpecialAccess.error}
                        onChange={(value) => this.updateConditional("hasSpecialAccess", value)}
                    >
                        <RadioOption value={true}>Yes</RadioOption>
                        <RadioOption value={false}>No</RadioOption>
                    </RadioField>
                    {this.state.conditionals.hasSpecialAccess.value && (
                        <NestedFieldGroup>
                            <MultiLineTextField
                                label="Property Access Instructions"
                                placeholder="Enter the special instructions for accessing the property..."
                                error={this.state.fields.propertyAccessInstructions.error}
                                onChange={(value) => this.updateField("propertyAccessInstructions", value)}
                            />
                        </NestedFieldGroup>
                    )}
                    <RadioField
                        label="Are Trees Being Removed?"
                        error={this.state.conditionals.hasTreeRemoval.error}
                        onChange={(value) => this.updateConditional("hasTreeRemoval", value)}
                    >
                        <RadioOption value={true}>Yes</RadioOption>
                        <RadioOption value={false}>No</RadioOption>
                    </RadioField>
                    {this.state.conditionals.hasTreeRemoval.value && (
                        <NestedFieldGroup>
                            <SelectionField
                                label="How many trees are being removed?"
                                placeholder="Select the number of trees that will be removed..."
                                error={this.state.fields.numberOfTreeRemovals.error}
                                onChange={(value) => this.updateField("numberOfTreeRemovals", value)}
                            >
                                <SelectionOption value={1}>1</SelectionOption>
                                <SelectionOption value={2}>2</SelectionOption>
                                <SelectionOption value={3}>3</SelectionOption>
                            </SelectionField>
                            <SelectionField
                                label={`Who will be removing the tree${(this.state.fields.numberOfTreeRemovals.value > 1) ? "s" : ""}?`}
                                placeholder={`Select who is responsible for removing the tree${(this.state.fields.numberOfTreeRemovals.value > 1 ? "s" : "")}...`}
                                error={this.state.fields.partyResponsibleForTreeRemoval.error}
                                onChange={(value) => this.updateField("partyResponsibleForTreeRemoval", value)}
                            >
                                <SelectionOption value="Purelight Power">Purelight Power</SelectionOption>
                                <SelectionOption value="Customer">Customer</SelectionOption>
                            </SelectionField>
                            <MultipleFileField
                                label="Upload satellite image(s) of tree(s) being removed"
                                error={this.state.uploads.satelliteImagesOfTrees.error}
                                uploader={this.fileUploader}
                                onStartLoader={() => this.startUpload("satelliteImagesOfTrees")}
                                onChange={(value) => this.completeUpload("satelliteImagesOfTrees", value)}
                                onError={(error) => this.setUploadError("satelliteImagesOfTrees", error)}
                            />
                            <MultipleFileField
                                label="Upload ground image(s) of tree(s) being removed"
                                error={this.state.uploads.groundImagesOfTrees.error}
                                uploader={this.fileUploader}
                                onStartLoader={() => this.startUpload("groundImagesOfTrees")}
                                onChange={(value) => this.completeUpload("groundImagesOfTrees", value)}
                                onError={(error) => this.setUploadError("groundImagesOfTrees", error)}
                            />
                        </NestedFieldGroup>
                    )}
                    <RadioField
                        label="Does the customer want a roof bid?"
                        error={this.state.fields.customerWantsRoofBid.error}
                        onChange={(value) => this.updateField("customerWantsRoofBid", value)}
                    >
                        <RadioOption value="Yes">Yes</RadioOption>
                        <RadioOption value="No">No</RadioOption>
                    </RadioField>
                    <RadioField
                        label="Does the customer want more panels if they can fit?"
                        error={this.state.fields.customerWantsMorePanels.error}
                        onChange={(value) => this.updateField("customerWantsMorePanels", value)}
                    >
                        <RadioOption value="Yes">Yes</RadioOption>
                        <RadioOption value="No">No</RadioOption>
                    </RadioField>
                    {this.state.fields.customerWantsMorePanels.value === "Yes" && (
                        <NestedFieldGroup>
                            <NumericField
                                label="How many extra panels does the customer want?"
                                error={this.state.fields.numberOfAdditionalPanels.error}
                                placeholder="Enter the number of extra panels that the customer wants..."
                                onChange={(value) => this.updateField("numberOfAdditionalPanels", value)}
                            />
                        </NestedFieldGroup>
                    )}
                    <RadioField
                        label="Is this a customer referral?"
                        error={this.state.conditionals.isReferral.error}
                        onChange={(value) => this.updateConditional("isReferral", value)}
                    >
                        <RadioOption value={true}>Yes</RadioOption>
                        <RadioOption value={false}>No</RadioOption>
                    </RadioField>
                    {this.state.conditionals.isReferral.value && (
                        <NestedFieldGroup>
                            <SingleLineTextField
                                label="Who referred this customer?"
                                placeholder={`Enter the name of the customer who referred ${opportunity.name}...`}
                                error={this.state.fields.referredBy.error}
                                onChange={(value) => this.updateField("referredBy", value)}
                            />
                        </NestedFieldGroup>
                    )}
                    <SelectionField
                        label="Which is more important to the customer?"
                        placeholder="Select the option that is more important to the customer..."
                        error={this.state.fields.customerPriority.error}
                        onChange={(value) => this.updateField("customerPriority", value)}
                    >
                        <SelectionOption value="Aesthetics">Aesthetics</SelectionOption>
                        <SelectionOption value="Production">Production</SelectionOption>
                    </SelectionField>
                    {this.state.fields.customerPriority.value === "Aesthetics" && (
                        <NestedFieldGroup>
                            <MultiLineTextField
                                label="Please explain how aesthetics are important to the customer"
                                placeholder="Enter an explanation..."
                                error={this.state.fields.customerPriorityExplanation.error}
                                onChange={(value) => this.updateField("customerPriorityExplanation", value)}
                            />
                        </NestedFieldGroup>
                    )}
                    <SelectionField
                        label="How many stories does the building have?"
                        placeholder="Select the number of stories in the building..."
                        error={this.state.fields.numberOfStories.error}
                        onChange={(value) => this.updateField("numberOfStories", value)}
                    >
                        <SelectionOption value={1}>1</SelectionOption>
                        <SelectionOption value={2}>2</SelectionOption>
                        <SelectionOption value={3}>3</SelectionOption>
                    </SelectionField>
                    {this.state.fields.numberOfStories.value > 1 && (
                        <NestedFieldGroup>
                            <RadioField
                                label="Can you access the top story roof with a 1-story ladder?"
                                error={this.state.fields.roofAccess.error}
                                onChange={(value) => this.updateField("roofAccess", value)}
                            >
                                <RadioOption value="Yes">Yes</RadioOption>
                                <RadioOption value="No">No</RadioOption>
                            </RadioField>
                        </NestedFieldGroup>
                    )}
                    <MultipleFileField
                        label="Utility Bill(s)"
                        error={this.state.uploads.utilityBill.error}
                        uploader={this.fileUploader}
                        onStartLoader={() => this.startUpload("utilityBill")}
                        onChange={(value) => this.completeUpload("utilityBill", value)}
                        onError={(error) => this.setUploadError("utilityBill", error)}
                    />
                    <MultipleFileField
                        label="Upload a screenshot of the design from the Aurora proposal."
                        error={this.state.uploads.proposalDesign.error}
                        uploader={this.fileUploader}
                        onStartLoader={() => this.startUpload("proposalDesign")}
                        onChange={(value) => this.completeUpload("proposalDesign", value)}
                        onError={(error) => this.setUploadError("proposalDesign", error)}
                    />
                    <MultipleFileField
                        label="Upload a screenshot of the pricing table from the Aurora proposal."
                        error={this.state.uploads.proposalPricing.error}
                        uploader={this.fileUploader}
                        onStartLoader={() => this.startUpload("proposalPricing")}
                        onChange={(value) => this.completeUpload("proposalPricing", value)}
                        onError={(error) => this.setUploadError("proposalPricing", error)}
                    />
                    <MultipleFileField
                        label="Upload a screenshot of the adders list from the Aurora proposal."
                        error={this.state.uploads.proposalAdders.error}
                        uploader={this.fileUploader}
                        onStartLoader={() => this.startUpload("proposalAdders")}
                        onChange={(value) => this.completeUpload("proposalAdders", value)}
                        onError={(error) => this.setUploadError("proposalAdders", error)}
                    />
                    <MultiLineTextField
                        label="Please enter any special notes (for site surveyor, designer, project manager, etc.)."
                        placeholder="Enter your notes..."
                        error={this.state.fields.specialRequests.error}
                        onChange={(value) => this.updateField("specialRequests", value)}
                    />
                    <SingleLineTextField
                        label="Project Name"
                        placeholder="Enter a memorable name for the project..."
                        error={this.state.fields.nickname.error}
                        onChange={(value) => this.updateField("nickname", value)}
                    />
                    {!this.state.isLoading && this.isError() ? (
                        <ErrorAlert onClear={this.clearError.bind(this)}>
                            <div dangerouslySetInnerHTML={{__html: this.state.error}}/>
                        </ErrorAlert>
                    ) : (
                        <button
                            className="dark-button large-button full-button submit-button"
                            onClick={this.submit.bind(this)}
                        >
                            <strong>Submit</strong>
                        </button>
                    )}
                </div>
            </div>
        );
    }
}

export default SubmitProjectForm;
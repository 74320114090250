import axios from "axios";


class PodioSingleFileUploader {
    constructor(api) {
        this.api = api;
    }

    upload(file, name = null) {
        return this.getAccessToken()
            .then((token) => this.sendFile(file, name, token));
    }

    sendFile(file, name, token) {
        const form = this.createForm(file, name);
        return axios.post("https://api.podio.com/file/", form, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${token}`
            }
        })
        .then((response) => this.processUploadResponse(response));
    }

    createForm(file, name) {
        const form = new FormData();
        form.append("source", file);
        form.append("filename", name);
        return form;
    }

    processUploadResponse(response) {
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(`The file upload request failed with a status of ${response.status}.`);
        }
    }

    getAccessToken() {
        return this.api.isAuthenticated().then(() => {
            return this.api.authObject.accessToken;
        }).catch(() => {
            return Promise.reject(new Error("The Podio api is not currently authenticated."));
        });
    }
}

export default PodioSingleFileUploader;
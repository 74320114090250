import { Component } from "react";
import Profile from "../../components/profile/Profile/Profile";
import ProfileMetadata from "../../components/profile/ProfileMetadata/ProfileMetadata";
import ProfileHeader from "../../components/profile/ProfileHeader/ProfileHeader";
import "./ProjectScreen.css";
import ProfileModule from "../../components/profile/ProfileModule/ProfileModule";
import ProfileField from "../../components/profile/ProfileField/ProfileField";
import ProfileForm from "../../components/profile/ProfileForm/ProfileForm";
import Table from "../../components/table/Table";
import TableRow from "../../components/table/TableRow";
import TableData from "../../components/table/TableData";
import ProfileLink from "../../components/profile/ProfileLink/ProfileLink";
import ProfileButton from "../../components/profile/ProfileButton/ProfileButton";
import ScreenContainer from "../../components/ScreenContainer/ScreenContainer";
import RequestScreen from "../RequestScreen/RequestScreen";

class ProjectScreen extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.stopLoader();
    }

    render() {
        const { project } = this.props;
        return (
            <ScreenContainer
                showPage={this.props.showPage}
                platform={this.props.platform}
            >
                <Profile>
                    <ProfileMetadata title="Details">
                        <ProfileHeader
                            title={project.name}
                            subtitle={`Signed ${new Intl.DateTimeFormat("en-US", { dateStyle: "short" }).format(project.signedOn)}`}
                            footer={project.phase}
                        />
                        <ProfileModule>
                            {project.panel && (
                                <ProfileField label="Panel">{project.panel}</ProfileField>
                            )}
                            {project.panelCount && (
                                <ProfileField label="Panel Count">
                                    {new Intl.NumberFormat("en-US").format(project.panelCount)} panels
                                </ProfileField>
                            )}
                            {project.systemSize && (
                                <ProfileField label="System Size">
                                    {new Intl.NumberFormat("en-US").format(project.systemSize)} watts
                                </ProfileField>
                            )}
                            <ProfileField label="Contract Price">
                                {new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: "USD"
                                }).format(project.contractPrice)}
                            </ProfileField>
                        </ProfileModule>
                        <ProfileButton onClick={() => this.props.showScreen(<RequestScreen project={project} branch={this.props.branch}/>)}>
                            Make a Request
                        </ProfileButton>
                        <ProfileLink type="blue" href={project.externalUrl}>
                            Podio
                        </ProfileLink>
                    </ProfileMetadata>
                    <ProfileForm>
                        <Table title="Issues">
                            {project.issues && project.issues.map((issue, index) => (
                                <TableRow key={index}>
                                    <TableData>{issue.id}</TableData>
                                    <TableData>{issue.name}</TableData>
                                </TableRow>
                            ))}
                        </Table>
                    </ProfileForm>
                </Profile>
            </ScreenContainer>
        );
    }
}

export default ProjectScreen;
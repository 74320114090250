import { Component } from "react";
import DateField from "../DateField/DateField";
import FieldLabel from "../FieldLabel";
import FormField from "../FormField";
import "./DateTimeField.css";

class DateTimeField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: null,
            dateIsValid: true,
            hours: null,
            hourIsValid: true,
            minutes: null,
            minuteIsValid: true,
            period: "AM",
            periodIsOpen: false
        };
    }

    open() {
        this.setState({
            isOpen: true
        });
    }

    close() {
        this.setState({
            isOpen: false
        });
    }

    toggle() {
        if (this.state.isOpen) {
            this.close();
        } else {
            this.open();
        }
    }

    changeValue(date, hours, minutes, period) {
        if (this.props.onChange) {
            if (this.isValid(date, hours, minutes, period)) {
                const value = new Date(date);
                value.setHours(this.getHours(hours, period));
                value.setMinutes(minutes);
                value.setSeconds(0);
                value.setMilliseconds(0);
                this.props.onChange(value);
            }
        }
    }

    getHours(hours, period) {
        if (period === "PM" && hours !== 12) {
            return hours + 12;
        } else if (period === "AM" && hours === 12) {
            return 0;
        }
        return hours;
    }

    isValid(date, hour, minute) {
        return this.isValidDate(date, hour, minute);
    }

    setDate(date) {
        if (this.isValidDate(date)) {
            this.setState({
                date: date,
                dateIsValid: true
            });
            this.changeValue(date, this.state.hours, this.state.minutes, this.state.period);
        } else {
            this.setState({
                date: date,
                dateIsValid: false
            });
            if (this.props.onChange) {
                this.props.onChange(null);
            }
        }
    }

    isValidDate(value) {
        return value !== null;
    }

    handleHoursChange(event) {
        const value = parseInt(event.target.value);
        if (this.isValidHour(value)) {
            this.setState({
                hours: value,
                hourIsValid: true
            });
            this.changeValue(this.state.date, value, this.state.minutes, this.state.period);
        }
    }

    isValidHour(value) {
        return !isNaN(value) && value !== null && value >= 0 && value <= 12;
    }

    handleMinutesChange(event) {
        const value = parseInt(event.target.value);
        if (this.isValidMinute(value)) {
            this.setState({
                minutes: value,
                minuteIsValid: true
            });
            this.changeValue(this.state.date, this.state.hours, value, this.state.period);
        }
    }

    isValidMinute(value) {
        return !isNaN(value) && value !== null && value >= 0 && value <= 59;
    }

    setPeriod(period) {
        this.setState({
            period,
            periodIsOpen: false
        });
        this.changeValue(this.state.date, this.state.hours, this.state.minutes, period);
    }

    showPeriodOptions() {
        this.setState({
            periodIsOpen: true
        });
    }

    getHoursAsText() {
        if (this.state.hours === null || isNaN(this.state.hours)) {
            return "";
        } else {
            if (this.state.hours < 10) {
                return "0" + this.state.hours.toString();
            }
            return this.state.hours.toString();
        }
    }

    getMinutesAsText() {
        if (this.state.minutes === null || isNaN(this.state.minutes)) {
            return "";
        } else {
            if (this.state.minutes < 10) {
                return "0" + this.state.minutes.toString();
            }
            return this.state.minutes.toString();
        }
    }

    render() {
        return (
            <FormField error={this.props.error}>
                <FieldLabel text={this.props.label}/>
                <div className="DateTimeField">
                    <div className="DateTimeField-container">
                        <DateField onChange={this.setDate.bind(this)} placeholder={this.props.placeholder} />
                        <div className="DateTimeField-time">
                            <div className={"DateTimeField-hour" + (this.state.hourIsValid ? "" : " invalid")}>
                                <input type="text" value={this.getHoursAsText()} onChange={this.handleHoursChange.bind(this)} placeholder="10" inputMode="decimal" />
                            </div>
                            <div className={"DateTimeField-minute" + (this.state.minuteIsValid ? "" : " invalid")}>
                                <input type="text" value={this.getMinutesAsText()} onChange={this.handleMinutesChange.bind(this)} placeholder="30" inputMode="decimal" />
                            </div>
                            <div className={"DateTimeField-period" + (this.state.periodIsOpen ? " open" : "")}>
                                <div className="DateTimeField-period-value" onClick={this.showPeriodOptions.bind(this)}>
                                    {this.state.period}
                                </div>
                                <div className="DateTimeField-period-options">
                                    <div className="DateTimeField-period-option" onClick={() => this.setPeriod("AM")}>AM</div>
                                    <div className="DateTimeField-period-option" onClick={() => this.setPeriod("PM")}>PM</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </FormField>
        );
    }
}

export default DateTimeField;
import ProjectSchema from "../../ProjectSchema";


class MedfordProjectSchema extends ProjectSchema {
    constructor(podioApi) {
        super(podioApi, 23024488);
        this.addField("name", 191755790, "title");
        this.addField("email", 191933503, "customer-email");
        this.addField("phone", 191933502, "customer-phone");
        this.addField("address", 191933501, "customer-address");
        this.addField("stage", 214681875, "project-stage-2");
        this.addField("status", 191755793, "status");
        this.addField("panel", 204872842, "panel-type-3");
        this.addField("panelCount", 204872839, "of-modules");
        this.addField("systemSize", 204872840, "system-size-2");
        this.addField("price", 204872843, "loan-amount");
        this.addField("consultant", 191755792, "responsible");
    }
}

export default MedfordProjectSchema;
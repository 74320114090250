import React from "react";
import "./style.css";

function GoogleCalendarSignInLink({ show, onClick }) {
    return (
        <div
            style={{ display: show ? "block" : "none" }}
            className="GoogleCalendarSignInLink"
        >
            You must{" "}
            <span className="primary-link" onClick={onClick}>
                authorize Google Calendar
            </span>{" "}
            in order to schedule an appointment.
        </div>
    );
}

export default GoogleCalendarSignInLink;

import { Component } from "react";
import "./ProfileModule.css";

class ProfileModule extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="ProfileModule">
                <div className="ProfileModule-container">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default ProfileModule;
class Agent {
    constructor() {
        this.id = null;
        this.name = null;
        this.email = null;
        this.phone = null;
        this.avatarUrl = null;
        this.avatarThumbnailUrl = null;
        this.externalUrl = null;
    }
}

export default Agent;
import LeadConverter from "../../../converters/LeadConverter";
import OpportunityConverter from "../../../converters/OpportunityConverter";
import ProjectConverter from "../../../converters/ProjectConverter";
import PodioBranch from "../PodioBranch";
import SalemLeadSchema from "../schema/workspaces/salem/SalemLeadSchema";
import SalemOpportunitySchema from "../schema/workspaces/salem/SalemOpportunitySchema";
import SalemProjectSchema from "../schema/workspaces/salem/SalemProjectSchema";


class SalemBranch extends PodioBranch {
    constructor(podioApi) {
        super(podioApi);
        this.id = 4;
        this.name = "Salem";
        this.leadSchema = new SalemLeadSchema(podioApi);
        this.leadConverter = new LeadConverter(this.leadSchema);
        this.opportunitySchema = new SalemOpportunitySchema(podioApi);
        this.opportunityConverter = new OpportunityConverter(this.opportunitySchema);
        this.projectSchema = new SalemProjectSchema(podioApi);
        this.projectConverter = new ProjectConverter(this.projectSchema);
    }
}

export default SalemBranch;
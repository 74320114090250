import { Component } from "react";
import "./FieldLabel.css";

class FieldLabel extends Component {
    render() {
        return (
            <div className="FieldLabel">
                {this.props.text}
            </div>
        );
    }
}

export default FieldLabel;
import Branch from "../../Branch";
import axios from "axios";


class PodioBranch extends Branch {
    constructor(podioApi) {
        super();
        this.api = podioApi;
        this.leadSchema = null;
        this.leadConverter = null;
        this.opportunitySchema = null;
        this.opportunityConverter = null;
        this.projectSchema = null;
        this.projectConverter = null;
    }

    fetchLead(id) {
        return this.api.request("GET", `/item/${id}`)
            .then((response) => this.leadConverter.convert(response));
    }

    fetchLeads(user) {
        return this.createLeadFilters(user)
            .then((filters) => this.api.request("POST", `/item/app/${this.leadSchema.id}/filter`, { filters }))
            .then((response) => ({
                count: response.filtered,
                page: 1,
                records: this.leadConverter.convertMany(response.items)
            }));
    }

    fetchStaleLeads(user) {
        return this.leadSchema.translateFields({
            consultants: user.profile.profile_id,
            appointment: { to: "-3dr" },
            stage: ["1T Scheduled", "1T Rescheduled"],
            status: ["Active"]
        }).then((filters) => this.api.request(
            "POST",
            `/item/app/${this.leadSchema.id}/filter`,
            { filters }
        )).then((response) => ({
            count: response.filtered,
            page: 1,
            records: this.leadConverter.convertMany(response.items)
        }));
    }

    fetchSelfGens(user) {
        return this.leadSchema.translateFields({
            consultants: user.profile.profile_id,
            channel: [
                "[Self-Gen] Referral",
                "[Self-Gen] Knocking",
                "[Self-Gen] Flyer",
                "[Self-Gen] Event",
                "[Self-Gen] Other"
            ],
            stage: null,
        }).then((filters) => this.api.request(
            "POST",
            `/item/app/${this.leadSchema.id}/filter`,
            { filters }
        )).then((response) => ({
            count: response.filtered,
            page: 1,
            records: this.leadConverter.convertMany(response.items)
        }));
    }

    updateLeadField(lead, field, value) {
        const fieldId = this.leadSchema.getFieldId(field);
        return this.leadSchema.translateField(field, value)
            .then((values) => {
                if (["array", "object"].includes(typeof values)) {
                    return this.api.request("PUT", `/item/${lead.id}/value/${fieldId}`, values);
                } else {
                    return this.api.request("PUT", `/item/${lead.id}/value/${fieldId}`, [values]);
                }
            });
    }

    createLeadFilters(user) {
        try {
            const filters = {};
            const consultantField = this.leadSchema.getFieldId("consultants");
            filters[consultantField] = user.profile.profile_id;
            const appointmentField = this.leadSchema.getFieldId("appointment");
            filters[appointmentField] = { from: "-2dr" };
            const stageField = this.leadSchema.getFieldId("stage");
            return this.leadSchema.getOptionId("stage", "1T Scheduled")
                .then((id) => {
                    filters[stageField] = [id];
                    return this.leadSchema.getOptionId("stage", "1T Rescheduled");
                }).then((id) => {
                    filters[stageField].push(id);
                    return filters;
                });
        } catch (error) {
            return Promise.reject(error);
        }
    }

    scheduleConsultation(lead, payload) {
        return this.leadSchema.translateFields({
            ...payload,
            stage: "1T Scheduled"
        }).then((fields) => this.api.request(
            "PUT",
            `/item/${lead.id}`,
            { fields }
        ));
    }

    requestDesign(lead, requestForm) {
        requestForm["designStatus"] = "Requested";
        requestForm["stage"] = "2T Scheduled";
        return this.leadSchema.translateFields(requestForm)
            .then((fields) => this.api.request("PUT", `/item/${lead.id}`, { fields }));
    }

    requestDesignRevision(opportunity, form) {
        return this.opportunitySchema.translateFields(form)
            .then((fields) => this.api.request("PUT", `/item/${opportunity.id}`, { fields }));
    }

    updateLeadStage(lead, data) {
        return this.leadSchema.translateFields(data)
            .then((fields) => this.api.request("PUT", `/item/${lead.id}`, { fields }));
    }

    fetchPendingDesigns(user) {
        return this.leadSchema.translateFields({
            consultants: user.profile.profile_id,
            status: ["Active"],
            designStatus: ["Requested", "In-Progress", "Needs Revision", "In Home Revision"]
        }).then((filters) => this.api.request(
            "POST",
            `/item/app/${this.leadSchema.id}/filter`,
            { filters }
        )).then((response) => ({
            count: response.filtered,
            page: 1,
            records: this.leadConverter.convertMany(response.items)
        }));
    }

    uploadFiles(id, fields, schema) {
        const promises = [];
        for (const key in fields) {
            const value = fields[key];
            if (value instanceof File) {
                switch (schema.getType(key)) {
                    case "file":
                        promises.push(this.attachFile(id, value));
                        break;
                    case "image":
                        promises.push(this.uploadImage(id, schema.getFieldId(key), value));
                        break;
                    default:
                        throw new Error(`Files can only be uploaded to fields that are listed as files in the schema. ${key} is not a file.`);
                }
            }
        }
        return Promise.all(promises);
    }

    uploadFilesToLead(leadId, fields) {
        const promises = [];
        for (const key in fields) {
            const value = fields[key];
            if (value instanceof File) {
                switch (this.leadSchema.getType(key)) {
                    case "file":
                        promises.push(this.attachFile(leadId, value));
                        break;
                    case "image":
                        promises.push(this.uploadImage(leadId, this.leadSchema.getFieldId(key), value));
                        break;
                    default:
                        throw new Error(`Files can only be uploaded to fields that are listed as files in the schema. ${key} is not a file.`);
                }
            }
        }
        if (promises.length === 0) {
            return Promise.resolve();
        }
        return Promise.all(promises);
    }
    
    attachFile(itemId, file) {
        return this.sendFileToPodio(file, file.name)
            .then((response) => this.api.request("POST", `/file/${response.file_id}/attach`, {
                ref_type: "item",
                ref_id: itemId
            }))
            .catch((error) => {
                console.log(error);
                return Promise.reject(new Error(`There was an issue attaching the ${file.name} file to the Podio item ${itemId}`));
            })
    }

    uploadImage(itemId, fieldId, file) {
        return this.sendFileToPodio(file, file.name)
            .then((response) => this.api.request("PUT", `/item/${itemId}/value/${fieldId}`, [response.file_id])
                .catch((error) => {
                    console.log(error);
                    return Promise.reject(new Error(`There was an issue attaching the ${file.name} image to the Podio item ${itemId} at field ${fieldId}`));
                }));
    }

    sendFileToPodioXhr(file, name) {
        return this.getAccessToken().then((token) => new Promise((resolve, reject) => {
            const data = new FormData();
            data.append("source", file);
            data.append("filename", name);
            const xhr = new XMLHttpRequest();
            xhr.addEventListener("load", () => {
                if (xhr.status === 200 || xhr.status === 201) {
                    resolve(JSON.parse(xhr.responseText));
                } else {
                    console.log(xhr.responseText);
                    reject(new Error(`There was an issue uploading the ${name} file to Podio.`));
                }
            });
            xhr.open("POST", "https://api.podio.com/file/");
            // xhr.setRequestHeader("Content-Type", "multipart/form-data");
            xhr.setRequestHeader("Authorization", `Bearer ${token}`);
            xhr.send(data);
        }));
    }

    sendFileToPodio(file, name) {
        return this.getAccessToken().then((token) => {
            const data = new FormData();
            data.append("source", file);
            data.append("filename", name);
            return axios.post("https://api.podio.com/file/", data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${token}`
                }
            });
        }).then((response) => {
            if (response.status === 200) {
                if (response.data !== undefined) {
                    if (response.data.file_id !== undefined) {
                        return response.data;
                    }
                }
            }
            throw new Error(`There was an unexpected error when attempting to upload the ${name} file to Podio.`);
        });
    }

    getAccessToken() {
        return this.api.isAuthenticated().then(() => {
            return this.api.authObject.accessToken;
        }).catch(() => {
            return Promise.reject(new Error("The Podio api is not currently authenticated."));
        });
    }

    updateOpportunityStage(opportunity, form) {
        return this.opportunitySchema.translateFields(form)
            .then((fields) => this.api.request("PUT", `/item/${opportunity.id}`, { fields }));
    }

    updateOpportunityField(opportunity, field, value) {
        const fieldId = this.opportunitySchema.getFieldId(field);
        return this.opportunitySchema.translateField(field, value)
            .then((values) => {
                if (["array", "object"].includes(typeof values)) {
                    return this.api.request("PUT", `/item/${opportunity.id}/value/${fieldId}`, values);
                } else {
                    return this.api.request("PUT", `/item/${opportunity.id}/value/${fieldId}`, [values]);
                }
            });

    }

    submitProject(id, form) {
        form["stage"] = "Closed";
        form["submitDeal"] = "Submit";
        form["type"] = "In Home";
        form["notes"] = this.createNotesFromProjectSubmissionForm(form);
        return this.opportunitySchema.translateFields(form)
            .then((fields) => this.api.request("PUT", `/item/${id}`, { fields }));
    }

    createNotesFromProjectSubmissionForm(form) {
        let html = "<div>";
        if (form.hoaName || form.hoaEmail || form.hoaPhone) {
            html += "<p>HOA Information</p><p><ul>";
            if (form.hoaName) {
                html += `<li><b>Name</b>: ${form.hoaName}</li>`;
            }
            if (form.hoaEmail) {
                html += `<li><b>Email</b>: ${form.hoaEmail}</li>`;
            }
            if (form.hoaPhone) {
                html += `<li><b>Phone</b>: ${form.hoaPhone}</li>`;
            }
            html += "</ul></p>";
        }
        if (form.propertyAccessInstructions) {
            html += "<p><b>Property Access Instructions</b>:</p>";
            html += `<p>${form.propertyAccessInstructions}</p>`;
        }
        if (form.numberOfTreeRemovals) {
            html += "<p><b>Tree Removals</b>:</p><p><ul>";
            html += `<li><b>Number of Trees</b>: ${form.numberOfTreeRemovals}</li>`;
            if (form.partyResponsibleForTreeRemoval) {
                html += `<li><b>Responsible Party</b>: ${form.partyResponsibleForTreeRemoval}</li>`;
            }
            html += "</ul></p>";
        }
        html += `<p><b>Does the customer want a roof bid?</b> ${form.customerWantsRoofBid}</p>`;
        html += `<p><b>Does the customer want more panels if they can fit?</b> ${form.customerWantsMorePanels}</p>`;
        if (form.customerWantsMorePanels === "Yes") {
            html += `<p><b>How many panels?</b> ${form.numberOfAdditionalPanels}</p>`;
        }
        html += `<p><b>What is most important to the customer?</b> ${form.customerPriority}</p>`;
        if (form.customerPriority === "Aesthetics") {
            html += "<p><b>Please explain</b>:</p>";
            html += `<p>${form.customerPriorityExplanation}</p>`;
        }
        html += `<p><b>How many stories does the building have?</b> ${form.numberOfStories}</p>`;
        if (form.numberOfStories > 1) {
            html += `<p><b>Can you access the roof from the ground level?</b> ${form.roofAccess}</p>`;
        }
        if (form.specialRequests) {
            html += `<p><b>Special Notes</b>:</p><p>${form.specialRequests}</p>`;
        }
        html += "</div>";
        return html;
    }

    fetchOpportunities(user) {
        return this.createOpportunityFilters(user)
            .then((filters) => this.api.request("POST", `/item/app/${this.opportunitySchema.id}/filter`, { filters }))
            .then((response) => ({
                count: response.filtered,
                page: 1,
                records: this.opportunityConverter.convertMany(response.items)
            }));
    }

    fetchStaleOpportunities(user) {
        return this.opportunitySchema.translateFields({
            "consultants": user.profile.profile_id,
            "appointment": { to: "-3dr" },
            "designStatus": ["Complete"],
            "stage": ["2T Scheduled", "2T Still Working"],
            "status": ["Active"]
        }).then((filters) => (
            this.api.request("POST", `/item/app/${this.opportunitySchema.id}/filter`, { filters })
        )).then((response) => ({
            count: response.filtered,
            page: 1,
            records: this.opportunityConverter.convertMany(response.items)
        }));
    }

    fetchOpportunity(id) {
        return this.api.request("GET", `/item/${id}`)
            .then((response) => {
                return this.opportunityConverter.convert(response);
            });
    }

    createOpportunityFilters(user) {
        return this.opportunitySchema.translateFields({
            "consultants": user.profile.profile_id,
            "appointment": { from: "-2dr" },
            "designStatus": ["Complete"],
            "stage": ["2T Scheduled", "2T Still Working"]
        });
    }

    getProject(id) {
        return this.api.request("GET", `/item/${id}`)
            .then((response) => this.projectConverter.convert(response))
            .catch(() => {
                throw new Error(`There was an issue finding the project with an id of ${id} in the ${this.name} branch.`);
            });
    }

    fetchProjects(user) {
        return this.createProjectFilters(user)
            .then((filters) => this.api.request("POST", `/item/app/${this.projectSchema.id}/filter`, { filters }))
            .then((response) => ({
                count: response.filtered,
                page: 1,
                records: this.projectConverter.convertMany(response.items)
            }));
    }

    createRequest(id, fields) {
        let requestId = null;
        return this.api.request("POST", `/item/app/27330982`, { fields })
            .catch(() => Promise.reject(new Error(`An unexpected error occurred when creating your request.`)))
            .then((response) => {
                requestId = response.item_id;
                return this.api.request("GET", `/item/${id}/value/changes/v2`)
                    .catch(() => Promise.reject(new Error(`An unexpected error occurred when trying to find the requests for the project ${id}.`)));
            })
            .then((response) => {
                const data = [];
                if (response.values && response.values.length > 0) {
                    for (const value of response.values) {
                        data.push(value.item_id);
                    }
                }
                data.push(requestId);
                return data;
            })
            .then((data) => {
                return this.api.request("PUT", `/item/${id}/value/changes`, data)
                    .catch(() => Promise.reject(`An unexpected error occured when relating your request to the project ${id}.`));
            });
    }

    createProjectFilters(user) {
        const filters = {};
        const consultantField = this.projectSchema.getFieldId("consultant");
        filters[consultantField] = user.profile.profile_id;
        const statusField = this.projectSchema.getFieldId("status");
        return this.projectSchema.getOptionId("status", "Active")
            .then((id) => {
                filters[statusField] = [id];
                return filters;
            });
    }

    resolveIssue(issueId, fields, file) {
        fields["237308630"] = [2];
        return this.api.request("PUT", `/item/${issueId}/`, { fields })
            .catch(() => Promise.reject(`There was an issue adding your comments to the task ${issueId}`))
            .then(() => {
                if (file) {
                    return this.attachFile(issueId, file)
                        .catch(() => Promise.reject(new Error(`There was an issue attaching your file to the task ${issueId}`)));
                }
            });

    }
}

export default PodioBranch;
import { Component } from "react";
import "./FormField.css";

class FormField extends Component {
    constructor(props) {
        super(props);
    }

    hasError() {
        return this.props.error !== undefined && this.props.error !== null && this.props.error !== "";
    }

    render() {
        return (
            <div className={"FormField" + (this.hasError() ? " error" : "")}>
                <div className="FormField-container">
                    {this.props.children}
                </div>
                <div className={"FormField-error-container"}>
                    <div className="FormField-error-message">
                        {this.props.error}
                    </div>
                </div>
            </div>
        );
    }
}

export default FormField;
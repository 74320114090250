import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { api as PodioJS } from "podio-js";
import BasicSessionManager from "./storage/podio/BasicSessionManager";
import PodioPlatform from './storage/platforms/podio/PodioPlatform';

let podioApi = new PodioJS({
  authType: "client",
  clientId: process.env.REACT_APP_PODIO_CLIENT_ID
}, {
  sessionStore: new BasicSessionManager()
});
let podioPlatform = new PodioPlatform(podioApi);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App platform={podioPlatform}/>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { Component } from "react";
import LoginScreen from "./screens/LoginScreen/LoginScreen";
import LeadsScreen from "./screens/LeadsScreen/LeadsScreen";
import { Oval } from "svg-loaders-react";
import "./App.css";
import PodioBranchList from "./storage/podio/PodioBranchList";
import { Switch, Route, useHistory } from "react-router-dom";
import LeadScreen from "./screens/LeadScreen/LeadScreen";
import OpportunitiesScreen from "./screens/OpportunitiesScreen/OpportunitiesScreen";
import OpportunityScreen from "./screens/OpportunityScreen/OpportunityScreen";
import HomeScreen from "./screens/HomeScreen/HomeScreen";
import ProjectsScreen from "./screens/ProjectsScreen/ProjectsScreen";
import RevisionScreen from "./screens/RevisionScreen/RevisionScreen";
import PendingDesignsScreen from "./screens/PendingDesignsScreen";
import StaleLeadsScreen from "./screens/StaleLeadsScreen/StaleLeadsScreen";
import StaleOpportunitiesScreen from "./screens/StaleOpportunitiesScreen/StaleOpportunitiesScreen";
import NewLeadScreen from "./screens/NewLeadScreen/NewLeadScreen";
import SelfGensScreen from "./screens/SelfGensScreen/SelfGensScreen";
import ScheduleConsultationScreen from "./screens/ScheduleConsultationScreen/ScheduleConsultationScreen";

class App extends Component {
	constructor(props) {
		super(props);
		this.podio = this.props.platform.getApi();
		this.branchList = new PodioBranchList(this.podio);
		this.state = {
			activeBranch: this.getBranchFromQuery(),
			user: null,
			isLoading: true
		};
		this.setBranch = this.setBranch.bind(this);
		this.startLoader = this.startLoader.bind(this);
		this.stopLoader = this.stopLoader.bind(this);
		this.showPage = this.showPage.bind(this);
	}

	getBranchFromQuery() {
		const query = App.getQuery();
		if (query.has("branch")) {
			const branchName = query.get("branch");
			try {
				return this.branchList.getByName(branchName);
			} catch(_) {}
		}
		return null;
	}

	static getQuery() {
		return new URLSearchParams(window.location.search);
	}

	componentDidMount() {
		this.podio.isAuthenticated()
			.then(() => {
				if (this.state.user) {
					return this.state.user;
				} else {
					return this.podio.request("GET", "/user/status").catch(() => {
						this.showLoginPage();
					});
				}
			})
			.then((user) => {
				this.setState({
					user
				});
			})
			.catch(() => {
				this.showLoginPage();
			});
	}

	showLoginPage() {
		const loginPath = this.getLoginPathWithRedirect();
		this.props.history.push(loginPath);
	}

	getLoginPathWithRedirect() {
		const query = App.getQuery();
		if (window.location.pathname !== "/login") {
			query.append("redirect", window.location.pathname);
		}
		return "/login?" + query.toString();
	}

	startLoader() {
		this.setState({
			isLoading: true
		});
	}

	stopLoader() {
		this.setState({
			isLoading: false
		});
	}

	setBranch(branch) {
		this.startLoader();
		this.setState({
			activeBranch: branch
		});
	}

	showPage(path, state = {}) {
		this.startLoader();
		if (this.state.activeBranch) {
			this.props.history.push(`${path}?branch=${this.state.activeBranch.name}`, state);
		} else {
			this.props.history.push(path, state);
		}
	}

	render() {
		return (
			<div className={"App" + (this.state.isLoading ? " loading" : "")}>
				<div className="App-container">
					<Switch>
						<Route path="/deals/stale/opportunities" render={(props) => <StaleOpportunitiesScreen {...props} platform={this.props.platform} podio={this.podio} user={this.state.user} branchList={this.branchList} activeBranch={this.state.activeBranch} setBranch={this.setBranch} startLoader={this.startLoader} stopLoader={this.stopLoader} showPage={this.showPage}/>}/>
						<Route path="/deals/stale/leads" render={(props) => <StaleLeadsScreen {...props} platform={this.props.platform} podio={this.podio} user={this.state.user} branchList={this.branchList} activeBranch={this.state.activeBranch} setBranch={this.setBranch} startLoader={this.startLoader} stopLoader={this.stopLoader} showPage={this.showPage}/>}/>
						<Route path="/deals/self-gens" render={(props) => <SelfGensScreen {...props} platform={this.props.platform} podio={this.podio} user={this.state.user} branchList={this.branchList} activeBranch={this.state.activeBranch} setBranch={this.setBranch} startLoader={this.startLoader} stopLoader={this.stopLoader} showPage={this.showPage}/>}/>
						<Route path="/deals/schedule/:id" render={(props) => <ScheduleConsultationScreen platform={this.props.platform} {...props} podio={this.podio} user={this.state.user} branchList={this.branchList} activeBranch={this.state.activeBranch} startLoader={this.startLoader} stopLoader={this.stopLoader} showPage={this.showPage}/>}/>
						<Route exact path="/deals/leads/new" render={(props) => <NewLeadScreen {...props} user={this.state.user} platform={this.props.platform} branchList={this.branchList} startLoader={this.startLoader} stopLoader={this.stopLoader} showPage={this.showPage}/>}/>
						<Route path="/deals/leads/:id" render={(props) => <LeadScreen {...props} platform={this.props.platform} podio={this.podio} user={this.state.user} branchList={this.branchList} activeBranch={this.state.activeBranch} startLoader={this.startLoader} stopLoader={this.stopLoader} showPage={this.showPage}/>}/>
						<Route path="/deals/leads" render={(props) => <LeadsScreen {...props} platform={this.props.platform} podio={this.podio} user={this.state.user} branchList={this.branchList} activeBranch={this.state.activeBranch} setBranch={this.setBranch} startLoader={this.startLoader} stopLoader={this.stopLoader} showPage={this.showPage}/>}/>
						<Route path="/deals/opportunities/:id/revision" render={(props) => <RevisionScreen {...props} platform={this.props.platform} podio={this.podio} user={this.state.user} branchList={this.branchList} activeBranch={this.state.activeBranch} startLoader={this.startLoader} stopLoader={this.stopLoader} showPage={this.showPage}/>}/>
						<Route path="/deals/opportunities/:id" render={(props) => <OpportunityScreen {...props} platform={this.props.platform} podio={this.podio} user={this.state.user} branchList={this.branchList} activeBranch={this.state.activeBranch} startLoader={this.startLoader} stopLoader={this.stopLoader} showPage={this.showPage}/>}/>
						<Route path="/deals/opportunities" render={(props) => <OpportunitiesScreen {...props} platform={this.props.platform} podio={this.podio} user={this.state.user} branchList={this.branchList} activeBranch={this.state.activeBranch} setBranch={this.setBranch} startLoader={this.startLoader} stopLoader={this.stopLoader} showPage={this.showPage}/>}/>
						<Route path="/projects" render={(props) => <ProjectsScreen {...props} platform={this.props.platform} podio={this.podio} user={this.state.user} branchList={this.branchList} activeBranch={this.state.activeBranch} setBranch={this.setBranch} startLoader={this.startLoader} stopLoader={this.stopLoader} showPage={this.showPage}/>}/>
						<Route path="/designs" render={(props) => <PendingDesignsScreen {...props} platform={this.props.platform} podio={this.podio} user={this.state.user} branchList={this.branchList} activeBranch={this.state.activeBranch} setBranch={this.setBranch} startLoader={this.startLoader} stopLoader={this.stopLoader} showPage={this.showPage}/> }/>
						<Route path="/login" render={(props) => <LoginScreen {...props} platform={this.props.platform} podio={this.podio} stopLoader={this.stopLoader} startLoader={this.startLoader} showPage={this.showPage}/>}/>
						<Route exact path="/" render={(props) => <HomeScreen {...props} platform={this.props.platform} user={this.state.user} stopLoader={this.stopLoader} showPage={this.showPage}/>}/>
					</Switch>
				</div>
				<div className="App-loader">
					<div className="App-loader-container">
						<div className="App-loader-spinner">
							<Oval width="50" stroke="#000" strokeWidth={3}/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function AppWrapper(props) {
	let history = useHistory();

	return <App {...props} history={history}/>;
};

export default AppWrapper;
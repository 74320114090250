import LeadSchema from "../../LeadSchema";


class BillingsLeadSchema extends LeadSchema {
    constructor(podioApi) {
        super(podioApi, 26712630);
        this.addField("name", 230354048, "first-name");
        this.addField("email", 230354050, "email");
        this.addField("phone", 230354049, "phone");
        this.addField("cosignerName", 230354051, "co-name");
        this.addField("cosignerEmail", 230354053, "co-email");
        this.addField("cosignerPhone", 230354052, "co-phone");
        this.addField("address", 230354054, "location-2");
        this.addField("status", 230354055, "lead-status");
        this.addField("stage", 230354058, "status");
        this.addField("appointment", 230354059, "appointment-date");
        this.addField("leadManager", 230354081, "lead-owner");
        this.addField("utilityCompany", 240687093, "utility-company");
        this.addField("installationAddress", 240687094, "installation-address");
        this.addField("roofMaterial", 240687095, "roof-material");
        this.addField("isLongTermResident", 240687103, "is-long-term-resident");
        this.addField("shortTermResidencyReason", 240687104, "short-term-residency-reason");
        this.addField("squareFootage", 240687105, "square-footage");
        this.addField("yearBuilt", 240687106, "year-built");
        this.addField("numberOfStories", 240687107, "number-of-stories");
        this.addField("numberOfResidents", 240687108, "number-of-residents");
        this.addField("heatingType", 240687109, "heating-type");
        this.addField("airConditioner", 240687110, "air-conditioner");
        this.addField("waterHeater", 240687111, "water-heater");
        this.addField("applianceType", 240687112, "appliance-type");
        this.addField("consumptionChanges", 240687113, "consumption-changes");
        this.addField("utilityMeterNumber", 240687114, "utility-meter-number");
        this.addField("utilityAccountNumber", 240687115, "utility-account-number");
        this.addField("proposalRequest1", 240687096, "proposal-request-1");
        this.addField("proposalRequest2", 240687097, "proposal-request-2");
        this.addField("proposalRequest3", 240687098, "proposal-request-3");
        this.addField("targetOffset", 236932916, "system-offset");
        this.addField("adders", 240687099, "adders-notes");
        this.addField("turnaroundTime", 240687102, "design-turnaround-time");
        this.addField("designStatus", 230354061, "request-design");
        this.addField("consultants", 230354082, "salesperson-2");
        this.addField("leadManagers", 230354081, "lead-owner");
        this.addField("proposalAppointment", 230354060, "2nd-touch-appt");
        this.addField("stageNotes", 240687092, "stage-notes");
        this.addField("leadManagerNotes", 230354056, "lead-notes");
        this.addField("channel", 230354080, "lead-source-do-not-change");
        this.addField("source", 230354087, "lead-source-category");
        this.addField("referredBy", 230354077, "referred-by");
        this.addField("type", 245083032, "type");
        this.addField("unqualifiedReason", 252160368, "unqualified-reason");
    }
}

export default BillingsLeadSchema;
/**
 * @typedef {import("../Lead").default} Lead
 */

/**
 * @abstract
 */
class Platform {
    /**
     * @abstract
     * @param {Lead} lead
     * @returns {Promise<Lead>}
     */
    createLead(lead) {
        throw new Error("The Platform class must be extended in order to use this method.");
    }

    /**
     * @abstract
     * @returns {Promise<void>}
     */
    logout() {
        throw new Error("The Platform class must be extended in order to use this method.");
    }
}

export default Platform;
import { Component } from "react";
import BranchSelector from "../../BranchSelector/BranchSelector";
import ScreenContainer from "../../ScreenContainer/ScreenContainer";
import Table from "../../table/Table";
import TableRow from "../../table/TableRow";
import "./ObjectQueue.css";


class ObjectQueue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            records: null
        };
    }

    static hasBranchInQuery() {
        const query = new URLSearchParams(window.location.search);
        return query.has("branch");
    }

    componentDidMount() {
        if (this.props.activeBranch && this.props.user) {
            this.fetch();
        } else if (!ObjectQueue.hasBranchInQuery()) {
            this.props.stopLoader();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.needsRefresh(prevProps, this.props)) {
            this.refresh();
        }
    }

    needsRefresh(prevProps, props) {
        if (this.hasBranchAndUser(props)) {
            return !this.hasBranchAndUser(prevProps) || this.branchOrUserHasChanged(prevProps, props);
        }
        return false;
    }

    hasBranchAndUser(props) {
        return props.activeBranch && props.user;
    }

    branchOrUserHasChanged(prevProps, props) {
        return this.isDifferentBranch(prevProps.activeBranch, props.activeBranch) || this.isDifferentUser(prevProps.user, props.user)
    }

    isDifferentBranch(branch1, branch2) {
        return branch1.id !== branch2.id;
    }

    isDifferentUser(user1, user2) {
        return user1.user_id !== user2.user_id;
    }

    refresh() {
        this.props.startLoader();
        this.clearResults();
        this.fetch();
    }

    clearResults() {
        this.setState({
            error: null,
            records: null
        });
    }

    fetch() {
        this.props.fetchResources(this.props.user)
            .then((results) => {
                this.setState({
                    error: null,
                    records: results.records
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    error: "There was an issue finding your records.",
                    records: null
                });
            })
            .then(() => this.props.stopLoader());
    }

    isSuccess() {
        return this.state.error === null && this.state.records !== null;
    }

    handleRowClick(record) {
        if (this.props.recordPath) {
            this.showRecordScreen(record);
        } else if (record.externalUrl !== undefined && record.externalUrl !== null) {
            window.open(record.externalUrl, "_blank").focus();
        }
    }

    showRecordScreen(record) {
        this.props.showPage(
            this.props.recordPath.replaceAll(":id", record.id),
            { record }
        );
    }

    hasBranch() {
        if (this.props.activeBranch) {
            return true;
        }
        return false;
    }

    render() {
        return (
            <ScreenContainer showPage={this.props.showPage} platform={this.props.platform}>
                <BranchSelector
                    default={this.props.activeBranch}
                    branchList={this.props.branchList}
                    onChange={this.props.setBranch}
                />
                <div className="ObjectQueue-title">
                    {this.props.title}
                </div>
                {this.isSuccess() ? (this.state.records.length > 0 ? (
                    <Table>
                        {this.props.header}
                        {this.state.records.map((record, index) => (
                            <TableRow key={index} onClick={() => this.handleRowClick(record)}>
                                {this.props.renderRecord(record)}
                            </TableRow>
                        ))}
                    </Table>
                ) : (
                    <div className="ObjectQueue-message">
                        No records could be found.
                    </div>
                )) : !this.hasBranch() && (
                    <div className="ObjectQueue-message">
                        Please select a branch.
                    </div>
                )}
            </ScreenContainer>
        );
    }
}

export default ObjectQueue;
import UploadedFile from "../UploadedFile";
import FileUploader from "../FileUploader";
import PodioSingleFileUploader from "./PodioSingleFileUploader";


class PodioSimpleMultipleFileUploader extends FileUploader {
    constructor(api) {
        super(null);
        this.uploader = new PodioSingleFileUploader(api);
    }

    attach(files) {
        if (files instanceof Array) {
            return this.uploadFiles(files);
        } else {
            throw new Error("You must pass an array of files to upload them to Podio.");
        }
    }

    uploadFiles(files) {
        const promises = [];
        for (const file of files) {
            promises.push(this.uploadFile(file));
        }
        return Promise.all(promises);
    }

    uploadFile(file) {
        return this.uploader.upload(file.blob, file.name)
            .then((response) => UploadedFile.fromUploadable(file, response.file_id, response.link));
    }

    remove(attachment) {
        return this.api.request("DELETE", `/file/${attachment.id}`)
            .catch(() => Promise.reject(new Error(`The attachment with an id of ${attachment.id} could not be removed.`)));
    }
}

export default PodioSimpleMultipleFileUploader;
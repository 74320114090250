import { Component } from "react";
import ProposalRequest from "../../../ProposalRequest";
import MultipleFileField from "../fields/MultipleFileField/MultipleFileField";
import MultiLineTextField from "../fields/MultiLineTextField/MultiLineTextField";
import NumericField from "../fields/NumericField/NumericField";
import RadioField from "../fields/RadioField/RadioField";
import RadioOption from "../fields/RadioField/RadioOption";
import SelectionField from "../fields/SelectionField/SelectionField";
import SelectionOption from "../fields/SelectionField/SelectionOption";


class ProposalFieldGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            request: new ProposalRequest(),
            treeRemoval: null,
            hasAdders: null,
            conditionals: {
                treeRemoval: {
                    value: null,
                    error: null
                },
                hasAdders: {
                    value: null,
                    error: null
                }
            },
            uploads: {
                siteMap: {
                    loading: false,
                    value: null,
                    error: null
                }
            },
            fields: {
                mountingType: {
                    value: null,
                    error: null
                },
                description: {
                    value: null,
                    error: null
                },
                treeRemovalDescription: {
                    value: null,
                    error: null
                },
                targetOffset: {
                    value: null,
                    error: null
                },
                addersNotes: {
                    value: null,
                    error: null
                }
            }
        };
    }

    updateUpload(key, value) {
        const uploads = this.state.uploads;
        uploads[key].value = value;
        uploads[key].loading = false;
        this.setState({ uploads });
        this.change();
    }

    startUpload(key) {
        const uploads = this.state.uploads;
        uploads[key].loading = true;
        this.setState({ uploads });
    }

    setUploadError(key, error) {
        const uploads = this.state.uploads;
        uploads[key].loading = false;
        uploads[key].error = error;
        this.setState({ uploads });
    }

    updateConditional(key, value) {
        const conditionals = this.state.conditionals;
        conditionals[key].value = value;
        this.setState({ conditionals });
        this.change();
    }

    updateField(key, value) {
        const fields = this.state.fields;
        fields[key].value = value;
        this.setState({ fields });
        this.change();
    }

    change() {
        if (this.props.onChange) {
            const html = this.getHtml();
            this.props.onChange(html);
        }
    }

    getHtml() {
        let html = "<div>";
        if (this.state.fields.mountingType.value) {
            html += `<p><b>Mounting Type</b>: <span>${this.state.fields.mountingType.value}</span></p>`;
        }
        if (this.state.fields.description.value) {
            html += `<p><b>Description</b>: <span>${this.state.fields.description.value}</span></p>`;
        }
        if (this.state.fields.treeRemovalDescription.value) {
            html += `<p><b>Tree Removal</b>: <span>Yes</span></p>`;
        } else {
            html += `<p><b>Tree Removal</b>: <span>No</span></p>`;
        }
        if (this.state.fields.treeRemovalDescription.value) {
            html += `<p><b>Tree Removal Notes</b>: <span>${this.state.fields.treeRemovalDescription.value}</span></p>`;
        }
        if (this.state.uploads.siteMap.value !== null && this.state.uploads.siteMap.value.length > 0) {
            html += "<p><b>Site Map</b>:</p><p>";
            for (const image of this.state.uploads.siteMap.value) {
                html += `<img src="${image.url}" alt="Satellite Image Failed to Upload">`;
            }
            html += "</p>";
        }
        if (this.state.fields.targetOffset.value) {
            html += `<p><b>Target Offset</b>: <span>${this.state.fields.targetOffset.value}</span></p>`;
        }
        if (this.state.fields.addersNotes.value) {
            html += `<p><b>Adders</b>: <span>${this.state.fields.addersNotes.value}</span></p>`;
        }
        html += "</div>";
        return html;
    }

    render() {
        const { fields, conditionals, uploads } = this.state;
        return (
            <div className="ProposalFieldGroup FieldGroup">
                <div className="ProposalFieldGroup-container FieldGroup-container">
                    <SelectionField label="Mounting Type" placeholder="Select a mounting type..." error={fields.mountingType.error} onChange={(value) => this.updateField("mountingType", value)}>
                        <SelectionOption value="Roof Mount">Roof Mount</SelectionOption>
                        <SelectionOption value="Ground Mount">Ground Mount</SelectionOption>
                    </SelectionField>
                    <MultiLineTextField label="Please describe any details about your proposal" placeholder="Enter a description for the proposals..." error={fields.description.error} onChange={(value) => this.updateField("description", value)}/>
                    <RadioField label="Do any trees need to be removed?" error={conditionals.treeRemoval.error} onChange={(value) => this.updateConditional("treeRemoval", value)}>
                        <RadioOption value={true}>Yes</RadioOption>
                        <RadioOption value={false}>No</RadioOption>
                    </RadioField>
                    {conditionals.treeRemoval.value && (
                        <MultiLineTextField label="Please explain the tree removal in detail..." error={fields.treeRemovalDescription.error} placeholder="Enter an explanation for the tree removals for this proposal..." onChange={(value) => this.updateField("treeRemovalDescription", value)}/>
                    )}
                    <MultipleFileField
                        uploader={this.props.siteMapUploader}
                        label="Upload a satellite image with annotations"
                        error={uploads.siteMap.error}
                        onStartLoader={() => this.startUpload("siteMap")}
                        onChange={(value) => this.updateUpload("siteMap", value)}
                        onError={(error) => this.setUploadError("siteMap", error)}
                    />
                    <NumericField label="Target Offset (%)" placeholder="Enter the desired energy offset..." error={fields.targetOffset.error} onChange={(value) => this.updateField("targetOffset", value)}/>
                    <RadioField label="Are there any adders that should be in the proposal?" error={conditionals.hasAdders.error} onChange={(value) => this.updateConditional("hasAdders", value)}>
                        <RadioOption value={true}>Yes</RadioOption>
                        <RadioOption value={false}>No</RadioOption>
                    </RadioField>
                    {conditionals.hasAdders.value && (
                        <MultiLineTextField label="Please list the adders" error={fields.addersNotes.error} placeholder="Enter the adders that need included in this proposal..." onChange={(value) => this.updateField("addersNotes", value)}/>
                    )}
                </div>
            </div>
        );
    }
}

export default ProposalFieldGroup;
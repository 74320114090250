import OpportunitySchema from "../../OpportunitySchema";

class TestOpportunitySchema extends OpportunitySchema {
    constructor(podioApi) {
        super(podioApi, 25762586);
        this.addField("name", 219816501, "first-name");
        this.addField("email", 219816503, "email");
        this.addField("phone", 219816502, "phone");
        this.addField("address", 219816507, "location-2");
        this.addField("cosigner", 219816504, "co-name");
        this.addField("cosignerEmail", 219816506, "co-email");
        this.addField("cosignerPhone", 219816505, "co-phone");
        this.addField("status", 219816508, "lead-status");
        this.addField("stage", 219816511, "status");
        this.addField("appointment", 219816513, "2nd-touch-appt");
        this.addField("auroraId", 237293868, "aurora-project-id");
        this.addField("utilityBillHolder", 237445096, "utility-bill-holder");
        this.addField("hasHoa", 237445097, "has-hoa");
        this.addField("gateCode", 237445098, "gate-code");
        this.addField("monitoringSystem", 237445099, "monitoring-system");
        this.addField("deposit", 237445100, "deposit-amount");
        this.addField("specialRequests", 237445101, "special-requests");
        this.addField("nickname", 237445095, "nickname");
        this.addField("leadManager", 219816533, "lead-owner");
        this.addField("designStatus", 219816514, "request-design");
        this.addField("consultants", 219816534, "salesperson-2");
        this.addField("siteMap", 236875548, "site-map");
        this.addField("hoaName", 238380066, "hoa-name");
        this.addField("hoaEmail", 238380067, "hoa-email");
        this.addField("hoaPhone", 238380068, "hoa-phone");
        this.addField("propertyAccessInstructions", 238381365, "property-access-instructions");
        this.addField("numberOfTreeRemovals", 239995852, "number-of-tree-removals");
        this.addField("partyResponsibleForTreeRemoval", 239995971, "party-responsible-for-tree-removal");
        this.addField("customerWantsRoofBid", 239996379, "customer-wants-roof-bid");
        this.addField("customerWantsMorePanels", 239996380, "customer-wants-more-panels");
        this.addField("numberOfAdditionalPanels", 239996381, "number-of-additional-panels");
        this.addField("referredBy", 219816529, "referred-by");
        this.addField("customerPriority", 239996382, "customer-priority");
        this.addField("customerPriorityExplanation", 239996383, "customer-priority-explanation");
        this.addField("numberOfStories", 238712059, "number-of-stories");
        this.addField("roofAccess", 239996384, "can-access-roof-from-ground-level");
        this.addField("submitDeal", 219816536, "submit-deal");
        this.addField("stageNotes", 239999257, "stage-notes");
        this.addField("designRevisionNotes", 240059330, "design-revision-notes");
        this.addField("type", 245336890, "type");
        this.addField("notes", 219816535, "consultant-notes");
    }
}

export default TestOpportunitySchema;
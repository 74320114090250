class Project {
    constructor() {
        this.id = null;
        this.name = null;
        this.phase = null;
        this.panel = null;
        this.panelCount = null;
        this.systemSize = null;
        this.contractPrice = null;
        this.issues = null;
        this.signedOn = null;
        this.externalUrl = null;
    }
}

export default Project;
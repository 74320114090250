import { Component } from "react";
import "./ProfileMetadata.css";
// import triangle from "../../../assets/white-triangle.svg";

class ProfileMetadata extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCollapsed: true
        };
    }

    toggleCollapse() {
        if (this.state.isCollapsed) {
            this.setState({
                isCollapsed: false
            });
        } else {
            this.setState({
                isCollapsed: true
            });
        }
    }

    render() {
        return (
            <div className={"ProfileMetadata" + (this.state.isCollapsed ? " collapse" : "")}>
                <div className="ProfileMetadata-container">
                    <div className="ProfileMetadata-collapse-menu" onClick={this.toggleCollapse.bind(this)}>
                        <div className="ProfileMetadata-collapse-menu-container">
                            <div className="ProfileMetadata-collapse-menu-title">
                                {this.props.title}
                            </div>
                            <div className="ProfileMetadata-collapse-menu-icon">
                                {/* <img src={triangle} /> */}
                                {this.state.isCollapsed ? <span>+</span> : <span>&minus;</span>}
                            </div>
                        </div>
                    </div>
                    <div className="ProfileMetadata-contents">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default ProfileMetadata;
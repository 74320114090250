import PodioAppSchema from "./PodioAppSchema";

class OpportunitySchema extends PodioAppSchema {
    constructor(podioApi, appId) {
        super(podioApi, appId);
        this.addKey("name", "text");
        this.addKey("email", "email");
        this.addKey("phone", "phone");
        this.addKey("address", "location");
        this.addKey("cosigner", "text");
        this.addKey("cosignerEmail", "email");
        this.addKey("cosignerPhone", "phone");
        this.addKey("status", "category");
        this.addKey("stage", "category");
        this.addKey("appointment", "date");
        this.addKey("auroraId", "text");
        this.addKey("utilityBillHolder", "text");
        this.addKey("hasHoa", "category");
        this.addKey("gateCode", "text");
        this.addKey("monitoringSystem", "category");
        this.addKey("deposit", "currency");
        this.addKey("specialRequests", "text");
        this.addKey("nickname", "text");
        this.addKey("designStatus", "category");
        this.addKey("consultants", "contact");
        this.addKey("leadManager", "contact");
        this.addKey("siteMap", "image");
        this.addKey("hoaName", "text");
        this.addKey("hoaEmail", "email");
        this.addKey("hoaPhone", "phone");
        this.addKey("propertyAccessInstructions", "text");
        this.addKey("numberOfTreeRemovals", "number");
        this.addKey("partyResponsibleForTreeRemoval", "category");
        this.addKey("customerWantsRoofBid", "category");
        this.addKey("customerWantsMorePanels", "category");
        this.addKey("numberOfAdditionalPanels", "number");
        this.addKey("referredBy", "text");
        this.addKey("customerPriority", "category");
        this.addKey("customerPriorityExplanation", "text");
        this.addKey("numberOfStories", "number");
        this.addKey("roofAccess", "category");
        this.addKey("submitDeal", "category");
        this.addKey("stageNotes", "text");
        this.addKey("designRevisionNotes", "text");
        this.addKey("type", "category");
        this.addKey("notes", "text");
    }
}

export default OpportunitySchema;
import LeadSchema from "../../LeadSchema";

class TestLeadSchema extends LeadSchema {
    constructor(podioApi) {
        super(podioApi, 25762586);
        this.addField("name", 219816501, "first-name");
        this.addField("email", 219816503, "email");
        this.addField("phone", 219816502, "phone");
        this.addField("cosignerName", 219816504, "co-name");
        this.addField("cosignerEmail", 219816506, "co-email");
        this.addField("cosignerPhone", 219816505, "co-phone");
        this.addField("address", 219816507, "location-2");
        this.addField("status", 219816508, "lead-status");
        this.addField("stage", 219816511, "status");
        this.addField("appointment", 219816512, "appointment-date");
        this.addField("leadManager", 219816533, "lead-owner");
        this.addField("utilityCompany", 236875539, "utility-company");
        this.addField("installationAddress", 236875540, "installation-address");
        this.addField("mountingType", 236875541, "mounting-type");
        this.addField("roofMaterial", 236875542, "roof-material");
        this.addField("isLongTermResident", 236875543, "short-term-residency");
        this.addField("shortTermResidencyReason", 238712056, "short-term-residency-reason");
        this.addField("januaryConsumption", 238712069, "january-consumption");
        this.addField("februaryConsumption", 238712070, "february-consumption");
        this.addField("marchConsumption", 238712071, "march-consumption");
        this.addField("aprilConsumption", 238712072, "april-consumption");
        this.addField("mayConsumption", 238712073, "may-consumption");
        this.addField("juneConsumption", 238712074, "june-consumption");
        this.addField("julyConsumption", 238712075, "july-consumption");
        this.addField("augustConsumption", 238712076, "august-consumption");
        this.addField("septemberConsumption", 238712077, "september-consumption");
        this.addField("octoberConsumption", 238712078, "october-consumption");
        this.addField("novemberConsumption", 238712079, "november-consumption");
        this.addField("decemberConsumption", 238712080, "december-consumption");
        this.addField("squareFootage", 238712057, "square-footage");
        this.addField("yearBuilt", 238712058, "year-built");
        this.addField("numberOfStories", 238712059, "number-of-stories");
        this.addField("numberOfResidents", 238712060, "number-of-residents");
        this.addField("heatingType", 238712061, "heating-type");
        this.addField("airConditioner", 238712062, "air-condition");
        this.addField("waterHeater", 238712063, "water-heater");
        this.addField("wholeHomeLed", 238712064, "whole-home-led");
        this.addField("applianceType", 238712065, "appliance-type");
        this.addField("consumptionChanges", 238712066, "consumption-changes");
        this.addField("utilityMeterNumber", 238712067, "utility-meter-number");
        this.addField("utilityAccountNumber", 238712068, "utility-account-number");
        this.addField("proposalRequest1", 238771514, "proposal-request-1");
        this.addField("proposalRequest2", 238771515, "proposal-request-2");
        this.addField("proposalRequest3", 238771516, "proposal-request-3");
        this.addField("utilityBill", 236883500, "utility-bill");
        this.addField("usageDisclosureForm", 236883524, "usage-disclosure-form");
        this.addField("numberOfProposals", 236875544, "number-of-proposals");
        this.addField("proposalDescription", 236875545, "proposal-notes");
        this.addField("needsTreeRemoval", 236875546, "remove-trees");
        this.addField("treeRemovalDescription", 236875547, "tree-notes");
        this.addField("siteMap", 236875548, "site-map");
        this.addField("aestheticRequests", 236875550, "aesthetic-requests");
        this.addField("targetOffset", 236875549, "target-offset");
        this.addField("adders", 236875551, "adders-notes");
        this.addField("turnaroundTime", 236884854, "design-turnaround-time");
        this.addField("designStatus", 219816514, "request-design");
        this.addField("consultants", 219816534, "salesperson-2");
        this.addField("leadManagers", 219816533, "lead-owner");
        this.addField("proposalAppointment", 219816513, "2nd-touch-appt");
        this.addField("stageNotes", 239999257, "stage-notes");
        this.addField("leadManagerNotes", 219816509, "lead-notes");
        this.addField("channel", 219816532, "lead-source-do-not-change");
        this.addField("source", 219816537, "lead-source-category");
        this.addField("referredBy", 219816529, "referred-by");
        this.addField("type", 245336890, "type");
        this.addField("unqualifiedReason", 252279636, "unqualified-reason");
    }
}

export default TestLeadSchema;
import { Component } from "react";
import "./LeadsScreen.css";
import TableData from "../../components/table/TableData";
import ObjectQueue from "../../components/templates/ObjectQueue";
import TableHeader from "../../components/table/TableHeader";

class LeadsScreen extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ObjectQueue
                {...this.props}
                title="My Leads"
                recordPath={"/deals/leads/:id"}
                fetchResources={(user) => this.props.activeBranch.fetchLeads(user)}
                header={(
                    <TableHeader>
                        <TableData className="medium">ID</TableData>
                        <TableData>Name</TableData>
                        <TableData>Consultation Date</TableData>
                        <TableData className="medium">City</TableData>
                    </TableHeader>
                )}
                platform={this.props.platform}
                renderRecord={(lead) => (
                    <>
                        <TableData className="medium">{lead.id}</TableData>
                        <TableData>
                            <strong>{lead.getFullName()}</strong>
                        </TableData>
                        <TableData>
                            {lead.appointment.toLocaleString()}
                        </TableData>
                        <TableData className="medium">
                            {lead.getCityAndState()}
                        </TableData>
                    </>
                )}
            />
        );
    }
}

export default LeadsScreen;
import { Component } from "react";
import TableData from "./TableData";
import "./TableHeader.css";


class TableHeader extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="TableHeader">
                <div className="TableHeader-container">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default TableHeader;
import { Component } from "react";
import "./TableData.css";

class TableData extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={"TableData" + (this.props.className ? (" " + this.props.className) : "")}>
                <div className="TableData-container">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default TableData;
import { Component } from "react";
import "./Table.css";

class Table extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="Table">
                <div className="Table-container">
                    <div className="Table-rows">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default Table;
import ProjectSchema from "../../ProjectSchema";


class BendProjectSchema extends ProjectSchema {
    constructor(podioApi) {
        super(podioApi, 24397994);
        this.addField("name", 205677201, "title");
        this.addField("email", 205678385, "email");
        this.addField("phone", 205678384, "phone");
        this.addField("address", 205678389, "address");
        this.addField("stage", 215138658, "project-stage");
        this.addField("status", 205678602, "category-2");
        this.addField("panel", 205678400, "panel-type");
        this.addField("panelCount", 205678402, "of-panels");
        this.addField("systemSize", 205678403, "system-size");
        this.addField("price", 205678405, "loan-amount");
        this.addField("consultant", 205678391, "consultant");
    }
}

export default BendProjectSchema;
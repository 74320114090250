import Attachment from "../Attachment";
import ObjectConverter from "./ObjectConverter";

class AttachmentConverter extends ObjectConverter {
    constructor() {
        super(null);
    }

    convert(file) {
        const attachment = new Attachment();
        attachment.id = file.file_id;
        attachment.name = file.name;
        attachment.type = file.mimetype;
        attachment.url = file.link;
        return attachment;
    }
}

export default AttachmentConverter;